import React from 'react';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import C18MuiFormControl from '../../usa/components/C18MuiFormControl'
import C18MuiInputLabel from '../../usa/components/C18MuiInputLabel'
import C18MuiSelect from '../../usa/components/C18MuiSelect'
import C18MuiMenuItem from '../../usa/components/C18MuiMenuItem'
import {chTypes} from '../utilsFui';
// import ShowArray from './ShowArray00';
// import SiteArrayMenu from './SiteArrayMenu';
 import {cl, globs} from '../../components/utils/utils';
// import {dbVals, init} from './utils/http';
// import {checkLogin, saveConfigs} from './utils/httpauth';
// import {p, pi} from './utils/paramIds';
// import MainBar from './MainBar';
// import history from "../history"
/*this will have to actually set the channeltype, *and*
the sub-type in channel data*/

class ChannelType extends React.Component{


  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      type: props.type,
    }
    this.makeTanks()
    this.props.getValue(props.ind, {type: "mixTank"})
    this.subscribe_updateFui=globs.events.subscribe("updateFui", this.updateFui)
  }
  
  componentWillUnmount=()=>{
    this.subscribe_updateFui.remove()
  }

  makeTanks=()=>{
    this.tanks={
      tank0: {t: "Tank 1"},
      tank1: {t: "Tank 2"},
      tank2: {t: "Tank 3"},
      tank3: {t: "Tank 4"},
      tank4: {t: "Tank 5"},
      tank5: {t: "Tank 6"},
      tank6: {t: "Tank 7"},
      tank7: {t: "Tank 8"},
    }
    Object.keys(this.tanks).forEach((k,i)=>{
      if(this.props.current.tankNames[i]){
        this.tanks[k].t=this.props.current.tankNames[i]
      }
    })
  }
  
  makeTankSelect=()=>{
    return Object.keys(this.tanks).map((c, i)=>{
        let v = this.tanks[c]
        return(
            <C18MuiMenuItem key={i} value=
            {c}>{v.t}</C18MuiMenuItem>
        )
    });
  }
  
  updateFui=()=>{
    this.makeTanks()
  }

  onChange=(e)=>{
    let tank = e.target.value;
//     cl(e.target.value)
//     let title = "Insert Title for " + e.target.value + " Here";
//     if (globs.fuiPages[type] !== undefined){
//       title = globs.fuiPages[type].title;
//     }
    let vo = {value: {type: "mixTank", tank: tank}, noChange:true}
//     cl(vo)
    this.props.onChange(this.props.ind, vo);
  }

  render(){
    // cl(this.props.type)
//     cl(this.props.current.tankNames[1])
//     cl(this.state)
//     cl(this.props.value)
    return(
      <div>
        <C18MuiFormControl className={"formControl"}>
          <C18MuiInputLabel htmlFor="age-simple">Mixing&nbsp;Tank</C18MuiInputLabel>
          <C18MuiSelect
            value={this.props.value}
            onChange={this.onChange}
            saveOK={this.props.saveOK}
            >
            {this.makeTankSelect()}
          </C18MuiSelect>
        </C18MuiFormControl>
      </div>
    );
  }
}


  export default ChannelType ;
