import React from 'react';
import history from "../../history"
import C18Select00 from './C18Select00'
import {cl,globs,constant,dateToDisplayDate} from '../../components/utils/utils';
import {wsTrans} from '../utils/utils'
import { DateRangePicker } from 'react-date-range';
import C18Anchor00 from './C18Anchor00'
import {loadSitesInfo,loadZonesInfo,loadUsersInfo,loadReportsInfo,loadReportSchedulesInfo} from './C18utils'

class C18ScheduleList extends React.Component{
  constructor(props) {
    super(props);
    this.loadInfo()

    this.state={
      all_sites: [],
      all_reports: [],
      all_users: [],
      all_reportSchedules: [],
      site: "All Sites",
      zone: "All Zones",
      sortMode:1,
    }
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveUsers)
    this.setBreadCrumbs()
  }
  
  loadInfo=async()=>{
    await this.makeSites()
    await this.makeReports()
    await this.makeReportSchedules()
    await this.makeUsers()
  }

  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Schedules", url:`/usa/c18/reports/scheduleList`},
            ]},
        },
      )
    }
  }
  
  makeUsers=async()=>{
    await loadUsersInfo()
    let users=[]
    let gui=globs.usersInfo.info
    this.userInd={}
    gui.forEach(u=>{
      users.push(
        {
          name: u.name,
          userId: u.userId,
        }
      )
      this.userInd[u.userId]=u
    })
    this.setState({loaded:true, all_users:users})
  }

  makeSites=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let sites=[]
    let gsi=globs.sitesInfo.info
    gsi.forEach(s=>{
      sites.push(
        {
          name: s.name,
          zones:this.makeZones(s.siteId),
          siteId:s.siteId,
        }
      )
    })
    this.setState({all_sites:sites})
  }

  makeZones=(siteId)=>{
    let gzi=globs.zonesInfo.info
    let zones=[]
    gzi.forEach(z=>{
      if(z.siteId==siteId) {zones.push(
        {
          name: z.zoneName,
          zoneId: z.zoneId,
        }
      )}
    })
    return zones
  }

  makeReports=async()=> {
    await loadReportsInfo()
    let gri=globs.reportsInfo.info
    let reports=[]

    gri.forEach(r=>{
      reports.push(
        {
          title: r.title,
          reportId: r.reportId,
          userId: r.userId,
          createdOn: r.createdOn,
          sites: r.sites,
          zones: r.zones,
        }
      )
    })
    this.setState({all_reports:reports})
  }

  makeReportSchedules=async()=> {
    await loadReportSchedulesInfo()
    let grsi=globs.reportSchedulesInfo.info
    let reportSchedules = []
//     cl(grsi)

    grsi.forEach(r=>{
      reportSchedules.push(
        {
          name: r.name,
          nextDate: r.nextDateTs,
          userId: r.userId,
          reportId: r.reportId,
          reportScheduleId: r.reportScheduleId,
          period: r.period,
          status: r.status,
          createdOn: r.createdOn,
        }
      )
    })
    reportSchedules=this.sortSchedules(reportSchedules,this.state.sortMode)
    this.setState({all_reportSchedules: reportSchedules})
  }

  onChange=(type, val)=> {
    cl([type, val])
    let st=this.state
    switch (type){
      case 'site':
        this.setState({site: val})
        break
      case 'zone':
        this.setState({zone: val})
        break
      case 'sort':
//         cl(st)
        if(Math.abs(val.sortMode)==st.sortMode){
          val.sortMode=0-st.sortMode
        }
        let schedules=this.sortSchedules(st.all_reportSchedules.slice(0),
          val.sortMode)
        Object.assign(val,{all_reportSchedules:schedules})
        this.setState(val)
        break
    }
  }
  
  sortSchedules=(schedules,sortMode)=>{
//     cl(schedules)
    let field=["","name","userId","nextDate","period","status"][Math.abs(sortMode)]
    let dir=(sortMode>0)?1:-1
    schedules.sort((a,b)=>{
      let af=a[field]
      let bf=b[field]
//       cl([af,bf])
      switch(field){
        case "name":
          af=af||"Untitled Schedule"
          bf=bf||"Untitled Schedule"
          break
        case "userId":
//           cl(af)
          af=this.userInd[af]?.name||"Former User"
          bf=this.userInd[bf]?.name||"Former User"
          break
        case "nextDate":
          bf = af
          af = b[field]
          break

      }
//       if(field=="title"){
//         af=(af||"Untitled Report").toLowerCase()
//         bf=(bf||"Untitled Report").toLowerCase()
//       }
//       if(field=="userId"){
//         af=(this.usersSearch[af]||{}).name||"Former User"
//         bf=(this.usersSearch[bf]||{}).name||"Former User"
//       }
      if(af>bf){return dir}
      if(af<bf){return 0-dir}
      return 0
    })
//     cl(schedules)
    return schedules
  }

  onClick=async(e,type, val)=> {
    e.preventDefault()
    cl([type, val])
    switch (type){
      case 'create':
        history.push(`/usa/c18/reports/editSchedule/`)
        cl("create schedule")
        break
      case 'edit':
        // navigate to edit report
        history.push(`/usa/c18/reports/editSchedule/${val}`)
        cl("edit schedule")
        break
      case 'delete':
        let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Schedule?", buttons:["Cancel","Yes"]})
        if(res=="Yes"){
          wsTrans("usa", {cmd: "cRest", uri: "/s/reportSchedules", method: "delete", 
          sessionId: globs.userData.session.sessionId, body: {
            reportScheduleId: val,
          }})
          globs.reportSchedulesInfo.got = false
          this.makeReportSchedules()
//           cl('delete schedule')
        }
        break
    }
  }

  filterZone=(val)=>{
//     cl(val)
    return this.state.site == "All Sites" || this.state.site == val.siteId
  }

  showZoneSelector=()=>{
    let zones = []
    let arr = this.state.all_sites.filter(this.filterZone)
//     cl(arr)
    arr.forEach((s, i) => {
        s.zones.forEach((z, j) => {
          zones.push( 
            <option key={[i, j]} value={z.zoneId}>{z.name}</option>
          )
        })
    })

    return(
      <span className="custom-select">
        <label htmlFor="report-zone">Zone</label>
        <C18Select00 
          id="report-zone"
          onChange={(e)=>this.onChange("zone", e.currentTarget.value)}
          value={this.state.zone}
        >
          {zones}
          <option>All Zones</option>
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }

  showSiteSelector=()=> {
    let sites = []
    this.state.all_sites.forEach((s, i) => {
        sites.push( 
          <option key={i} value={s.siteId}>{s.name}</option>
        )
    })

    return(
      <span className="custom-select">
        <label htmlFor="report-site">Site</label>
        <C18Select00 
          id="report-site"
          onChange={(e)=>this.onChange("site", e.currentTarget.value)}
          value={this.state.site}
        >
          {sites}
          <option>All Sites</option>
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }

  filterReport=(s)=> {
    let report = this.state.all_reports.find((r) => r.reportId == s.reportId)
    if (!report) {
      // delete bad schedule ?
      return false
    }
    let site_bool = (this.state.site == "All Sites") || report.sites.includes(this.state.site)
    let zone_bool = (this.state.zone == "All Zones") || report.zones.includes(this.state.zone)
    return site_bool && zone_bool 
  }
  
  showScheduleHead=()=>{
    let sort=this.state.sortMode// 1-5 for the columns
    let icons=[]
    for(let i=0;i<6;i++){
      let icon=""
      if(Math.abs(sort)==i+1){
        icons.push((sort<0)?"keyboard_arrow_up":"keyboard_arrow_down")
      } else{
        icons.push("")
      }
    }
    return(
      <tr>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:1})}
        >Name <span className="material-icons-outlined">{icons[0]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:2})}
        >Created By <span className="material-icons-outlined">{icons[1]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:3})}
        >Next Date <span className="material-icons-outlined">{icons[2]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:4})}
        >Period <span className="material-icons-outlined">{icons[3]}</span></button></th>
        <th><button type="button" aria-label="sort"
          onClick={()=>this.onChange("sort",{sortMode:5})}
        >Status <span className="material-icons-outlined">{icons[4]}</span></button></th>
        
        {this.state.writePriv&&
          <>
            <th>Edit</th><th>Delete</th>
          </>
        }
      </tr>
    )
  }

  showReportSchedules=()=>  {
    let reportSchedules = []
//     cl(this.state.all_reportSchedules)
    let arr = this.state.all_reportSchedules.filter(this.filterReport)
//     cl(arr)
    let banding=globs.usersInfo.uiInfo.tableBanding||true
    arr.forEach((r, i) => {
      let tableBand=(((i+1)%2)&&banding)?"tableBand":null
//       cl(this.state.all_users)
//       cl(r.userId)
      let user = this.state.all_users.find((u) => u.userId == r.userId)

      // replace date with next date
      let dt = dateToDisplayDate(new Date(r.nextDate), "mm/dd/yyyy")
//           <a className="name">
//           </a>
      reportSchedules.push(
        <tr key={i} className={tableBand}>
          <td>
            {(r.name == "") ? "Untitled Schedule" : r.name}
          </td>
          <td>
          {user == null ? "Former User" : user.name}
          </td>
          <td>
          {dt}
          </td>
          <td>
          {r.period.substring(0, 1).toUpperCase() + r.period.substring(1)}
          </td>
          <td>
          {(r.status ? "Active" : "Paused")}
          </td>
          <td>
          <C18Anchor00 to="" onClick={(e)=>this.onClick(e,"edit", r.reportScheduleId)} className="settings-button material-icons-outlined flip" aria-label="settings">build</C18Anchor00>
          </td>
          <td>
            <button onClick={(e)=>this.onClick(e,"delete", r.reportScheduleId)} type="button" className="material-icons trash" aria-label="delete schedule">
              delete_outline
            </button>
          </td>
        </tr>
      )
    })

    return (reportSchedules.length > 0 ?
      <div className="table-container">
        <table className="list schedules"><tbody>
          {this.showScheduleHead()}
          {reportSchedules}
        </tbody></table>
      </div> :
      <div className="none-found">No Schedules found</div>
      )
  }

  showReportScheduleList=()=> {
    if(this.state.loaded){
      return (
        <div>
          <div className="section-controls floatright">
            <C18Anchor00 to="" onClick={(e)=>this.onClick(e,"create", "")} className="material-icons-outlined add" aria-label="add schedule">
              add
            </C18Anchor00>
          </div>
          <div className="clearfloat"></div>

          {this.showSiteSelector()}
          {this.showZoneSelector()}
          {this.showReportSchedules()}

        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
  
  render(){
    return this.showReportScheduleList()
  }
  
}

export default C18ScheduleList;
