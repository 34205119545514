import React from 'react';
import C18Anchor00 from './C18Anchor00';
import {loadZonesInfo,getZoneInfo,loadUsersInfo,loadFuiPagesInfo,getUserIndex,makeAvatarPath} from './C18utils'
import {getDatapointName} from '../../usa/utils/utils'
import {wsTrans} from '../../usa/utils/utils'
import {cl,globs,getTime,dateToDisplayDate} from '../../components/utils/utils';
import {pInd} from '../../components/utils/paramIds';


class C18SidebarAuditLog00 extends React.Component{
  constructor(props) {
    super(props);
    cl(props)
    this.state={
      mode:"sites",
      loaded:false,
      skip:0,
      limit:2,
    }
    this.auditLog=[
      {parmName:"Inside Temperature",
        oldVal:20,
        newVal:30,
        unit:"degC",
        userId:"xx",
        name:"Jonathan Edward Johnson III",
        time:"8:23 AM",
        date:"05/17/2021",
      },
      {parmName:"Inside Temperature Really Long Log Entry Description which can happen sometimes",
        oldVal:20,
        newVal:31,
        unit:"degC",
        userId:"xx",
        name:"Max Cooper",
        time:"8:23 AM",
        date:"05/17/2021",
      },
    ]
    this.loadInfo()
  }
  
  componentDidMount=()=>{
    this.subscribe_newContextEvent=globs.events.subscribe("newContext",this.newContext)
    this.subscribe_newDataEvent=globs.events.subscribe("data",this.newData)
    this.mounted=true
  }
  componentWillUnmount=()=>{
    this.subscribe_newContextEvent.remove()
    this.subscribe_newDataEvent.remove()
    this.mounted=false
    cl("audit log unmounted")
  }
  
  newContext=async(vals)=>{// page has changed
//     cl(vals)
    var p
    if(vals.level=="config"){
      p={level:vals.level,siteId:vals.siteId,zoneId:vals.zoneId,mode:"fui",pageType:vals.pageType,zuci:vals.zuci}
    }else{
      p={level:vals.level,siteId:vals.siteId,zoneId:vals.zoneId}
    }
    await this.loadConfigLog(p,this.state.limit)
    this.setState({upd:(this.state.upd||0)+1,siteId:p.siteId,zoneId:p.zoneid})
//     let entries=await this.loadGJ(this.state.gjSkip,this.state.gjLimit,p)
//     cl(p.level)
//     if(this.mounted){this.setState({loaded:true,entries:entries,sgjLevel:p.level,sgjMode:p.mode,sgjSite:p.siteId,
//       sgjZone:p.zoneId,pageType:vals.pageType,zuci:vals.zuci})}

  }
  
  loadInfo=async()=>{
//     cl(this.props.parms)
    await loadZonesInfo()
    await loadUsersInfo()
    await loadFuiPagesInfo()
//     cl(globs.usersInfo.info)
//     this.zoneInfo=getZoneInfo(this.props.parms.zoneId)
//     cl(this.zoneInfo)
    await this.loadConfigLog(this.props.parms,this.state.limit)
    this.setState({loaded:true})
  }
  
  getTimePeriod=()=>{
    let end=Math.floor(getTime())+3*3600
    let begin=end-14*86400
    return [begin,end]
  }
  
  newData=(mo)=>{
    if(!this.query){return}
    var add=p=>{return false}
//     cl(this.query)
    if(this.query.c<40){
//       cl("define add")
      add=(p)=>{return (p.c==this.query.c)&&(p.f)}
    }else{
      add=(p)=>{
//         cl(p)
        return ((this.query.i)&&this.query.i["$in"].includes(p.i))
      }
//       cl(this.query)
      
    }
    mo.params.forEach(p=>{
      if(add(p)){
//         cl("add")
//         cl(mo)
//         cl(mo.user)
        p.u=mo.user
        this.auditLog.unshift(this.makeAuditLogEntry(p))
        this.setState({upd:(this.state.upd||0)+1})
      }
    })
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    switch(type){
      case "loadMore":
        vals.e.preventDefault()
        await this.loadConfigLog(this.props.parms,st.limit+3)
//         cl(this.auditLog)
        this.setState({limit:st.limit+3})
        break
    
    }
    
  }
  
  loadConfigZone=async(query)=>{// this has to be adjusted to account for the index
    var params=[]
    var makeParamTab=()=>{
      Object.keys(pInd[1800]).forEach(k=>{
        let p=pInd[1800][k]
        params.push({id:p[0],size:p[2]})
      })
      params.sort((a,b)=>{
        if(a.id>b.id){return 1}
        if(a.id<b.id){return -1}
        return 0
      })
    }
    var adjustIndex=(pid,ind)=>{// if the value has an index, then adjust the pid
      if(!ind){return pid}
      for(let i=0;i<params.length;i++){
        if(params[i+1].id>pid){
          return pid+ind*params[i+1].size
        }
      }
    }
    makeParamTab()
    let p=this.props.parms
    let fp=globs.fuiPagesInfo.index[p.pageType]
    let zuci=p.zuci
    let [z,u,c,ind]=p.zuci.split("-")
    let pids=[]
    fp.controls.forEach(co=>{
      pids.push(adjustIndex(co.pid,ind))
    })
    query.i={"$in":pids}
  }
  
  loadConfigLog=async(p,limit)=>{
    console.trace()
    let [begin,end]=this.getTimePeriod()// 14 days
    this.zoneInfo=getZoneInfo(p.zoneId)
    this.zoneType=this.zoneInfo?.gatewayType||1800
    cl(this.zoneType)
    let zInd=this?.zoneInfo?.siteZoneIndex
    var query={z: zInd, s: this.props.parms.siteId,$and:[{t:{$gt: begin}}, {t:{$lte: end}}]}
//     cl(p)
    switch(p.level){
      case "account":
        this.auditLog=[]
        return
      case "site":
        delete query.z
        break
      case "zone":
        break
      case "config":
//         if(p.pageType.indexOf("channel")<0){// this was blocking non-equipment pages
//           this.auditLog=[]
//           return
//         }
        let parts=p.zuci.split("-")
        query.c=+parts[2]
//         cl(p)
        break
    }
//     cl(query)
    if(query.c>=40){
      this.loadConfigZone(query)
    }
//     cl(query)
    this.query=query
    let st=this.state
//     if(!this.mounted){
//       cl("returning")
//       return}
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/configLog", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: query, page:{skip:st.skip,limit:limit+1,sort:{t:-1}}})// get one more to determine "load more"
//     if(!this.mounted){
//       cl("returning after")
//       return}
    let configs=[]
//     r.data.sort((a,b)=>{
//       if(a.t<b.t){return 1}
//       if(a.t>b.t){return -1}
//       return 0
//     })
//     cl(r.data)
//     let len=r.data.length
    this.auditLength=r.data.length// the *actual* number of items returned
    if(limit>r.data.length){limit=r.data.length}
//     if(len>115){len=115}
    
    for(let i=0; i<limit; i++){configs.push(r.data[i])}
    let auditLog=[]
    configs.forEach(c=>{
      cl(c)// c,d,f,i,t,u,z
//       let da=new Date(1000*c.t)
//       let ui=globs.usersInfo.info[getUserIndex(c.u)]||{name:"Unknown User"}
//       c.ty="Activity"
//       cl(c.i,c.z)
      auditLog.push(this.makeAuditLogEntry(c))
//       auditLog.push({
//         parmName:getDatapointName(c.i,c.z),
//         oldVal:"--",
//         newVal:c.d,
//         unit:"",
//         name:ui.name,
//         avatar:makeAvatarPath(ui.avatar),
//         time:dateToDisplayDate(da,"h:mm AP"),
//         date:dateToDisplayDate(da,"mm/dd/yyyy"),
//       })
    })
    cl(auditLog)
    this.auditLog=auditLog
    return configs
  }
  
  makeAuditLogEntry=(c)=>{
//     cl(c)
    let da=new Date(1000*c.t)
    let ui=globs.usersInfo.info[getUserIndex(c.u)]||{name:"LinkConn User"}
    return {
      parmName:getDatapointName(c.i,this.zoneType),
      oldVal:c.o||"--",
      newVal:c.d,
      src:c.f,
      unit:"",
      name:ui.name,
      avatar:makeAvatarPath(ui.avatar),
      time:dateToDisplayDate(da,"h:mm AP"),
      date:dateToDisplayDate(da,"mm/dd/yyyy"),
    }    
  }
  
  showEntries=()=>{
    return this.auditLog.map((a,i)=>{
//       cl(a)
      let src=(a.src==1)?"(lc)":""
//       let dpName=getDatapointName(a.i)
      return(
        <div key={i} id="sideBarAuditLogEntry">
          <div className="info-section">
            <div className="floatleft">
              <span id="sideBarAuditLogContent" className="log-entry">{a.parmName}</span><br />
              <div className="change"><span>{`${a.oldVal}${a.unit}`}</span> > <span>{`${a.newVal}${a.unit}`}</span></div>
            </div>
            <div className="floatright">
              <a href="" id="sideBarAuditLogUserName"><img className="small" src={a.avatar} alt={a.name}/><span className="name">{a.name+src}</span></a><br />
              <div className="time-date">
                <span className="time">{a.time}</span> | <span className="date">{a.date}</span>
              </div>
            </div>
          </div>

          <div className="clearfloat"></div>
          <br /><hr /><br />
        </div>
        
      )
    })
  }
  
  render(){
    if(this.state.loaded){
//           <div className="center">
//             <a href="" className="colored-link-text large-text">See All</a>
//           </div>
      return(
        <div>
          {this.showEntries()}
          {(this.auditLength>this.auditLog.length)&&// we have more to show
            <div className="center">
              <C18Anchor00 id="sideBarAuditLogLoadMore" to="" className="colored-link-text large-text load-more"
              onClick={e=>this.onChange("loadMore",{e:e})}>Load More</C18Anchor00>
            </div>
          }

        </div>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18SidebarAuditLog00;
