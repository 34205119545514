import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MainBar from '../components/MainBar';
import history from "../history"
import {getTemplates, getFuiPages, saveFuiPage, deleteFuiPage} from '../components/utils/httpauth';
import {checkLoggedIn} from '../usa/utils/utils'
import {cl} from '../components/utils/utils';
import {globs, userSpecificHome} from '../components/utils/utils';
import {getHomeDashboard} from '../usa/utils/utils'


class EditFui extends React.Component{
  constructor(props) {
    super(props);
    // cl(props.match.params.pageType)
//     cl(globs.fuiPages);
//     globs.userData.loggedIn=false
//     cl(globs.userData)
//     cl(globs.userData.loggedIn)
    this.state = {
      loaded: false,
      loadMsg: "loading",
      selFolder: "",
      selTemplate: "",
      selGateway: "800",
      title: "",
      controls: [],
      // controls: ["one", "two", "three"],
      selControl: -1,
    };
    this.loadData()

  }
  
//   componentWillUnmount=()=>{
//     cl("unmount")
//     globs.userData.loggedIn=false
//   }

  loadData=async()=>{
    await checkLoggedIn()
//     globs.userData.loggedIn=false
    cl("checked")
//     cl(globs.userData)
    let gt = getTemplates();
    let gfp = getFuiPages();
    Promise.all([gt, gfp]).then(r=>{
      // cl(r);
      // let arr = [];
      // r.pages.forEach(p=>{
      //   arr[p.type] = p;
      // });
      // cl(arr);
      let page = globs.fuiPages[this.props.match.params.pageType];
      cl(page)
      cl(this.props.match.params.pageType)
      let controls = page.controls.slice(0);// a copy
      // cl(controls.length)
      // cl(controls)
      this.setState({
        page: page,
        title: page.title,
        selTemplate: page.template,
        selGateway:page.gatewayType,
        controls: controls,
        loaded: true})
      // cl(page.controls)
    });
  }

  onChange=(e)=>{
//     cl(id)
    let id = e.target.id;
    let val = e.target.value;
    switch(id){
      case "title":
      this.setState({title: val})
      break;
      default:
      break;
    }
    // let cmds={title: this.getTitle(id)}
    // cmds
    // cl(e.target.id)

  }

  showOneText=(lab, id, place, val, multi)=>{
    return(
      <div style={{margin: 10}}>
        <TextField
          label={lab}
          id={id}
          value={val}
          multiline={multi}
          onChange={this.onChange}
          placeholder={place}
        />
      </div>
    );
  }

  showOneSelect=(p)=>{
    // cl(p.getList())
    // // let ls = this.getTemplateList();
    // let ls = p.getList();
    return(
      <div style={{margin: 10}}>
        <FormControl className={"formControl"}
        style={{minWidth: 250}}>
          <InputLabel htmlFor="unknown">{p.title}</InputLabel>
          <Select
            value={p.val}
            onChange={p.onChange}
            inputProps={{
              name: 'fui_page_select',
            }}
            >
            {p.getList()}
          </Select>
        </FormControl>
      </div>

    );
  }

  onTemplatePageChange=(e)=>{
    let templ = JSON.stringify(globs.templates[e.target.value], null, 2);
    this.setState({
      selTemplate: e.target.value,
      template: templ,
      name: e.target.value,
    })
  }

  getTemplateList=()=>{
    // cl("get")
    // cl(globs.templates)
    return Object.keys(globs.templates).map((k,i)=>{
      if (this.state.selFolder === ""){
        return <MenuItem key={i} value={k}>{k}</MenuItem>
      }else{
        let sp = k.split('|');
        // cl(f)
        if (sp[0] === this.state.selFolder){
          return <MenuItem key={i} value={k}>{k}</MenuItem>
        }
      }
    });
  }
  
  onGatewayChange=(e)=>{
//     let templ = JSON.stringify(globs.templates[e.target.value], null, 2);
    this.setState({
      selGateway: e.target.value,
//       template: templ,
//       name: e.target.value,
    })
  }

  getGatewayList=()=>{
    return[
      <MenuItem key={0} value={800}>{"800"}</MenuItem>,
      <MenuItem key={1} value={614}>{"614"}</MenuItem>,
      <MenuItem key={2} value={624}>{"624"}</MenuItem>,
      <MenuItem key={3} value={1800}>{"1800"}</MenuItem>,
      <MenuItem key={4} value={"pearl"}>{"Pearl"}</MenuItem>,
      <MenuItem key={5} value={3200}>{"3200"}</MenuItem>,
      <MenuItem key={6} value={3300}>{"3300"}</MenuItem>,
    ]
    
  }

  showGatewaySelect=()=>{
    return this.showOneSelect({
      val: this.state.selGateway,
      onChange: this.onGatewayChange,
      getList: this.getGatewayList,
      title: "Gateway Type",
    });
  }

  showTemplateSelect=()=>{
    return this.showOneSelect({val: this.state.selTemplate,
      onChange: this.onTemplatePageChange,
      getList: this.getTemplateList,
      title: "Template",
    });
  }
  
  getTemplateFolders=()=>{
    let arr = Object.keys(globs.templates);
    let arr2 = {};
    arr.forEach(e=>{arr2[e.split('|')[0]] = 1})
    // cl(arr2);
    return Object.keys(arr2).map((k,i)=>{
      return(
        <MenuItem key={i} value={k}>{k}</MenuItem>
      );
    });
  }

  onTemplateFolderChange=(e)=>{
    let templ = JSON.stringify(globs.templates[e.target.value], null, 2);
    this.setState({
      selFolder: e.target.value,
    })
  }

showTemplateFolderSelect  =()=>{
    return this.showOneSelect({val: this.state.selFolder,
      onChange: this.onTemplateFolderChange,
      getList: this.getTemplateFolders,
      title: "Template Folder",
    });
  }


  createControl=()=>{
    // cl("docreate")
    let url = "/fui/controledit/" + this.props.match.params.pageType;
    history.push(url);
  }

  deleteControl=()=>{
    cl("del")
    if(this.state.selControl >= 0){
      let n = this.state.selControl;
      let controls = this.state.controls.slice(0);
      controls.splice(n, 1);
      cl(controls)
      this.setState({controls: controls})
    }
    cl(this.state.selControl)
  }

  saveCurPage=()=>{
/* put the title and the controls into the globs page structure,
and save that page to the db*/
    let key = this.props.match.params.pageType;
    let page = Object.assign({}, this.state.page);
    page.controls=this.state.controls;
    page.title = this.state.title;
    page.template = this.state.selTemplate;
    page.gatewayType=this.state.selGateway
    globs.fuiPages[key] = page;
//     cl(page);
    this.setState({
      page: page,
    })
    saveFuiPage(key, page);
  }

  upControl=()=>{
    let sel = this.state.selControl;
    let controls = this.state.controls.slice(0);
    if ((sel >= 1) && (sel < controls.length)){
      let tmp = controls[sel];
      controls[sel] = controls[sel-1];
      controls[sel-1] = tmp;
      this.setState({
        controls: controls,
        selControl: sel-1,
      })
    }
  }

  downControl=()=>{
    let sel = this.state.selControl*1;
    let controls = this.state.controls.slice(0);
    if ((sel >= 0) && (sel < controls.length - 1)){
      let tmp = controls[sel];
      controls[sel] = controls[sel+1];
      controls[sel+1] = tmp;
      this.setState({
        controls: controls,
        selControl: sel+1,
      })
    }
  }

  onClick=(e)=>{
    switch(e){
      case "Save":
        // cl(this.state)
        globs.backMsg = {type: "fui", pageType: this.props.match.params.pageType};
        this.saveCurPage();
        history.goBack();
        break ;
      case "Cancel":
        globs.backMsg = {type: "fui", pageType: this.props.match.params.pageType};
        history.goBack();
        break;
      case "Edit":
        let sel = this.state.selControl;
        if ((sel >= 0) && (sel < this.state.controls.length)){
          let url = "/fui/controledit/" + this.props.match.params.pageType +
          "/" + this.state.selControl;
          history.push(url);
        }
        // cl(url)
        break;
      case "Create":
        this.createControl();
        break
      case "Delete":
        this.deleteControl();
        break;
      case "Up":
        this.upControl();
        break;
      case "Down":
        this.downControl();
        break;
      default:
        break;
    }
    // cl(e);
  }

  showButton=(name)=>{
    return(
      <Button onClick={
        ()=>this.onClick(name)}
      style={{margin: 5}}
      variant="contained" color="primary" className={"button"}>
        {name}
      </Button>
    );
  }

  onControlClick=(e)=>{
    // cl(e.target.id)
    this.setState({selControl: e.target.id})
  }

  showControls=()=>{
    return(
      <>
      <h3>Controls</h3>
      <ul style={{listStyleType: "none"}}>
      {this.state.controls.map((c, i)=>{
//         cl(c);
        let col = (i===this.state.selControl*1) ? "lightblue" : "white";
        if ((c.name === undefined) || (c.name === "")) c.name = "none";
        return (
          <li
          id={i}
          style={{cursor: "pointer",
          backgroundColor: col}}
          onClick={this.onControlClick}
          key={i}>{c.name}</li>
        );
      })}
      </ul>
      </>
    );
  }

  barClick = async (e)=>{
    cl(e.currentTarget.id);
    switch (e.currentTarget.id){
      case "home":
//         userSpecificHome()
        let dash=await getHomeDashboard()
        if(dash){history.push(`/usa/dash/${dash}`)}
//         history.push("/usa/dashboard")
//         history.push("/sa")
        break;
      default:
        break;
    }
  }

  render(){
    // cl(this.state)
    if(this.state.loaded){
      document.title="FUI Page Editor";
      return(
        <div>
        <MainBar home settings
        menu click={this.barClick} title="FUI Page Editor" />
        <div>
        {this.showOneText("Title", "title", "Enter Title", this.state.title, false)}
        {this.showTemplateFolderSelect()}
        {this.showTemplateSelect()}
        {this.showGatewaySelect()}
        {this.showControls()}
          <div style={{margin: 5}}>{this.state.message}</div>
          <table><tbody>
          <tr>
            <td>{this.showButton("Create")}</td>
            <td>{this.showButton("Delete")}</td>
            <td>{this.showButton("Edit")}</td>
          </tr>
          <tr>
            <td>{this.showButton("Up")}</td>
            <td>{this.showButton("Down")}</td>
            <td></td>
          </tr>
          <tr>
            <td>{this.showButton("Save")}</td>
            <td>{this.showButton("Cancel")}</td>
            <td></td>
          </tr>
          </tbody></table>
        </div>
        </div>
      );
    }else{
      return(
        <div>
        <MainBar home settings
        menu click={this.barClick} title="FUI Page Selector" />
        {this.state.loadMsg}</div>
      );
    }
  }

}

export default EditFui ;
