import React from 'react';
import UsaSelect00 from './UsaSelect00'
import UsaIcon from './UsaIcon';
import {cl, globs, userSpecificHome} from '../../components/utils/utils';// ,globs,az,getTime, getRandomString
import {wsTrans, checkLoggedIn} from '../utils/utils'
import history from "../../history"

class GrowJournalView00 extends React.Component{
  constructor(props) {// 
    super(props);
    let da=new Date()
    this.state={
      zone: 2,
      user: 1,
      loaded: false,
      gjSelect: -1,
      gjLoaded: "",
      imageDisplay: [],
    }
    this.gjZoneOpts=[
      {v: 0, t: "Room One"},
      {v: 1, t: "Room Two"},
      {v: 2, t: "Room Three"},
      {v: 3, t: "Room Four"},
    ]
    this.gjUserOpts=[
      {v: 0, t: "User One"},
      {v: 1, t: "User Two"},
      {v: 2, t: "User Three"},
      {v: 3, t: "User Four"},
    ]
    this.getGrowJournalEntries("all")
  }
  
  getImageInfo=(images)=>{
    let ret={}
    images.forEach(i=>{
      let ext=i.name.substr(i.name.lastIndexOf(".")+1)
      ret[i.id]={ext: ext, w: i.w, h: i.h}
    })
//     cl(ret)
    return ret
//     cl(gje)
  }
  
  getGrowJournalEntries=async(id)=>{// gets all for account
    let logged=await checkLoggedIn()
    if(!logged)history.push("/usa/login")
    let getGJ={cmd: "cRest", uri: "/s/growJournal", method: "retrieve", sessionId: globs.userData.session.sessionId,
      body: {id: id}};
    let gjEntries=(await wsTrans("usa", getGJ)).data
    if(id=="all"){
      this.setState({loaded: true, gjEntries: gjEntries})
    }else{
//       cl(gjEntries)
      let images=this.getImageInfo(gjEntries.images)
      this.setState({gjLoaded: id, gjEntry: gjEntries, images: images})
    }
  }

  zoneSelect=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Zone",
        select: true, // !this.state.editMode,
        valueId: "zone",//valueId, // "widgetSel",
        value: this.state.zone, //value, // this.state.widgetSel,
        opts: this.gjZoneOpts,
        onChange: (vals)=>this.onChange("zone", vals),
      }}/>
    )
  }
  
  userSelect=()=>{
    return(
      <UsaSelect00 parms={{
        title: "User",
        select: true, // !this.state.editMode,
        valueId: "user",//valueId, // "widgetSel",
        value: this.state.user, //value, // this.state.widgetSel,
        opts: this.gjUserOpts,
        onChange: (vals)=>this.onChange("user", vals),
      }}/>
    )
  }
  
  onChange=(type, vals)=>{
    switch(type){
      case "zone":
      case "user":
        this.setState(vals)
        break
      default:
        cl(type)
        cl(vals)
        break
    }
  }
  
  gjClick=async(e)=>{
//     cl(e.target.id)
    let parts=e.target.id.split("-")
    let id=this.state.gjEntries[parts[1]].growJournalId
    await this.getGrowJournalEntries(id)
//     cl(parts[1])
//     cl(id)
    this.setState({gjSelect: parts[1]})
  }
  
  showGJEntryList=()=>{
    if(this.state.loaded){
      return(
        <div style={{width: 300, height: 200, margin: 10, padding:10, overflowY: "auto", backgroundColor: "#C0FFE0"}}>
        {this.state.gjEntries.map((gj,i)=>{
          let bgColor=(i==this.state.gjSelect)?"#80FFC0" : "#C0FFE0"
          return <div key={i} id={`gje-${i}`}style={{cursor: "pointer", backgroundColor: bgColor}}
            onClick={this.gjClick}>{gj.title}</div>})
        }
        </div>
      )
    }
  }
  
  makeImagePath=(id, ext)=>{
    return `${id[0]}/${id[1]}/${id[2]}/${id.substr(3)}.${ext}`
  }
  
  showImage=(path, s)=>{
//     cl(s)
    let images=this.state.imageDisplay.slice(0)
    images.push({path: path, imageInfo: s})
//     cl(images)
//     let im={imageDisplay: path, imageInfo: s}
    this.setState({imageDisplay: images})
//     cl(i)
  }

  showGJBody=(body)=>{
//     cl(this.state)
    let parts=body.split("{Img:")
    let sects=[]
    parts.forEach((p,i)=>{
      if(i>0){
        let p2=p.split("}")
        let imageInfo=this.state.images[p2[0]]
//         cl(imageInfo)
//         cl(p2)
        sects.push({i:p2[0], t:p2[1], w: imageInfo.w, h: imageInfo.h, x: 100+50*i, y: 100+50*i})// x, y is where it will appear large
      }else{
        sects.push({i: false, t:p})
      }
    })
    return sects.map((s,i)=>{
//       cl(s)
//       cl(this.state.images[s.i])
      var path
      if(s.i){
        path=this.makeImagePath(s.i, this.state.images[s.i].ext)
//         cl(path)
      }
      return(
        <div key={i}>
        {s.i &&
          <img width="100" src={`http://gene2.tr8.us/images/uploads/${path}`} onClick={e=>{this.showImage(path, s)}}/>
        }
        <div>{s.t}</div>
        </div>
      )
    })
    return
  }
//   x-special/nautilus-clipboard
// copy
// file:///home/gknight/repos/ng00/public/img/1452646545492.jpeg

  showReply=()=>{
    return(
      <div style={{cursor: "pointer"}} onClick={e=>this.result("Entry-Reply")}>
        <UsaIcon icon={`Entry-Reply`} result={this.result}/>
      Reply
      </div>
    )
  }
  
  showGJEntry=()=>{
/*body:
 When you look at the plants:
{Img:b6heJr1Bo4LZpRVI}
You'll see that the sensors:
{Img:2GuaGhOUWceo2Z+m}
are really not doing their job!*/
//     let gj=
    if(this.state.loaded && (this.state.gjSelect>=0)){
      if(this.state.gjEntries[this.state.gjSelect].growJournalId==this.state.gjLoaded){
//         cl("ok")
        let gje=this.state.gjEntry
//         cl(gje.body)
//         cl(this.state)
        return(
          <div style={{width: 300, height: 200, margin: 10, padding:10, overflowY: "auto", backgroundColor: "#C0E0C0"}}>
          <h3>{gje.title}</h3>
          {this.showGJBody(gje.body)}
          {this.showReply()}
          </div>
        )
      }
    }
  }
  
  result=(type)=>{
    let parts=type.split("-")
    let id=parts[0].substr(2)
    cl(id)
    switch(parts[1]){
      case "Cancel":
        let images=this.state.imageDisplay.slice(0)
        images.splice(id, 1)
        this.setState({imageDisplay: images})
        break
      case "Reply":
        cl(this.state.gjEntry.growJournalId)
        history.push(`/usa/growJournal/${this.state.gjEntry.growJournalId}`)
        break
      default:
        cl(type)
        break
    }
  }
  
/******************** Drag / Drop Code ***************************/

  mouseDown=(ev,i)=>{
//     cl(ev.target.offsetLeft)
    let ii=this.state.imageDisplay[i].imageInfo
    let upd={dragStart: {i: i, x: ev.clientX, y: ev.clientY,
      x0: ii.x, y0: ii.y}, drag: {x: ev.clientX, y: ev.clientY}}
//     cl(upd)
    this.setState(upd)
  }

  mouseMove=(ev)=>{
    if(this.state.dragStart){
      let upd={drag: {x: ev.clientX, y: ev.clientY}}
      let i=this.state.dragStart.i
      let ii=this.state.imageDisplay[i].imageInfo
      let drs=this.state.dragStart
      let drg=this.state.drag
      ii.x=drg.x-drs.x+drs.x0
      ii.y=drg.y-drs.y+drs.y0
      this.setState(upd)
    }
  }
  
  dragStart=(ev)=>{
    ev.preventDefault()
  }
  
/******************** End Drag / Drop Code ***************************/
//   dragEnd=(ev)=>{
//     cl("drag end")
//     this.setState({dragStart: null, drag: null})
//   }
  
  mouseUp=(ev)=>{
    if(this.state.dragStart){
//       let drag={x: e.clientX, y: e.clientY}
//       let i=this.state.dragStart.i
//       let dx=drag.x-this.state.dragStart.x
//       let dy=drag.y-this.state.dragStart.y
//       let size=this.state.dashboard.s
//       let dx2=Math.round(dx/size)
//       let dy2=Math.round(dy/size)
//       let widgets=this.state.widgets.slice(0)
//       let x2=widgets[i].position.left
//       widgets[i].position.left=+widgets[i].position.left+dx2
//       widgets[i].position.top=+widgets[i].position.top+dy2
//       widgets[i].flags|=constant.WIDGET_FLAG_MODIFIED
      this.setState({dragStart: null, drag: null})
    }
//     cl(ev)
  }
  
  
/******************** End Drag / Drop Code ***************************/
  
  imageDisplayRender=()=>{
//     cl(this.state)
//     if(this.state.imageDisplay.length){
      return this.state.imageDisplay.map((idsp, i)=>{
        let ii=idsp.imageInfo
        let path=idsp.path
        let w=ii.w
        let h=ii.h
        if(w>500){
          h=h*500/w
          w=500
        }
//         cl(w)
        return(
          <div key={i} style={{position: "absolute", left: ii.x, top: ii.y, width: w, height: h, backgroundColor: "red"}}>
            <img width={w} src={`http://gene2.tr8.us/images/uploads/${path}`}
            style={{backgroundColor: "#C0FFC0", borderRadius: 10, boxShadow: "5px 10px 10px #888888"}}
            onMouseDown={e=>this.mouseDown(e,i)} onMouseMove={this.mouseMove} onMouseUp={this.mouseUp} 
            onDragStart={this.dragStart} onDragEnd={this.dragEnd}/>
            <div style={{width: 48, height: 48, 
              position: "absolute", right: 0, top: 0}}>
              <UsaIcon icon={`im${i}-Cancel`} result={this.result}/>
            </div>
            
          </div>
        )
      })
//     }
  }
  
  render(){
  return(
      <div><h3>Grow Journal Entries</h3>
      {this.zoneSelect()}
      {this.userSelect()}
      {this.showGJEntryList()}
      {this.showGJEntry()}
      {this.imageDisplayRender()}
      </div>
    )
  }
}

export default GrowJournalView00 ;

