import React from 'react';
import C18Anchor00 from './C18Anchor00'
import {loadSitesInfo,loadZonesInfo,getSiteName,getZoneName} from './C18utils';
import {cl,globs} from '../../components/utils/utils';

class C18Tags00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
//       tags:["one", "two","three","four","five"],
//       allTags:["now","is","the","time","for","all","good"],
      selIndex:-1,
      tagName:"",
    }
    this.loadInfo()
//     this.inputRef=React.createRef()
  }
  
//   componentDidMount=()=>{
//     cl(this.inputRef.current)
//     this.inputRef.current.onFocus=this.onFocus
//   }
  
  loadInfo=()=>{// get list of tags
    
  }
  
  
  onChange=(type,vals)=>{
    cl([type,vals])
    switch(type){
      case "tagDelete":
        vals.e.preventDefault()
        let tags=this.props.parms.tags.slice(0)
        tags.splice(vals.ind,1)
        this.props.parms.onChange({tags:tags})
//         this.setState({tags:tags})
        break
      case "inputKey":
//         cl(vals.e.key)
        switch(vals.e.key){
          case "Enter":
            if(this.state.selIndex>=0){
              this.addTag(this.tags[this.state.selIndex])
            }else{
              if(this.state.tagName){this.addTag(this.state.tagName)}
            }
            this.setState({selIndex:-1})
//             cl("enter")
//             vals.e.stopPropagation()
            break
          case "ArrowDown":
            let max=this.tags.length
            if(max>5){max=5}
            if(this.state.selIndex<max-1){
              this.setState({selIndex:this.state.selIndex+1})
            }
            break
          case "ArrowUp":
            if(this.state.selIndex>=0){
              this.setState({selIndex:this.state.selIndex-1})
            }
            break
        }
        break
      case "tagName":
        Object.assign(vals,{selIndex:-1})
        globs.events.publish("savePageEnable", false)
        this.setState(vals)
        break
      case "addTag":
        this.addTag(vals.tag)
        break;
    }
  }
  
  addTag=(tag)=>{
    let tags=this.props.parms.tags.slice(0)
    tags.push(tag)
    this.props.parms.onChange({tags:tags})
    this.setState({tagName:""})
  }
  
  showEditTagDiv=(tagName,i)=>{
    return(
      <span key={i} style={{backgroundColor:"white", 
        padding:"5px 10px 5px 10px",
        margin:10, borderRadius:5,
        display:"inline-block"
      }}>
      {tagName}
      <span style={{margin:"0px 0px 0px 10px"}}>
      <C18Anchor00 to=""
        onClick={e=>this.onChange("tagDelete",{e:e,ind:i})}
      >x</C18Anchor00>
      </span>
      </span>
    )
  }
  
  showMatchingTags=()=>{
    let match=this.state.tagName.toLowerCase()
    this.tags=this.props.parms.allTags.filter(ta=>{
      return ta.toLowerCase().indexOf(match)>=0
    })
    let max=(this.state.tagName.length==0)?0:5
    return(
      <div style={{width:300,backgroundColor:"#EEEEEE",overflowY:"auto",
        position:"absolute"
      }}>
      {this.tags.map((ta,i)=>{
        let bg=(i==this.state.selIndex)?"#CCFFFF":"#EEEEEE"
        if(i<max){return <span key={i}
      style={{cursor:"pointer",backgroundColor:bg,margin:5}}
      onClick={e=>{this.onChange("addTag",{tag:ta})}}
      >{ta}<br/></span>}
          
        })}
      </div>
    )
  }
  
  setSaveEnable=()=>{
    globs.events.publish("savePageEnable", !this.focus)
  }
  
  onFocus=()=>{
    cl("focus")
//     this.focus=true
//     setTimeout(this.setSaveEnable,100)
    globs.events.publish("savePageEnable", false)
  }
  
  onBlur=()=>{
    cl("blur")
    cl(this.state)
    if(this.state.tagName){this.addTag(this.state.tagName)}
    
//     this.focus=false
//     setTimeout(this.setSaveEnable,100)
    globs.events.publish("savePageEnable", true)
  }
  
  showInputTagDiv=()=>{
//         padding:"5px 10px 5px 10px",
//         margin:10, borderRadius:5,
//         display:"inline-block",
//         width:"unset"
//         onKeyPress={e=>this.onChange("inputKey",{e:e})}
//     cl("show tag")
    return(
      <span style={{backgroundColor:"#FFFFFF",
          margin:"-5px 10px 10px 10px",
          padding:"5px 10px 5px 10px",
          borderRadius:5,
          display:"inline-block",
          verticalAlign:"text-top",
           position:"relative",
        }}>
        <input type="text" style={{width:70,height:20,padding:"2px 5px 5px 5px ",borderRadius:5,
          margin:"-5px 0px 0px -10px ",
          border:1,borderStyle:"solid",borderColor:"#AAAAAA"}}
//         ref={this.inputRef}
        value={this.state.tagName}
        onChange={e=>{this.onChange("tagName",{tagName:e.currentTarget.value})}}
        onKeyUp={e=>this.onChange("inputKey",{e:e})}
        onFocus={this.onFocus}
        onBlur={e=>this.onChange("inputKey",{e:e})}
      />
      {this.showMatchingTags()}
      </span>
    )
  }
  
  render(){
    cl(this.props)
    return(
      <>
        <label htmlFor="tags">Tags</label>
        <div id="tags" style={{width: 500, 
          borderRadius:10,
           border:1,borderStyle:"solid",borderColor:"#AAAAAA",
           padding:10,flexWrap:"wrap"}}>
          {(this.props.parms.tags||[]).map((ta,i)=>this.showEditTagDiv(ta,i))}
          {this.showInputTagDiv()}
        </div>
      </>
    )
  }
}
      
export default C18Tags00;
