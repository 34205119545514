import React from 'react';

class C18InputError00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
    }
  }
  
  showInputErrorField=(parms)=>{
    let messageClass={
      error:"input-error-message",
    }[parms.messageType]
    let divClass=`text-input-wrapper${(parms.message)?" input-error":""}`
    let input = (parms.input) ? parms.input : "input"
    if(parms.type=="password"){
      let auto = "on"
      let visibility = "visibility"
//       console.log(input)
      if (input == "password" || input == "confirmPassword") {
//         console.log("here")
        // specific to profile page
        divClass = divClass + " password-wrapper"
        auto = "one-time-code"
        visibility = parms.visibility
      }
      let type=(parms.visible)?"text":"password"
      
      return(
        <div className={divClass}>
          <label htmlFor={parms.id}>{parms.title}</label>
          <div className="password-container">
            <input id={parms.id} type={type} required={parms.required}
              value={parms.value} autoComplete={auto}
              disabled={parms.disabled}
              onChange={e=>parms.onChange(input, {[parms.valueId]: e.currentTarget.value})}
              onKeyPress={parms.onKeyPress}
            />
            <button className="visible-button" type="button" aria-label="toggle password visibility">
              <span className="material-icons"
                onClick={e=>parms.onChange(visibility, {id: parms.id})}
              >
                {(parms.visible)?"visibility_off":"visibility"}
                </span>
            </button>
            <div className={messageClass}>{parms.message}</div>
          </div>
        </div>
      )
    }else{
      return(
        <div className={divClass}>
          <label htmlFor={parms.id}>{parms.title}</label>
          <input id={parms.id} type={parms.type} required={parms.required} autoComplete="off"
            value={parms.value}
            disabled={parms.disabled}
            onChange={e=>parms.onChange(input, {[parms.valueId]: e.currentTarget.value})}
            onKeyPress={parms.onKeyPress}
          />
          <div className={messageClass}>{parms.message}</div>
        </div>
      )
    }
  }
  
  render(){
    return this.showInputErrorField(this.props.parms)
  }
}
      
export default C18InputError00;
