import React from 'react';
import C18Anchor00 from './C18Anchor00'
import history from "../../history"
import {wsTrans} from '../utils/utils';
import {loadSitesInfo,loadZonesInfo,loadUsersInfo,getSiteName,getZoneName} from './C18utils';
import {cl,colors,globs,getTime,constant} from '../../components/utils/utils';

class C18TaskList00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      loaded:false,
      icons:[0,1,0,0,0,0,],
      showComplete:true,
    }
    this.simple=true
    this.setBreadCrumbs()
    this.loadInfo()
  }
  
  setBreadCrumbs=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    var base
    if(p){
      base="/usa/c18/sites"
      let bc=[
        {t:"Sites", url:base},
      ]
      if((p.scope=="site")){
        base=`/usa/c18/sites/${p.site}`
        bc.push(
          {t:siteName, url:base},
          {t:"Tasks", url:`${base}/tasks`},
        )
      }
      if(p.scope=="zones"){
        base=`/usa/c18/sites/${p.site}`
        bc.push(
          {t:siteName, url:base},
          {t:zoneName, url:`${base}/zones/${p.zone}`},
          {t:"Tasks", url:`${base}/zones/${p.zone}/tasks`},
        )
        base+=`/zones/${p.zone}`
      }
      p.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: bc},
        },
      )
      this.baseUrl=base
    }
  }
  
//   componentWillUnmount=()=>{
//     this.subscribe_savePageEvent.remove()
//   }

  loadInfo=async()=>{
    await loadUsersInfo()
    let users={}
    globs.usersInfo.info.forEach(us=>{
      users[us.userId]=us
    })
    this.users=users
//     cl(users)
    this.userOptions=globs.usersInfo.info.map(ui=>{
      return{value:ui.userId,label:ui.name}
    })

    let pa=this.props.parms
//     cl(this.props)
//     cl(this.state)
    var query
    switch(pa.scope){
      case "sites":
        query={}
        break
      case "site":
        query={site:pa.site}
        break
      case "zones":
        query={zone:pa.zone}
        break
    }
//     cl("query")
//     cl(query)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: query,})
//     cl(res.data)
    res.data.forEach(ta=>{
      ta.handler0=(ta.handlers||[])[0]||"None"
      ta.pcntComplete=ta.pcntComplete||0
    })
//     cl(res.data)
    let sortMode=1// status, date
    let tasks=this.sortTasks(res.data,sortMode)
    this.setState({tasks:tasks,loaded:true,sortMode:sortMode})
  }
  
  sortTasks=(tasks,sortMode)=>{
    this.sitesInd={}
    globs.sitesInfo.info.forEach(s=>{this.sitesInd[s.siteId]=s})
    this.zonesInd={}
    globs.zonesInfo.info.forEach(z=>{this.zonesInd[z.zoneId]=z})
    this.usersInd={}
    globs.usersInfo.info.forEach(u=>{this.usersInd[u.userId]=u})
    var field
//     cl(tasks)
    if(this.simple){
      field=["","status","title","dueDate","handler0","site",
        "zone"][Math.abs(sortMode)]
    }else{
      field=["","title","priority","pcntComplete","status","handler0",
        "dueDate"][Math.abs(sortMode)]
    }
//     cl(field)
    let dir=(sortMode>0)?1:-1
    tasks.sort((a,b)=>{
      let af=a[field]
      let bf=b[field]
      if(field=="title"){
        af=af?.toLowerCase()
        bf=bf?.toLowerCase()
      }
      if(field=="handler0"){
        af=this.usersInd[af]?.name.toLowerCase()||"Unknown User"
        bf=this.usersInd[bf]?.name.toLowerCase()||"Unknown User"
      }
      if(field=="site"){
        af=this.sitesInd[af]?.name.toLowerCase()||"None"
        bf=this.sitesInd[bf]?.name.toLowerCase()||"None"
      }
      if(field=="zone"){
        af=this.zonesInd[af]?.zoneName.toLowerCase()||"None"
        bf=this.zonesInd[bf]?.zoneName.toLowerCase()||"None"     
      }
      if(this.simple&&(field=="status")){
        af=af=="done"
        bf=bf=="done"
        if(af==bf){
          af=a["dueDate"]
          bf=b["dueDate"]
        }
      }
      if(af>bf){return dir}
      if(af<bf){return 0-dir}
      return 0
    })
    return tasks
  }
  
  goToTask=(ta)=>{
    cl(ta)
    switch(ta.scope){
      case "account":
        history.push(`/usa/c18/sites/SPtasks`)
        break
      case "site":
        history.push(`/usa/c18/sites/${ta.site}/SPtasks`)
        break
      case "sites":
        history.push(`/usa/c18/sites/SPtasks`)
        break
      case "zone":
      case "zones":
//         cl(`/usa/c18/sites/${ta.site}/zones/${ta.zone}`)
        history.push(`/usa/c18/sites/${ta.site}/zones/${ta.zone}/SPtasks`)
        break
      case "config":
        history.push(`/usa/c18/sites/${ta.site}/fui/${ta.pageType}/${ta.zuci}/SPtasks`)
        break
    }
  }
  
    onChange=(type,vals)=>{
//       cl([type,vals])
      let st=this.state
      var tasks
      switch(type){
        case "sort":
          if(Math.abs(vals.sortMode)==st.sortMode){
            vals.sortMode=0-st.sortMode
          }
          tasks=this.sortTasks(st.tasks.slice(0),vals.sortMode)
          Object.assign(vals,{tasks:tasks})
          this.setState(vals)
          break
        case "markComplete":
          tasks=this.state.tasks.slice(0)
          let ta=tasks.filter(ta=>{return ta.taskId==vals.taskId})[0]
          ta.status=(vals.complete)?"done":"readyToStart"
          this.setTaskComplete(ta)
          this.sortTasks(tasks,st.sortMode)
          Object.assign(vals,{tasks:tasks})
          this.setState(vals)
          break
        case "upd":
          cl(vals)
          this.setState(vals)
          cl(this.state)
          break
        case "markAll":
          this.markAll(vals.mark)
          break
        case "taskLine":
          this.goToTask(vals.task)
          cl(vals)
          break
      }
    }
    
  setTaskComplete=async(ta)=>{
    await wsTrans("usa", {cmd: "cRest", uri: "/s/tasks", method: "update",
      sessionId: globs.userData.session.sessionId, body: {taskId:ta.taskId,status:ta.status},})
  }

  showTaskHead=()=>{
// sort mode: name, email, role, access, status, 
    let sort=this.state.sortMode// 1-5 for the columns
//     cl(this.state)
    let icons=[]
    for(let i=0;i<6;i++){
      let icon=""
      if(Math.abs(sort)==i+1){
        icons.push((sort<0)?"keyboard_arrow_up":"keyboard_arrow_down")
      } else{
        icons.push("")
      }
    }

    
//     cl(icons)
    if(this.simple){
      return(
        <tr>
          <th style={{width: "10%"}}><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:1})}
          >Complete <span className="material-icons-outlined">{icons[0]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:2})}
          >Title <span className="material-icons-outlined">{icons[1]}</span></button></th>

          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:3})}
          >Due <span className="material-icons-outlined">{icons[2]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:4})}
          >Assignee <span className="material-icons-outlined">{icons[3]}</span></button></th>

          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:5})}
          >Site <span className="material-icons-outlined">{icons[4]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:6})}
          >Zone <span className="material-icons-outlined">{icons[5]}</span></button></th>
          <th>Update </th>
        </tr>
      )
    }else{
      return(
        <tr>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:1})}
          >Title <span className="material-icons-outlined">{icons[0]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:2})}
          >Priority <span className="material-icons-outlined">{icons[1]}</span></button></th>

          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:3})}
          >Complete <span className="material-icons-outlined">{icons[2]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:4})}
          >Status <span className="material-icons-outlined">{icons[3]}</span></button></th>

          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:5})}
          >Assignee <span className="material-icons-outlined">{icons[4]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:6})}
          >Due <span className="material-icons-outlined">{icons[5]}</span></button></th>
          <th>Edit </th>
        </tr>
      )
    }
    
  }
  
  
  
  showEdit=(ta)=>{
    return(
      <C18Anchor00 to={`${this.baseUrl}/taskDetail/${ta.taskId}`} className="settings-button material-icons-outlined flip">edit</C18Anchor00>
    )
  }
  
//   showTitle=(ta)=>{
// //     cl(ta)
//     let title=ta.title||""
//     if(title.length>28){title=title.substr(0,25)+"..."}
//     return(
//       <C18Anchor00 to={`${this.baseUrl}/taskDetail/${ta.taskId}`} aria-label="task detail">
//         {title}
//       </C18Anchor00>
//     )
//   }
  
  showStatus=(status)=>{
    let statusLines={
      created:"Created",
      approved:"Approved",
      readyToStart:"Ready To Start",
      inProgress:"In Progress",
      problem:"Problem",
      onHold:"On Hold",
      cancelled:"Cancelled",
      waitingForReview:"Waiting for Review",
      complete:"Complete",
    }
//     className=`$task-status-${status}-color`
    return(
      <span className={`bubble-text status-${status}`}>{statusLines[status]}</span>
    )
  }
  
  showPriority=(priority)=>{
    let priorities={
      low:"Low",
      medium:"Medium",
      high:"High",
      urgent:"Urgent",
    }
    return(
      <span className={`bubble-text priority-${priority}`}>{priorities[priority]}</span>
    )
  }
  
  showPercent=(percent)=>{
    let pcntComplete=percent||0
    return(
      <span>{`${pcntComplete} %`}</span>
    )
  }
  
  showHandler=(userId, allAssigneeUserIds)=>{
    var path=`${constant.expressUrl}/usa/images/avatars/stockAvatar.jpg`
    var name
    if(userId&&(this.users[userId])){
      let av=this.users[userId].avatar
      if(av){
        path=`${constant.expressUrl}/usa/images/avatars/uploads/${av[0]}/${av[1]}/${av[2]}/${av.substr(3)}.jpeg`
      }
      name=(this.users[userId]||{}).name||"Unknown"
    }else{
      name="Unknown User"
    }
    if(allAssigneeUserIds.length > 1) {
      var allNames = '';
      for (let i = 0; i < allAssigneeUserIds.length; i++) {
        if(this.users[allAssigneeUserIds[i]]){
          allNames = `${allNames}, ${(this.users[allAssigneeUserIds[i]]||{}).name}`;   
        }
      }
    }
    return (
      <table><tbody>
      <tr><td style={{width:50}}><img src={path} width="50"/></td><td title={allNames}>{allAssigneeUserIds.length > 1 ? name + '...' : name}</td></tr>
      </tbody></table>
    )
  }

  showComplete=(ta)=>{
//     cl(ta)
    let complete=ta.status=="done"
//       style={{backgroundColor:"red"}}
    return(
      <input type="checkbox"
        checked={complete}
        onChange={e=>this.onChange("markComplete",{taskId:ta.taskId,
          complete:e.currentTarget.checked})}
      />
    )
  }
  
  showSite=(ta)=>{
//     cl(ta)
    let site=globs.sitesInfo.info.filter(si=>{return si.siteId==ta.site})[0]
//     cl(site)
    return(
      <span>{site?.name||"None"}</span>
    )
  }

  showZone=(ta)=>{
    let zone=globs.zonesInfo.info.filter(zo=>{return zo.zoneId==ta.zone})[0]
//     cl(zone)
    return(
      <span>{zone?.zoneName||"None"}</span>
    )
  }

  showTaskLines=()=>{
//     cl(this.state)
    let banding=globs.usersInfo.uiInfo?.tableBanding||true
    return this.state.tasks.map((ta,i)=>{
      let tableBand=(((i+1)%2)&&banding)?"tableBand":null
//       cl(globs)
//       cl(ta)
//       cl(ta.handlers)
//       cl((ta.handlers||[])[0])
      let doneItem=(this.state.showComplete)?"":"done"
      let tzo=(new Date).getTimezoneOffset()
      let da=86400+60*tzo+Date.parse(ta.dueDate)/1000//.getTime()
//       cl(da)
      let now=getTime()
//       cl(now)
      let dateColor=(now>da)?"red":null
//       cl(globs.usersInfo.uiInfo)
      if(["template","cancelled",doneItem].includes(ta.status)){
        return null
      }else{
        if(this.simple){
        return(
          <tr key={i} className={tableBand}
            style={{cursor:"pointer"}}
          >
            <td
            >{this.showComplete(ta)}</td>
            <td
              onClick={e=>this.onChange("taskLine",{task:ta})}
            >{ta.title||""}</td>
            <td
              onClick={e=>this.onChange("taskLine",{task:ta})}
              style={{color:dateColor}}
            >{ta.dueDate}</td>
            <td
              onClick={e=>this.onChange("taskLine",{task:ta})}
            >{this.showHandler((ta.handlers||[])[0], ta.handlers||[])}</td>
            <td
              onClick={e=>this.onChange("taskLine",{task:ta})}
            >{this.showSite(ta)}</td>
            <td
              onClick={e=>this.onChange("taskLine",{task:ta})}
            >{this.showZone(ta)}</td>
            <td>{this.showEdit(ta)}</td></tr>
        )
        }else{
          return(
            <tr key={i} className={tableBand}>
              <td>{this.showTitle(ta)}</td>
              <td>{this.showPriority(ta.priority)}</td>
              <td>{this.showPercent(ta.pcntComplete)}</td>
              <td>{this.showStatus(ta.status)}</td>
              <td>{this.showHandler((ta.handlers||[])[0])}</td>
              <td>{ta.dueDate}</td>
              <td>{this.showEdit(ta.taskId)}</td></tr>
          )
        }
      }
    })
  }

  getAllMarked=()=>{
    let all=true
    this.state.tasks.forEach(ta=>{
      if(ta.status!="done"){all=false}
    })
    return all
  }

  markAll=(mark)=>{
//     cl(mark)
    let tasks=this.state.tasks.slice(0)
    tasks.forEach(ta=>{
      let status=(mark)?"done":"readyToStart"
      if(ta.status!=status){
        ta.status=status
        this.setTaskComplete(ta)
      }
    })
    this.setState({tasks:tasks})
  }
  
  render(){
    if(this.state.loaded){
      return(
        <>
          <div className="floatleft">
            {false&&
              <span>
                <input type="checkbox" id="markAll"
                checked={this.getAllMarked()}
                onChange={e=>this.onChange("markAll",{mark:e.currentTarget.checked})}
                />
                <label htmlFor="markAll" style={{marginLeft:10,display:"inline-block"}}>Mark Complete</label>
              </span>
            }
          </div>
          <div className="section-controls floatright">
            <C18Anchor00 style={{float: "right"}} to={`${this.baseUrl}/taskEdit`} className="material-icons-outlined add" aria-label="add task">
              add
            </C18Anchor00>

            <br></br>

          <div className="right-checkbox top-margin-small">
              <label htmlFor="">Show Completed</label><input type="checkbox" id=""
              checked={this.state.showComplete||false}
              onChange={e=>this.onChange("upd",{showComplete:e.currentTarget.checked})}/>
          </div>

          </div>

          <div className="clearfloat"></div>
          <br />

          <div className="task-table-container">

              <table className="task-table"><tbody>
              {this.showTaskHead()}
              {this.showTaskLines()}

            </tbody></table>
          </div>  

          <div className="clearfloat"></div>

        </>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18TaskList00;
