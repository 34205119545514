import React from 'react';
import C18Anchor00 from './C18Anchor00'
import ReactHtmlParser from 'react-html-parser'
import {wsTrans,sensorIds,getUId} from '../utils/utils'
import {cl,globs,constant,dateToDisplayDate} from '../../components/utils/utils';

class C18EditInfoPages extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      search:"",
      showHtml:false,
    }
    this.loadInfo()
    this.setBreadCrumbs()
  }
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Edit InfoPages", url:`/usa/c18/admin/editInfoPages`},
            ]},
        },
      )
    }
  }
  
  loadInfo=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/info", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    cl(res)
    this.setState({loaded:true,infoPages:res.data})
  }
  
  onChange=(type,vals)=>{
    switch(type){
      case "infoLine":
        vals.e.preventDefault()
        cl(vals)
        globs.events.publish("showInfoPage", vals.infoId)
        break
      case "searchText":
        this.setState(vals)
        break
    }
  }
  
  infoPageSearch=(pages)=>{
    if(!this.state.search){return pages}
    let searchlc=this.state.search.toLowerCase()
    return pages.filter(p=>{
      let f1=(p.infoId.toLowerCase().indexOf(searchlc)>=0)
      let f2=(p.infoText.toLowerCase().indexOf(searchlc)>=0)
      return f1||f2
    })
//     cl(pages)
//     return pages
  }
  
  showHtmlWindow=()=>{
//         ch=ReactHtmlParser(pa)
    let st=this.state
    let disp=(st.showHtml)?"block":"none"
    return(
      <div style={{display:disp,backgroundColor:"white", position:"absolute",
        left:st.htmlX,top:st.htmlY,padding:20,
        borderRadius:10,
        boxShadow: "5px 5px 10px #9E9E9E",
      }}>
      {ReactHtmlParser(st.htmlText)}
      </div>
    )
    
  }
  
  onMouseOver=(e,ip)=>{
//     cl(ip)
//     cl([e.pageX,e.pageY])
    this.setState({showHtml:true,htmlX:e.pageX,htmlY:e.pageY,htmlText:ip.infoText})
  }
  
  onMouseOut=()=>{
    this.setState({showHtml:false})
//     cl("out")
  }
  
  showInfoLines=()=>{
    let pages=this.infoPageSearch(this.state.infoPages)
    let lines=pages.map((ip,i)=>{
      let da=new Date(1000*ip.ts)
      let daDisp=dateToDisplayDate(da,"mm/dd/yyyy h:mm ap",480)
      let str=ip.infoText.substring(0,50)
      return(
        <tr key={i} style={{cursor:"pointer"}}
          onClick={e=>{this.onChange("infoLine",{e:e,infoId:ip.infoId})}}
        ><td>{daDisp}</td>
        <td>
            {ip.infoId}
          </td>
        <td
        onMouseOver={e=>this.onMouseOver(e,ip)}
        onMouseOut={e=>this.onMouseOut()}
        >{str}</td></tr>
      )
//       cl(ip)
    })
    return (
      <table><tbody>
        <tr>
          <td>Date</td>
          <td>ID</td>
          <td>Text</td>
        </tr>
      {lines}
      </tbody></table>
    )
  }
  
  showSearch=()=>{
    return(
      <div>
        <label htmlFor="infoPageSearch" style={{display:"inline-block",marginRight:10}}>
          Search:</label>
        <input id="infoPageSearch"
        value={this.state.search}
        onChange={e=>this.onChange("searchText",{search:e.currentTarget.value})}
        />
      </div>
    )
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div>
        {this.showSearch()}
        {this.showInfoLines()}
        {this.showHtmlWindow()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18EditInfoPages;
