import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'
import {wsTrans} from '../utils/utils';
import {loadUsersInfo,sortUsersInfo,getUserIndex,loadZonesInfo,getZoneInfo, addToAdminLog} from './C18utils'
import {cl, globs, constant, getTime} from '../../components/utils/utils';
import history from "../../history"

class C18ManageUsers extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded: false,
      addEmail:"",
      update:0,
      showPopup: false,
    }
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveUsers)
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.loadInfo()
    this.setBreadCrumbs()
}
  
  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Admin", url:`/usa/c18/admin`},
              {t:"Manage Users", url:`/usa/c18/admin/manageUsers2`},
            ]},
        },
      )
    }
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  mySetState=(vals)=>{
    this.setState(vals)
  }
  
  loadInfo=async()=>{
    await loadUsersInfo()
    sortUsersInfo()
//     globs.usersInfo.info.sort((a,b)=>{
//       if(a.email.toLowerCase()>b.email.toLowerCase()){return 1}
//       if(a.email.toLowerCase()<b.email.toLowerCase()){return -1}
//       return 0
//     })
//     cl(globs.usersInfo.info)
    let userSel=globs.usersInfo.info[0].userId
//     cl(globs)
    this.inviteUser=await this.getPending()
//     cl(this.inviteUser)
    this.mySetState({userSel:userSel,loaded:true})
//     await loadSitesInfo()
//     await loadZonesInfo()
//     this.saveZoneNames(false)// save the names to compare later
//     let siteSel=globs.sitesInfo.info[0].siteId
//     let zoneSel=this.getZoneSel(siteSel)
//     this.setState({loaded:true, siteSel:siteSel,zoneSel:zoneSel})
//     cl(globs)
  }
  
  getPending=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/manageUsers", sessionId: globs.userData.session.sessionId, 
      method: "retrieve", body: {}})
    return res.data
  }
  
  addUser=async()=>{
    cl(this.state)
    cl(this.inviteUser)
    cl(`add user: ${this.state.addEmail}`)
    let adminAdds = []
    this.saveToAdminLog(adminAdds, "addUser", this.state.addEmail)
    addToAdminLog(adminAdds)
    let res= await wsTrans("usa", {cmd: "cRest", uri: "/s/manageUsers", sessionId: globs.userData.session.sessionId, 
      method: "create", body: {email: this.state.addEmail}})
    this.inviteUser.push({email:this.state.addEmail})
    cl(this.inviteUser)
    this.mySetState({addUserLink: res.data.token,addEmail:"", userSel:`pending-${this.state.addEmail}`})
  }
  
  deleteUserO=async()=>{
    let userId=this.state.user
    let users=Object.assign({},this.state.userOpts)
    delete users[userId]
    let user=Object.keys(users)[0]
    this.mySetState({userOpts: users, user: user})
    await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "delete", 
      sessionId: globs.userData.session.sessionId,
      body: {userId: userId}})

  }
  
  deletePending=(email)=>{
    for(let i=0;i<this.inviteUser.length;i++){
      if(this.inviteUser[i].email==email){
        this.inviteUser.splice(i,1)
        return
      }
    }
  }
  
  deleteLocalUser=(userId)=>{
    let ind=getUserIndex(userId)
    globs.usersInfo.info.splice(ind,1)
  }
  
  deleteUser=async()=>{
//     cl(this.state.userSel)
//     cl(globs.userData.session.userId)
    if(this.state.userSel==globs.userData.session.userId){
      await this.props.parms.getPopup({text:"You can't delete your own user.", buttons:["OK"]})
      return
    }
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this user?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      let userId=this.state.userSel
      let adminAdds = []
      this.saveToAdminLog(adminAdds, "deleteUser", userId)
      if(userId.indexOf("pending-")==0){
        let email=userId.substr(8)
        this.deletePending(email)
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/manageUsers", sessionId: globs.userData.session.sessionId, 
        method: "delete", body: {email:email}})
      }else{
  //       cl(this.state.userSel)
        this.deleteLocalUser(this.state.userSel)
        await wsTrans("usa", {cmd: "cRest", uri: "/s/users", method: "delete", 
          sessionId: globs.userData.session.sessionId,
          body: {userId: this.state.userSel}})
      }
      let userSel=globs.usersInfo.info[0].userId
      this.mySetState({userSel:userSel})
      addToAdminLog(adminAdds)
    }
  }

  saveToAdminLog=(adds, type, data)=>{
    let addObj={
      userId:globs.userData.session.userId,
      time:Math.floor(getTime())
    }
    adds.push(
      Object.assign({},addObj,
      {
      action: type,
      oldVal: "",
      newVal: data,
      })
    )
  }

  onChange=(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "userSel":
//         Object.assign(vals,{zoneSel:this.getZoneSel(vals.siteSel)})
        this.mySetState(vals)
        break
      case "addEmail":
        this.mySetState(vals)
        break
      case "addUser":
        this.addUser()
        break
      case "deleteUser":
//         cl(this.state.userSel)
        this.deleteUser()
        break
      case "popup":
        this.state.popupResolve(vals.cmd=="yes")
        this.setState({showPopup:false})
        cl(vals)
        break
      default:
        break
    }
  }
  
  showUserOpts=()=>{
//     cl(this.inviteUser)
    let users1=globs.usersInfo.info.map((u,i)=>{
      return(
        <option key={i} value={u.userId}>{`${u.name} (${u.email})`}</option>
      )
    })
    let users2=this.inviteUser.map((u,i)=>{
      return(
        <option key={i+1000} value={`pending-${u.email}`}>{`Pending: ${u.email}`}</option>
      )
    })
    return users1.concat(users2)
  }
  
  showUserSelect=()=>{
    return(
      <div className="custom-select">
        <label htmlFor="">Select User</label>
        <C18Select00 id=""
          value={this.state.userSel}
          onChange={e=>this.onChange("userSel",{userSel: e.currentTarget.value})}
        >
          {this.showUserOpts()}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div> 
    )
  }
  
  showAddUserLink=()=>{
    if(this.state.addUserLink){
      let url = constant.feUrl + `/usa/c18/inviteUser/${this.state.addUserLink}`;
      return(
        <p>
        This is the link in the email: 
        <a href={url}>{url}</a>
        </p>
      );
    }
  }
  
//   checkDelete=()=>{
//     return new Promise((r,e)=>{
//       cl("check")
//       this.setState({showPopup:true, popupResolve:r})
// //       r(false)
//     })
// //     cl("check")
// //     return false
//   }
//   
//   showPopup=()=>{
//     return(
//       <>
//         <div className="popup" style={{display:(this.state.showPopup)?"block":"none"}}>
//           <p>Are you sure you want to jump off this cliff?</p>
//           <br />
//           <div className="alignright">
//             <button type="button" className="outlined "
//               onClick={e=>this.onChange("popup",{cmd:"cancel"})}
//             >Cancel</button> <button type="button" className="filled left-margin"
//               onClick={e=>this.onChange("popup",{cmd:"yes"})}
//             >Yes</button>
//           </div>
//         </div>
//         <div className="popup-scrim"></div>
//       </>
//     )
//   }
  
  render(){
//     cl(this.state)
    if(this.state.loaded){
//       cl(this.state.userSel)
      let email=globs.usersInfo.info[getUserIndex(this.state.userSel)]?.email
      return(
        <div>
          {this.showUserSelect()}
            <C18Button00 type="button" className="danger" onClick={()=>this.onChange("deleteUser")}>Delete</C18Button00>
                <br /><br />
                <div className="indented-text">{email}</div>
                <br /><hr /><br />
                <p>Add User</p>
                <label htmlFor="new-user-email">Email of New User</label>
                <C18Input00 type="email" id="new-user-email" 
                  value={this.state.addEmail}
                  onChange={e=>this.onChange("addEmail",{addEmail:e.currentTarget.value})}
                />
                <C18Button00 type="button" className="outlined" onClick={()=>this.onChange("addUser")}>Add User</C18Button00>
                <br/>
                {this.showAddUserLink()}
        </div>
      )
    }else{
      return <div>loading. . .</div>
    }
  }
}
      
export default C18ManageUsers;
