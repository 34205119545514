import React, { Component } from 'react';
// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
// import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
// import theme from './theme';
import { Router, Route } from "react-router-dom";// , Link
// import logo from './logo.svg';
// import {getParamIds} from './components/utils/paramIds';
// import Floater from './components/Floater';
// import MainOne from './components/MainOne';
// import Login from './components/temps/Login00'
// import CreateUser from './components/temps/CreateUser00'
// import SiteArrayParent from './components/SiteArrayParent'
// import ZoneArray from './components/ZoneArray'
// import UnitArray from './components/UnitArray'
// import Param from './components/Param00'
// import ParamTable from './components/ParamTable00'
// import ConfigTest from './components/configs/ConfigTest';
// import ZoneSettings from './components/ZoneSettings';
// import Channel from './components/Channel';
// import Templates from './fui/Templates';
// import E32Host from './e32/components/E32Host';
// import Log00 from './e32/components/Log00';
// import Demo1 from './visualization/components/Demo1';
// import Log from './components/Log';
// import Log from './components/viewlog/ViewLog';
import SelectFui from './fui/SelectFui';
import EditFui from './fui/EditFui';
import EditControlFui from './fui/EditControlFui';
import LiveFui from './fui/LiveFui';
import Usa from './usa/components/Usa';
import C2CodeSplit00 from './cs00/C2CodeSplit00';
import {cl,start,stop,show} from './components/utils/utils'
import history from "./history"
// import './App.css';

class App extends Component {
  constructor(props){
    super(props);
    start("main")
    // getParamIds();
    // loadTextures();
    // loadTables().then(r=>{
    //   // cl("loaded");
    //   this.setState({loaded: true});
    // });
    this.state = {
      loaded: true,
    }
  }

  setupRouter(){
//           <Route path="/usa/:p1/:p2" exact component={Usa} />
//           <Route path="/usa/:p1" exact component={Usa} />
//           <Route path="/usa" exact component={Usa} />
//           <Route path="/usa/:p1/:p2/:p3" exact component={Usa} />
    
//           <Route path="/e32" exact component={E32Host} />
//           <Route path="/log" exact component={Log00} />
//           <Route path="/e32/*" component={E32Host} />
//           <Route path="/ce" exact component={MainOne} />
//           <Route path="/li" exact component={Login} />
//           <Route path="/cu" exact component={CreateUser} />
//           <Route path="/sa" exact component={SiteArrayParent} />
//           <Route path="/za/:id" exact component={ZoneArray} />
//           <Route path="/ua/:zid/:uid" exact component={UnitArray} />
//           <Route path="/tmp" exact component={Templates} />
//           <Route path="/params" exact component={Param} />
//           <Route path="/paramtab" exact component={ParamTable} />
//           <Route path="/ch/:zuc" exact component={Channel} />
//           <Route path="/zs/z/:zone/:page" exact component={ZoneSettings} />
//           <Route path="/co/z/:zid/c/:cid" exact component={ConfigTest} />
//       <Floater/>
//       <Floater/>
    return (
      <div className="App">
      <Router history={history}>
          <Route path="/" exact component={Usa} />
          
          <Route path="/fui" exact component={SelectFui} />
          <Route path="/fui/:pageType" exact component={SelectFui} />
          <Route path="/fui/:pageType/zuci/:zuci" exact component={SelectFui} />
          <Route path="/fui/edit/:pageType" exact component={EditFui} />
          <Route path="/fui/controledit/:pageType/:controlId" exact component={EditControlFui} />
          <Route path="/fui/controledit/:pageType/" exact component={EditControlFui} />
          <Route path="/fui/live/:pageType/:zuci" exact component={LiveFui} />
          
          <Route path="/usa" exact component={Usa} />
          <Route path="/usa/c18/*" component={Usa} />
          <Route path="/cs/*" component={C2CodeSplit00} />
      </Router>
      </div>
    )
  }
// this used to be a part of the routing:
//   <Route path="/fui/controledit/:pageType/:zuci" exact component={EditControlFui} />
  

  loadingPage(){
    return(
      <div>loading</div>
    )
  }

  render() {
    // cl(getScreenSize());
// Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/72.0.3626.121 Safari/537.36
    if(this.state.loaded){
      return this.setupRouter();
    } else {
      return this.loadingPage();
    }
    // return (
    // );
  }
}

export default App;
