import React from 'react';
import C18Input00 from './C18Input00'
import C18Select00 from './C18Select00'
import C18Button00 from './C18Button00'
import C18TabsHeader00 from './C18TabsHeader00'
import {wsTrans} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadUsersInfo,loadAccountInfo,
  loadGatewaysInfo,getGatewayInfo,
  getGatewayIndex, addToAdminLog,getSiteName,getZoneName} from './C18utils'
import {cl, globs, getTime,getRandomString,
  dateToDisplayDate} from '../../components/utils/utils';
import history from "../../history"

class C18SaveRestore00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      saveName:"",
      loaded:false,
      tab:"restore",
      selConfig:-1,
      sortMode:1,
    }
    this.tabs=[
      {v:"save",t:"Save"},
      {v:"restore",t:"Restore"},
    ]
    this.restoreParts=[
// Equipment: 500-599, 600-1023
// Aux Controls: 1216-1343, 1344-3639, 3640-3895
// Expansion Boards: 1152-1215
// Controller Settings: 4216-4555, 5024-5027
// Zone Settings: 4716-5021,5022-5023
// ECPH: 4556-4566,4567-4635
// Setpoints:4636-4715
// Sensors: 5200-7199

      {v:"allParts",t:"All Parts",r:[[0,7199]]},
      {v:"eqip",t:"Equipment",r:[[500,1023]]},
      {v:"auxControls",t:"Aux Controls",r:[[1216,3895]]},
      {v:"xBoards",t:"Expansion Boards",r:[[1152,1215]]},
      {v:"contSettings",t:"Controller Settings",r:[[4216,4555],[5024,5027]]},
      {v:"zoneSettings",t:"Zone Settings",r:[[4716,5023]]},
      {v:"ecph",t:"ECPH",r:[[4556,4635]]},
      {v:"setpoints",t:"Setpoints",r:[[4636,4715]]},
      {v:"sensors",t:"Sensors",r:[[5200,7199]]},
    ]
    this.loadInfo()
//     this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveZoneGroups)
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
//     this.loadInfo()
    this.setBreadCrumbs()
  }
  
  setBreadCrumbs=()=>{
    let pa=this.props.parms
    let siteName=getSiteName(pa.site)
    let zoneName=getZoneName(pa.zone)
    cl(pa)
    if(pa){
      pa.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${pa.site}`},
              {t:zoneName, url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}`},
              {t:"Zone Config", url:`/usa/c18/sites/${pa.site}/zones/${pa.zone}/saveRestore`},
            ]},
        },
      )
    }
  }
  
//   componentWillUnmount=()=>{
//     this.subscribe_savePageEvent.remove()
//   }

  loadInfo=async()=>{
    await loadUsersInfo()
    await loadZonesInfo()
    await loadSitesInfo()
    let user=globs.usersInfo.info.filter(u=>{
      return u.userId==globs.userData.session.userId})[0]
    cl(user)
    let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneConfig", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
    let saves=resp.data
    cl(user)
    if(user.p3){
      let resp2=await wsTrans("usa", {cmd: "cRest", uri: "/tp/tpZoneConfig", 
        method: "retrieve", sessionId: globs.userData.session.sessionId, 
        body: {accountId:user.p3AccountId}})
      saves=saves.concat(resp2.data)
    }else{}
    saves.forEach(sa=>{
      let zi=globs.zonesInfo.info.filter(z=>{return z.zoneId==sa.zoneId})[0]
      sa.zName=zi.zoneName
    })
    cl(saves)
    let flags={}
//     cl(this.restoreParts)
    this.restoreParts.forEach(rp=>{flags[rp.v]=true})
    this.setState({loaded:true,saves:saves,restoreFlags:flags})
  }

  saveConfig=async()=>{
    let user=globs.usersInfo.info.filter(u=>{
      return u.userId==globs.userData.session.userId})[0]
    cl(user)
    let pa=this.props.parms
    let st=this.state
//     cl(st)
//     cl("save Config")
    let zi=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    let configId=getRandomString(16)
    let query={siteId:pa.site,zoneId:pa.zone,zoneInd:zi.siteZoneIndex,
      configId:configId,name:st.saveName}
    let saves=st.saves.slice(0)
    if(user.p3){
      query.accountId=user.p3AccountId
      cl(query)
      let resp=await wsTrans("usa", {cmd: "cRest", uri: "/tp/tpZoneConfig", method: "create", 
        sessionId: globs.userData.session.sessionId, body: query})
    }else{
      let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneConfig", method: "create", 
        sessionId: globs.userData.session.sessionId, body: query})
    }
    query.ts=getTime()
    query.zName=zi.zoneName
    saves.push(query)
//     cl("save it")
    this.setState({saves:saves})
  }
  
  makePidRange=(flags)=>{
    let ranges=[]
    let parts=this.restoreParts
    if(flags.allParts){
      ranges.push(
//         {i:{$gte:0,$lte:0x5000}}// all 1800 values
        [0,0x5000]// all 1800 values
      )
    }else{
      Object.keys(flags).forEach(k=>{
        if(flags[k]){
          let rp=this.restoreParts.filter(p=>{return p.v==k})[0]
          rp.r.forEach(ids=>{
            ranges.push(ids)
//               {i:{$gte:ids[0],$lte:ids[1]}}
//             )
          })
        }
      })
    }
    return ranges
  }
  
  restoreConfig=async()=>{
    let st=this.state
    let pa=this.props.parms
    cl(st.restoreFlags)
    let ranges=this.makePidRange(st.restoreFlags)
    let config=st.saves.filter(sa=>{return sa.configId==st.selConfig})[0]
    let zi=globs.zonesInfo.info.filter(z=>{return z.zoneId==config.zoneId})[0]
    let zi2=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    let da=new Date(1000*config.ts)
    let da2=`${dateToDisplayDate(da,"mm/dd/yyyy")} ${dateToDisplayDate(da,"hh:mm")}`
    let text=`This will restore the selected Parts of Settings from the Saved Configuration 
    called ${config.name} of ${zi.zoneName} saved on ${da2} to the current zone, ${zi2.zoneName}. Are you sure you want to do this?`
    let res=await this.props.parms.getPopup({text:text, buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      cl("restore")
      let query={configId:config.configId,siteId:globs.userData.session.siteId,
        zoneInd:zi2.siteZoneIndex,ranges:ranges}
      cl(query)
      let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneConfig", method: "update", 
        sessionId: globs.userData.session.sessionId, body: query})
    }
  }
  
  deleteConfig=async()=>{
    let st=this.state
    let saves=st.saves.slice(0)
    let cf=saves.filter(s=>{return s.configId==st.selConfig})[0]
    cl(cf)
    let text=`Are you sure that you want to delete the Saved Configuration called ${cf.name}?`
    let res=await this.props.parms.getPopup({text:text, buttons:["Cancel","Yes"]})
    if(res=="Yes"){
      for(let i=0; i<st.saves.length;i++){
        if(st.saves[i].configId==st.selConfig){
          saves.splice(i,1)
        }
      }
      let resp=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneConfig", method: "delete", 
        sessionId: globs.userData.session.sessionId, body: {configId:st.selConfig}})
      this.setState({saves:saves})
    }
  }

  sortSaves=(tasks,sortMode)=>{
    let field=["","zName","name","ts"][Math.abs(sortMode)]
    let dir=(sortMode>0)?1:-1
    tasks.sort((a,b)=>{
      
      if(a[field]>b[field]){return dir}
      if(a[field]<b[field]){return 0-dir}
      return 0
    })
    return tasks
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    let st=this.state
//     cl(st.saves)
    switch(type){
      case "tabs":
        cl(vals)
        this.setState(vals)
        break
      case "saveName":
        this.setState(vals)
        break
      case "saveConfig":
        this.saveConfig()
        break
      case "sort":
        if(Math.abs(vals.sortMode)==st.sortMode){
          vals.sortMode=0-st.sortMode
        }
        let saves=this.sortSaves(st.saves.slice(0),vals.sortMode)
        vals.saves=saves
//         Object.assign(vals,{saves:saves})
        this.setState(vals)
        break
      case "selConfig":
        this.setState(vals)
        break
      case "flags":
        let flags=Object.assign({},st.restoreFlags)
        if(vals.item=="allParts"){
          Object.keys(flags).forEach(k=>{
            flags[k]=vals.flag
          })
        }else{flags[vals.item]=vals.flag}
        this.setState({restoreFlags:flags})
        break
      case "restoreConfig":
        this.restoreConfig()
        break
      case "deleteConfig":
        this.deleteConfig()
        break
    }
  }
 
  showSave=()=>{
//     cl("save")
//     cl(this.props)
    let pa=this.props.parms
    let zi=globs.zonesInfo.info.filter(z=>{return z.zoneId==pa.zone})[0]
    let si=globs.sitesInfo.info.filter(s=>{return s.siteId==pa.site})[0]
//     let si=globs.sitesInfo.info.filter(s=>{return s.siteId=pa.site})
//     cl([zi,si])
    let zName=zi.zoneName
    let sName=si.name
    let desc=`This will save the current settings of ${zName} in ${sName}, with \
the current date and the name given below.`
    return(
      <div>
        <p>{desc}</p>
        <label htmlFor="save-name">Configuration Save Name</label>
        <C18Input00 type="text" id="save-name" className="with-right-button"
          value={this.state.saveName}
          onChange={e=>this.onChange("saveName",{saveName:e.currentTarget.value})}
        />
        <C18Button00 type="button" className="filled"
        onClick={()=>this.onChange("saveConfig",{})}>Save</C18Button00>
      </div>
    )
  }
  
  showSaveHead=()=>{
    let sort=this.state.sortMode// 1-5 for the columns
    let icons=[]
    for(let i=0;i<3;i++){
      let icon=""
      if(Math.abs(sort)==i+1){
        icons.push((sort<0)?"keyboard_arrow_up":"keyboard_arrow_down")
      } else{
        icons.push("")
      }
    }
    return(
      <tr>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:1})}
          >Zone <span className="material-icons-outlined">{icons[0]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:2})}
          >Name <span className="material-icons-outlined">{icons[1]}</span></button></th>
          <th><button type="button" aria-label="sort"
            onClick={()=>this.onChange("sort",{sortMode:3})}
          >Date <span className="material-icons-outlined">{icons[2]}</span></button></th>

      </tr>
    )
  }
  
  showRestoreParts=()=>{
//     cl(this.state.restoreFlags)
//     let lines=[]
    let lines=this.restoreParts.map((rp,i)=>{
//       cl(this.state.restoreFlags[rp.v])
      return(
        <tr key={`rp${i}`}>
        <td><input id={`rp${i}`} type="checkbox"
          checked={this.state.restoreFlags[rp.v]}
          onChange={e=>this.onChange("flags",{item:rp.v, flag:e.currentTarget.checked})}
        />
        <label htmlFor={`rp${i}`} style={{display:"inline-block",marginLeft:20}}>
        {rp.t}</label>
        </td>
        
        </tr>
      )
    })
    return(
      <div style={{width:500,padding:20,
        borderStyle:"solid",borderWidth:1,borderRadius:10}}>
        <table style={{width:"100%"}}><tbody>
        
        {lines}
        </tbody></table>
      </div>
    )
  }
  
  showSaveList=()=>{
    let st=this.state
    let lines=st.saves.map((sa,i)=>{
      let da=new Date(1000*sa.ts)
      let da2=`${dateToDisplayDate(da,"mm/dd/yyyy")} ${dateToDisplayDate(da,"hh:mm")}`
//       let zi=globs.zonesInfo.info.filter(z=>{return z.zoneId==sa.zoneId})[0]
//       cl(zi)
//       cl(zi.zoneName)
      let bgColor=(sa.configId==st.selConfig)?"#CCFFFF":"#FFFFFF"
      return(
        <tr key={i} style={{cursor:"pointer",backgroundColor:bgColor}}
          onClick={e=>this.onChange("selConfig",{selConfig: sa.configId})}
        >
        <td>{sa.zName}</td>
        <td>{sa.name}</td>
        <td>{da2}</td>
        </tr>
      )
    })
    return(
      <div style={{width:500,padding:20,
        borderStyle:"solid",borderWidth:1,borderRadius:10}}>
        <table style={{width:"100%"}}><tbody>
        {this.showSaveHead()}
        {lines}
        </tbody></table>
      </div>
    )
  }

  showRestore=()=>{
    let st=this.state
    let disabled=(this.state.selConfig==-1)
//     cl(disabled)
//     cl(st)
    return(
      <div>
      <h3>Saved Configurations</h3>
      {this.showSaveList()}
      <div className="clearfloat"/><br/>
      <C18Button00 type="button" className="danger"
      disabled={disabled}
      onClick={e=>this.onChange("deleteConfig")}>Delete</C18Button00>
      
      <h3>Parts to Restore</h3>
      {this.showRestoreParts()}
      <div className="clearfloat"/><br/>
      <C18Button00 type="button" className="filled" disabled={disabled}
      onClick={()=>this.onChange("restoreConfig",{})}>Restore</C18Button00>
      </div>
    )
    
  }

  showSaveRestore=()=>{
    switch(this.state.tab){
      case "save":
        return this.showSave()
        break
      case "restore":
        return this.showRestore()
        break
    }
    
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div id="zone_save_restore">
          <div className="tabs">
            <C18TabsHeader00
              tabId={this.state.tab}
              tabs={this.tabs}
              onChange={o=>this.onChange("tabs",o)}
            />
            <div className="tab-panels">
              <div className="tab-panel selected" role="tabpanel">
                {this.showSaveRestore()}
                <div className="clearfloat"></div>
              </div> 
            </div>
          </div>
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18SaveRestore00;
