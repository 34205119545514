import React from 'react';
import C18MuiSelect from '../../usa/components/C18MuiSelect'
import C18MuiMenuItem from '../../usa/components/C18MuiMenuItem'
import {acctFeature} from '../../usa/components/C18utils'
import {wsTrans} from '../../usa/utils/utils'
import {cl, globs} from '../../components/utils/utils';

class ZoneGroups extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={loaded:false,selGroup:-1}
    this.props.getValue(props.ind, {type: "zoneGroup"})
    this.loadInfo()
  }
  
  loadInfo=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/zoneGroups", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body:{} })
//     cl(res)
    this.groups=((res.data?.groups||{})[globs.userData.session.siteId])||[]
//     cl(this.groups)
    this.setState({loaded:true})
  }
  
  onChange=async(type,vals)=>{
//     cl(type,vals)
    let st=this.state
    switch(type){
      case "selGroup":
        this.props.onChange(this.props.ind,
          {value: vals.selGroup})
        break
    }
  }
  
  
  makeSelect=()=>{
//     let options=[
//       {v:1,t:"This Zone Only"},
//       {v:2,t:"Group One"},
//       {v:3,t:"Group Two"},
//     ]
    let options=[{v:-1,t:"This Zone Only"}]
    Object.keys(this.groups).forEach(k=>{
      options.push(
        {v:k,t:this.groups[k].name}
      )
    })
//     cl(options)
    return (
      options.map((c, i)=>{
        return(
          <C18MuiMenuItem key={i} value={c.v}>{c.t}</C18MuiMenuItem>
        )
      })
    )
    return options
  }
  
  

  render(){
//     cl(this.props.value)
    if(!acctFeature("zoneGroups")){return null}
    if(this.state.loaded){
      return(
        <div className="clearfloat">
          <label>Apply To Zones</label>
          <C18MuiSelect
              value={this.props.value}
              onChange={e=>this.onChange("selGroup",{selGroup:e.currentTarget.value})}
              saveOK={this.props.saveOK}
              unit={this.props.custom.unit}
          >
            {this.makeSelect()}
          </C18MuiSelect>
        </div>
      );
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

  export default ZoneGroups ;
