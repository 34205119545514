import React from 'react';
import {acctFeature} from './C18utils'
import config from '../../components/utils/config';
import {cl,globs} from '../../components/utils/utils';

class C18SidebarHeader00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
//     this.state={
//       mode:"growJournal"
//     }
    this.subscribe_newContextEvent=globs.events.subscribe("newContext",this.newContext)
  }
  
  componentDidMount=()=>{
    this.mounted=true
  }
  componentWillUnmount=()=>{
    this.subscribe_newContextEvent.remove()
    this.mounted=false
  }
  
  onChange=(type,vals)=>{
//     cl(type)
    this.setState({mode:type})
    this.props.parms.onChange({mode:type})
  }
  
  newContext=(vals)=>{
//     cl(vals)
    let p=this.props.parms
    if((vals.level!="zone")&&(p.mode=="generalInfo")){
      p.onChange({mode:"growJournal"})
    }
//     cl(p)
  }
  
  render(){
//         <!-- <button type="button" className="selected" aria-pressed="true">General Info</button> -->
//         <button type="button" className={(this.state.mode=="tasks")?"selected":""} 
//           aria-pressed={(this.state.mode=="tasks")?"true":"false"} onClick={o=>this.onChange("tasks")}>Tasks</button>
    let mode=this.props.parms.mode
    return(
      <div id="/sidebar/header" className="sidebar-header user-toggle-field filled">
        <button id="/sidebar/header/growJournal" type="button" className={(this.props.parms.mode=="growJournal")?"selected":""} 
          aria-pressed={(mode=="growJournal")?"true":"false"} onClick={o=>this.onChange("growJournal")}>Grow Journal</button>
        <button id="/sidebar/header/alarms" type="button" className={(mode=="alarms")?"selected":""} 
          aria-pressed={(mode=="alarms")?"true":"false"} onClick={o=>this.onChange("alarms")}>Alarms</button>
        <button id="/sidebar/header/auditLog" type="button" className={(mode=="auditLog")?"selected":""} 
          aria-pressed={(mode=="auditLog")?"true":"false"} onClick={o=>this.onChange("auditLog")}>Audit Log</button>
        <button id="/sidebar/header/images" type="button" className={(mode=="images")?"selected":""} 
          aria-pressed={(mode=="images")?"true":"false"} onClick={o=>this.onChange("images")}>Images</button>
        {(this.props.parms.level=="zone")&&
          <button id="/sidebar/header/generalInfo" type="button" className={(mode=="generalInfo")?"selected":""} 
            aria-pressed={(mode=="generalInfo")?"true":"false"} onClick={o=>this.onChange("generalInfo")}>General Info</button>
        }
        {acctFeature("tasks")&&
          <button id="/sidebar/header/tasks" type="button" className={(mode=="tasks")?"selected":""}
            aria-pressed={(mode=="tasks")?"true":"false"} onClick={o=>this.onChange("tasks")}>Tasks</button>
        }
      </div>
    )
  }
}
      
export default C18SidebarHeader00;
