import React from 'react';
import UsaIcon from './UsaIcon';
import UsaSelect00 from './UsaSelect00';
import UsaCheckbox00 from './UsaCheckbox00';
import IconButton from '@material-ui/core/IconButton';
import Camera from '@material-ui/icons/Camera';
import {cl, globs, constant, getRandomString} from '../../components/utils/utils';
import {wsTrans, doGetPostBasic} from '../utils/utils'
import history from "../../history"


class Dashboard00 extends React.Component{
  constructor(props) {// 
    super(props);
//     cl(props)
//     cl(this.props.parms.dashboardId)
    this.state={
      editMode: false,
//       dashboardIcon1: "dashboard-Create",
//       dashboardIcon2: "dashboard-Edit",
//       dashboardIcon3: "dashboard-Delete",
//       dashboardOpts: [],
//       dashboards: [],
      dashboardSel: this.props.parms.dashboardId,
      dashboardName: "",
      gridSize: 100,
      gridWidth: 2,
      gridHeight: 2,
      dynamic: false,
      proportional: false,
      bgColor: "",
      defZone: "",
      defChannel: "",
      defIndex: "",
    }
    this.backSel=""
    this.dashboards={}
    this.dashboardOpts=[]
    this.loadDashboards()
//     let rs=getRandomString(8)
//     cl(rs)
  }
  
//   getDashboard=(sel)=>{
//     let dash=this.state.dashboards[sel]
//     
//   }
//   saveRestDashboard=(cmd)=>{
//     if(cmd==="save"){
//       this.backDashboard={
//         dashboardName: this.state.dashboardName,
//         dashboardSel: this.state.dashboardSel,
//         gridSize: this.state.gridSize,
//         gridWidth: this.state.gridWidth,
//         gridHeight: this.state.gridHeight,
//       }
//     }else{
//       this.setState(this.backDashboard)
//     }
//     
//   }
  
  loadDashboards=async()=>{
//     cl(this.state.dashboardSel)
//     cl(globs.userData.session.sessionId)
//     cl("loadDashboards")
//     let getDashboard=;
    let dashboardSearch={a:this.props.parms.dashboardAcct}
    if(!dashboardSearch){dashboardSearch={u: globs.userData.session.userId}}
//     cl(dashboardSearch)
    let r = await wsTrans("usa", {cmd: "cRest", uri: "/s/dashboards", method: "retrieve2", sessionId: globs.userData.session.sessionId,
      body: dashboardSearch})// {u: globs.userData.session.userId}
//     cl("got dashboards")
    let dbsf={}
    r.data.forEach(db=>{
      dbsf[db.i]=db
    })
//     cl(r.data)
    let d0=r.data[0]
// 	cl(d0)
    let sel=(this.state.dashboardSel) ? this.state.dashboardSel : d0.i
//     cl(sel)
//     sel="aYZJ_82ZMKbJeFsg"
//     sel="vz4sci_m-iHC9nQZ"
//     sel="3CtZxfzKKZ-EE51x"
//     cl(d0)
    this.backSel=sel
    this.dashboards=dbsf
    this.makeOpts()
    let st={dashboardSel: sel, dashboardName: d0.n}
//     cl("state")
    this.setState(st)// call *after* makeOpts
//     cl(st)
    this.props.onChange(st)
//     cl("load dash done")
    
  }
  
  makeOpts=()=>{
    let dbs=[]
    Object.values(this.dashboards).forEach(d=>{
      dbs.push({v: d.i, t: d.n})
    })
    this.dashboardOpts=dbs
  }
  
  saveDashboard=async()=>{
//     cl(this.dashboards)
//     cl("--" + this.state.defZone + "--")
    cl(this.state)
    var none
    let ds={
      a: globs.userData.session.accountId,
      n: this.state.dashboardName,
      d: this.state.dynamic,
      p: this.state.proportional,
      s: this.state.gridSize,
      w: this.state.gridWidth,
      h: this.state.gridHeight,
      i: this.state.dashboardSel,
      z: this.state.defZone,// : 0,
      c: this.state.defChannel,// : 0,
      ix: this.state.defIndex,// : 0,
//       z: (this.state.defZone) ? this.state.defZone : 0,
//       c: (this.state.defChannel) ? this.state.defChannel : 0,
//       ix: (this.state.defIndex) ? this.state.defIndex : 0,
//       z: ((!this.state.defZone)||this.state.defZone=="(none)") ? none : this.state.defZone,
//       c: ((!this.state.defChannel)||this.state.defChannel=="(none)") ? none : this.state.defChannel,
//       ix: ((!this.state.defIndex)||this.state.defIndex=="(none)") ? none : this.state.defIndex,
      bi: this.state.backImage,
      bg: this.state.imgName,
      col: this.state.bgColor,
    }
    cl(ds)
    let putDashboard={cmd: "cRest", uri: "/s/dashboards", method: "update", sessionId:
      globs.userData.session.sessionId, body: ds};
    cl("dashboard updated")
    globs.events.publish("dashboardUpdated", {dashboard: ds})
    let r=await wsTrans("usa", putDashboard)
    this.sendImage()
    
    ds.i=r.data.i
//     cl(ds)
    this.dashboards[ds.i]=ds
//     cl(this.dashboards)
    this.makeOpts()
    cl("state")
    this.setState({dashboardSel: ds.i})
//     cl(this.dashboardOpts)
  }
  
  deleteDashboard=()=>{
    let body={id: this.state.dashboardSel}
    delete this.dashboards[this.state.dashboardSel]
//     cl(this.dashboards)
//     let k=Object.keys(this.dashboards)[0]
    this.makeOpts()
    cl("state")
    this.setState({dashboardSel: this.dashboardOpts[0].v})

//     delete this.dashboards[this.state.dashboardId]
//     let key=Object.keys(this.dashboardOpts)[0]
//     this.setState({dashboardId: key, dashboardName: this.dashboardOpts[key]})
//     let body={i: this.state.dashboardSel}
    let deleteDashboard={cmd: "cRest", uri: "/s/dashboards", method: "delete", sessionId: globs.userData.session.sessionId,
      body: body};
    wsTrans("usa", deleteDashboard).then(r=>{
//       cl(r);
    });
  }
  
  markImage=async(e)=>{
//     cl(e.target.files)
    let files=e.target.files[0]// first file?
    let imageId= getRandomString(16)
    let ext=files.name.substr(files.name.lastIndexOf('.')+1)
    this.setState({imgName: `${imageId}.${ext}`, images: files})
  }
  
  sendImage=()=>{
    let n=this.state.imgName
    if(!n){return}
    let imageId=n.substr(0,n.lastIndexOf('.'))
    let data = new FormData()
    data.append("type", "background")
    data.append("sessionId", globs.userData.session.sessionId)
    data.append('file', this.state.images)
    data.append('id', imageId)
    let url=`${constant.expressUrl}/usa/images`
    let method="POST"
    let type="multipart/form-data"
    doGetPostBasic(url, method, data, type)
  }
  
//   onChange=(type)=>{
//     cl(type)
//   }

  saveDashboardCopy=async(dashboard)=>{// these routines save values to *different* accountId, and are unsafe!
//     cl([dashboard, accountId, newDashId])
//     let dash2=Object.assign({}, dashboard, {a: accountId, i: newDashId})
//     delete dash2.u
//     cl(dash2)
//     cl("save dashboard")
    cl("save dashboard: " + dashboard.i)
    delete dashboard["_id"]
    cl(dashboard)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/dashboards", method: "createForeign", 
      sessionId:globs.userData.session.sessionId, body: dashboard})
  }
  
  saveWidgets=async(widgets)=>{
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/widgets", method: "createForeign", 
      sessionId:globs.userData.session.sessionId, body: widgets})
  }

//   copyOneDashboard=(acct,dash)=>{
//     let widgets=this.getDashboardWidgets(dash)
//     
//   }
  
  getDashboardWidgets=(acct,dash)=>{
    let widgets=[]
    Object.keys(acct.widgets).forEach(k=>{
      let wdg=acct.widgets[k]
      if(wdg.dashboard==dash){
        widgets.push(wdg)
      }
    })
    return widgets
  }
  
  getAllDashboards=async(acct)=>{
    let r = await wsTrans("usa", {cmd: "cRest", uri: "/s/dashboards", method: "retrieve2", sessionId: globs.userData.session.sessionId,
      body: {}})
    let dashboards={}
    r.data.forEach(d=>{
      dashboards[d.i]=d
    })
    acct.dashboards=dashboards
  }
  
  getAllWidgets=async(acct)=>{
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/widgets", method: "retrieve", 
      sessionId: globs.userData.session.sessionId, body: {}})
//     cl(r)
    let widgets={}
    r.data.forEach(w=>{
      widgets[w.id]=w
    })
    acct.widgets=widgets
    
  }
  
  deleteWidgets=async(copyWidgets)=>{
    let arr=[]
    copyWidgets.forEach(w=>{
      arr.push(w.id)
    })
    cl(`widgets: ${arr.toString()}`)
//     cl(arr)
    let r = await wsTrans("usa", {cmd: "cRest", uri: "/s/widgets", method: "delete", sessionId: globs.userData.session.sessionId,
      body: {id: arr}})
    cl(r)
  }
  
  deleteDashboards=async(dashboards)=>{
    let arr=[]
    Object.keys(dashboards).forEach(d=>{
      arr.push(d)
    })
    cl(`dashboards: ${arr.toString()}`)
    let r = await wsTrans("usa", {cmd: "cRest", uri: "/s/dashboards", method: "delete", sessionId: globs.userData.session.sessionId,
      body: {i: arr}})
  }
  
  getLinkDashboard=(link)=>{
//     cl(link)
    if(link&&link.indexOf("/usa/dash/")==0){
      let ret=link.substr(10,16)
//       cl(ret)
      return ret
    }
    
  }
  
  copyDeleteDashboardTree=async(doDelete)=>{
//     cl(this.state)
    let userId=globs.userData.session.userId
//     cl(userId)
    let accountId=this.state.dashboardCopy
    let acct={}
    await this.getAllDashboards(acct)
    await this.getAllWidgets(acct)
    let dashboards=[this.state.dashboardSel]
    let i=0
    let dashGotIds={}
    let copyWidgets=[]
//     let widgetGotIds={}
    while (i< dashboards.length){// over *all* the dashboards found
      let dashId=dashboards[i]
      if(!(dashId in dashGotIds)){// if not done yet
//         cl(dashId)
        let newDashId= getRandomString(16)
        dashGotIds[dashId]=newDashId// mark as got
//         cl(newDashId)
        let dash=Object.assign({},acct.dashboards[dashId], {a:accountId, i: newDashId})// a *copy* of the dashboard
        delete dash.u// add new accountId, dashId, and remove user
        if(!doDelete){this.saveDashboardCopy(dash)}// with updated account and id
        let widgets=this.getDashboardWidgets(acct, dashId)
//         let copyWidgets={}// id=>widget
        widgets.forEach(w=>{// all the widgets on this dashboard
          copyWidgets.push(w)// list of all the widgets found, need to have id, dashboards, and links fixed, also, accountId added
//           let link=w.parameters.link
          let link=this.getLinkDashboard(w.parameters.link)
          if(link){
            let dashId2=link// .substr(10)
//             cl(dashId2)
            if(!(dashId2 in dashGotIds)){
              dashboards.push(dashId2)
            }
          }
//           if(link&&link.indexOf("/usa/dash/")==0){// those widgets that link to other dashboards
//           }
        })
      }
      i+=1
//       cl(dashboards)
    }
//     cl(dashGotIds)
//     cl(copyWidgets)
//     cl(dashGotIds)
    if(doDelete){
      this.deleteWidgets(copyWidgets);
      this.deleteDashboards(dashGotIds);
    }else{
      copyWidgets.forEach(w=>{// update info
        w.id=getRandomString(16)
        delete w["_id"]
        w.dashboard=dashGotIds[w.dashboard]
        w.accountId=accountId
        let link=w.parameters.link
        if(link&&link.indexOf("/usa/dash/")==0){// those widgets that link to other dashboards
          let oldLink=link.substr(10)
          w.parameters.link=`/usa/dash/${dashGotIds[oldLink]}`
        }
      })
//       cl(copyWidgets)
      this.saveWidgets(copyWidgets)
    }
  }
  
  deleteDashboardTree=()=>{
//     cl("delete tree to ")
    if(window.confirm("REALLY? DELETE IT???!!!")){
      cl("delete")
      this.copyDeleteDashboardTree(true)
    }
  }
  
  setAsHome=async()=>{// this has to be done from the account, and site to be used
    cl(this.state)
    cl(globs.userData.session)
    let r = await wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "update", sessionId: globs.userData.session.sessionId,
      body: {siteId: globs.userData.session.siteId, homeDashboard: this.state.dashboardSel}})
  }

  result=(type)=>{
//     cl(type)
    switch(type){
      case "dashboard-OK":
        if(this.state.dashboardSel==""){
          cl(this.state)
          this.saveDashboard()
        }else{
          this.saveDashboard()
        }
        cl("state")
        this.setState({editMode: false})
        break
      case "dashboard-Cancel":
//         this.saveRestDashboard("restore")
        cl("state")
        this.setState({editMode: false, dashboardSel: this.backSel})
        break
      case "dashboard-Edit":
        let db=this.dashboards[this.state.dashboardSel]
        cl(db)
        let zone=(db.z) ? db.z : ""
        let channel=(db.c) ? db.c : ""
        let index=(db.ix) ? db.ix : ""
//         let st=Object.assign({editMode: true}, this.state.dashboards[this.state.dashboardSel])
//         cl(st)
//         this.saveRestDashboard("save")
        this.backSel=this.state.dashboardSel
//         cl("state")
        this.setState({editMode: true, dashboardName: db.n, dynamic: db.d, proportional: db.p, gridSize: db.s, gridWidth: db.w, gridHeight: db.h,
          defZone: zone, defChannel: channel, defIndex: index, bgColor: db.col, backImage: db.bi, imgName: db.bg,
        })
        break
      case "dashboard-New":
        cl("state")
        this.setState({editMode: true, dashboardName: "", dashboardSel: "", dynamic: false, proportional: false, gridSize: 50, gridWidth: 6, gridHeight: 6})
        break
      case "dashboard-Delete":
        this.deleteDashboard()
//         this.setState({editMode: true, dashboardName: "", dashboardSel: "", gridSize: 50, gridWidth: 6, gridHeight: 6})
        break
      default:
        break
    }
  }
  
  colorSquare=(col, size)=>{
    return(
      <div style={{width: size, height: size, marginLeft: 10, display: "inline-block", verticalAlign: "middle", backgroundColor: col}}/>
    )
  }
  
  editDashboard=()=>{
    return(
      <div>
      <UsaIcon icon="dashboard-OK" result={this.result} inline/>
      <UsaIcon icon="dashboard-Cancel" result={this.result} inline/>
      <UsaSelect00 parms={{
        title: "Dashboard\u00A0Name",
        inline: true,
        opts: this.dashboardOpts,
        value: this.state.dashboardName,
        valueId: "dashboardName",
        onChange: o=>this.setState(o),
      }}/>
      <div style={{height: 10}}></div>
      <table><tbody>
      <tr>
      <td>
        <UsaCheckbox00 parms={{
          title: "Dynamic",
          inline: true,
          value: this.state.dynamic || false,
          valueId: "dynamic",
          onChange: o=>this.setState(o),
        }}/>
      </td>
      <td>
        <UsaCheckbox00 parms={{
          title: "Proportional",
          inline: true,
          value: this.state.proportional || false,
          valueId: "proportional",
          onChange: o=>this.setState(o),
        }}/>
      </td>
      </tr>
      </tbody></table>
      <div style={{height: 10}}></div>
      <UsaSelect00 parms={{
        title: "Grid\u00A0Size",
        width: 100,
        inline: true,
        value: this.state.gridSize,
        valueId: "gridSize",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Grid\u00A0Width",
        width: 100,
        inline: true,
        value: this.state.gridWidth,
        valueId: "gridWidth",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Grid\u00A0Height",
        width: 100,
        inline: true,
        value: this.state.gridHeight,
        valueId: "gridHeight",
        onChange: o=>this.setState(o),
      }}/>
      <div style={{height: 10}}></div>
      <UsaCheckbox00 parms={{
        title: "Background\u00A0Image",
        inline: true,
        value: this.state.backImage || false,
        valueId: "backImage",
        onChange: o=>this.setState(o),
      }}/>
      <div style={{height: 10}}></div>
        {!this.state.backImage&&
        <table><tbody>
        <tr>
        <td>
          <UsaSelect00 parms={{
            title: "Background\u00A0Color",
            width: 100,
            inline: true,
            value: this.state.bgColor || "",
            valueId: "bgColor",
            onChange: o=>this.setState(o),
          }}/>
        </td>
        <td>
          {this.colorSquare(this.state.bgColor, 50)}
        </td>
        </tr>
        </tbody></table>
      }
      {this.state.backImage&&
        <>
        <IconButton
          variant="contained"
          component="label"
          title="Upload Image"
        >
          <Camera/>
          <input hidden type="file" onChange={this.markImage}/>
        </IconButton>
        <span>Upload Image</span>
        </>
      }
      
      <div style={{height: 10}}></div>
      <UsaSelect00 parms={{
        title: "Default\u00A0Zone",
        width: 100,
        inline: true,
        value: this.state.defZone,
        valueId: "defZone",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Default\u00A0Channel",
        width: 100,
        inline: true,
        value: this.state.defChannel,
        valueId: "defChannel",
        onChange: o=>this.setState(o),
      }}/>
      <UsaSelect00 parms={{
        title: "Default\u00A0Index",
        width: 100,
        inline: true,
        value: this.state.defIndex,
        valueId: "defIndex",
        onChange: o=>this.setState(o),
      }}/>
      <div style={{height: 10}}/>
      <div>
        <div>Copy Dashboard Tree To Account:</div>
        <button type="button" onClick={()=>this.copyDeleteDashboardTree(false)}>Copy</button>
        <input
          type="text"
          value={this.state.dashboardCopy || ""}
          onChange={e=>this.setState({dashboardCopy: e.currentTarget.value})}
        />
        <div>
          <button type="button" onClick={this.deleteDashboardTree}>Delete Dashboard Tree</button>
        </div>
        <div>
          <button type="button" onClick={this.setAsHome}>Set As Home Dashboard</button>
        </div>
      </div>
      <div style={{height: 10}}/>
      <div>Dashboard ID: {this.state.dashboardSel}</div>
      </div>
    )
  }
  
//   setStateValue=(vals)=>{
//     cl(vals)
//     Object.keys(vals).forEach(v=>{
//       cl(v)
//     })
//     
//   }
  setSelectDB=(v)=>{
//     cl("state")
    this.setState(v)
    this.props.onChange(v)
    globs.events.publish("dashboardUpdated", {dashboard: v.dashboardSel})
    history.push(`/usa/dash/${v.dashboardSel}`)
//     cl(v)
//     globs.events.publish("newDashboardSelected", {dashboard: v.dashboardSel})
  }
  
  displayDashboard=()=>{
//     cl(this.state)
//     cl(this.state.dashboardSel)
    return(
      <div>
      <UsaIcon icon="dashboard-New" result={this.result} inline/>
      <UsaIcon icon="dashboard-Edit" result={this.result} inline/>
      <UsaIcon icon="dashboard-Delete" result={this.result} inline/>
      <UsaSelect00 parms={{
        title: "Select\u00A0Dashboard",
        select: true,
        inline: true,
        opts: this.dashboardOpts,
        value: this.state.dashboardSel,
        valueId: "dashboardSel",
        textId: "dashboardName",
        onChange: o=>this.setSelectDB(o),
      }}/>
      </div>
    )
  }
  

  render(){
    if(this.state.editMode){
      return this.editDashboard()
    }else{
      return this.displayDashboard()
    }
  }
}

export default Dashboard00 ;

