import React from 'react';
import C18Button00 from './C18Button00'
import C18Anchor00 from './C18Anchor00'
import config from '../../components/utils/config'
import {uploadTotal} from '../../components/utils/ws'
import {acctFeature} from './C18utils'
import {cl} from '../../components/utils/utils';

class C18DateFormat00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      mode:"sites",
      value: (props.parms.value)?"grid-select":"list-select",
    }
    this.showTimeZone=''
  }
  
  listTileClick=(mode)=>{
    var value
    let vals={}
    switch(mode){
      case "list-button":
      case "grid-button":
        value=mode.split("-")[0]+"-select"
        break
      case "phys-button":
        value=this.state.value// don't chnage tile / list mode
        vals.cmd=mode
        break
      default:
        break
    }
    this.setState({value:value})
    vals[this.props.parms.valueId]=value
//     cl(vals)
    this.props.parms.onChange(vals)
  }
  
  wrenchClick=(val,e)=>{
    e.preventDefault()
    this.props.parms.onChange(val)
  }
  
  showListTile=()=>{
//     cl(this.state)
    var listSelect="", gridSelect="", physSelect=""
//     if(this.state.value){// value is "tileMode"
//       gridSelect=" selected"
//     }else{
//       listSelect=" selected"
//     }
    let selected=" selected"
    if(this.state.value=="list-select"){listSelect=selected}
    if(this.state.value=="grid-select"){gridSelect=selected}
    if(this.state.value=="phys-select"){physSelect=selected}
    
    return(
      <div className="section-controls">
        <C18Button00 type="button" className={`grid-button material-icons${gridSelect}`} aria-label="grid" 
          aria-pressed="true" onClick={()=>{this.listTileClick("grid-button")}}>apps</C18Button00>
        <C18Button00 type="button" className={`list-button material-icons${listSelect}`} aria-pressed="false" 
          onClick={()=>{this.listTileClick("list-button")}}>view_list</C18Button00>
        {acctFeature("physView")&&
          <C18Button00 type="button" className={`phys-button material-icons${physSelect}`} aria-pressed="false" 
            onClick={()=>{this.listTileClick("phys-button")}}>landscape</C18Button00>
        }
      </div>
    )
  }
  
  showWrench=()=>{
    return(
      <div id="/zone/zoneEquipment/equipmentSettings" className="section-controls">
        <C18Anchor00 id="/zone/zoneSensors/stages" to="" className="quick-charts" aria-label="quick-charts"
          onClick={e=>this.wrenchClick({wrenchCmd:"stages"},e)}>
          <span className="material-icons-outlined">
            show_chart
          </span>
          <span className="quick-charts">Stages</span>
        </C18Anchor00>
        <C18Anchor00 to="" className="settings-button material-icons-outlined flip" aria-label={this.props.parms.ariaLabel} 
          onClick={e=>this.wrenchClick({wrenchCmd:"wrench"},e)}>settings</C18Anchor00>
      </div>
    )
  }
  
  getUploadColor=()=>{
    var uploadColor
    if(uploadTotal>15){
      if(uploadTotal>30){
        uploadColor="#FF8888"
      }else{
        uploadColor="#FFCC88"
      }
    }else{
      if(uploadTotal>3){
        uploadColor="#FFFF88"
      }else{
        uploadColor="#88FF88"
      }
    }
    return uploadColor
  }
  
  showQuickWrench=()=>{
    return(
      <div className="section-controls">
        <C18Anchor00 id="/zone/zoneSensors/quickCharts" to="" className="quick-charts" aria-label="quick-charts"
          onClick={e=>this.wrenchClick({wrenchCmd:"charts"},e)}>
          <span className="material-icons-outlined">
            signal_cellular_alt
          </span>
          <span className="quick-charts">Quick Charts</span>
        </C18Anchor00>
        <C18Anchor00 id="/zone/zoneSensors/sensorSettings" to="" className="settings-button material-icons-outlined flip" aria-label={this.props.parms.ariaLabel} 
          onClick={e=>this.wrenchClick({wrenchCmd:"wrench"},e)}>settings</C18Anchor00>
        {this.props.parms.gatewayType==1900&&// only on Pearl
          <>
            <C18Anchor00 to="" className="settings-button material-icons-outlined flip" 
              aria-label={this.props.parms.ariaLabel} 
              title="Force full upload of this Zone"
              style={{backgroundColor:this.getUploadColor()}}
              onClick={e=>this.props.parms.onChange({e:e,wrenchCmd:"upload"},e)}>
              upload</C18Anchor00>
            <C18Anchor00 to="" className="settings-button material-icons-outlined flip" 
              aria-label={this.props.parms.ariaLabel} 
              title="Delete all zone data"
              style={{backgroundColor:"#FFCCCC"}}
              onClick={e=>this.props.parms.onChange({e:e,wrenchCmd:"eraseDB"},e)}>
              delete</C18Anchor00>
          </>
        }
      </div>
    )
  }
  
  showRightElement=()=>{
//     cl(this.props.parms)
    switch(this.props.parms.type){
      case "listTile":
        return this.showListTile()
      case "quickWrench":
          return this.showQuickWrench()
      case "wrench":
        return this.showWrench()
      default:
        return null
    }
  }
  
  showDateFormat=()=>{
//     cl(this.props.parms)

   if (this.props.parms.timeZone) {
    this.showTimeZone = <div style={{
      float: "left",
      lineHeight: "40px",
      fontSize: "16px",
      fontWeight: "500",
      paddingLeft: "10px"
    }}>({this.props.parms.timeZone})</div>
   }

    return(
      <div className="section-header">
        <div className={this.props.parms.leftClass}>{this.props.parms.leftString}
            <span className="material-icons-outlined not-connected-icon" title="Zone is Offline">report_problem</span>
        </div>
        {this.showTimeZone}
        {this.showRightElement()}
      </div>
    )
  }
  
  
  render(){
    return this.showDateFormat()
  }
}
      
export default C18DateFormat00;
