import React from 'react';
import UsaIcon from '../UsaIcon';
import UsaSelect00 from '../UsaSelect00'
import DateTime00 from '../DateTime00'
import UsaDuration00 from '../UsaDuration00'
import C18Duration00 from '../C18Duration00'
import RepeatHours from './RepeatHours'
import RepeatDayHours from './RepeatDayHours'
import RepeatDays from './RepeatDays'
import RepeatWeekDays from './RepeatWeekDays'
import RepeatMonthDays from './RepeatMonthDays'
import RepeatMonthWeek from './RepeatMonthWeek'
import RepeatYearDay from './RepeatYearDay'
import RepeatYearWeek from './RepeatYearWeek'
import RepeatMinutes from './RepeatMinutes'
import {loadZonesInfo,getZoneInfo,loadSitesInfo,loadSiteData,saveTable,getSiteName,getZoneName} from '../C18utils'
import {wsTrans,sVolUnit,bVolUnit} from '../../../usa/utils/utils'
import {cl,globs,az,getTime,constant,getRandomString,dateToDisplayDate,displayDateToDate,secsToHm,days,shortDays,months,shortMonths,weeks} from '../../../components/utils/utils';
import {showSelect, showInput} from '../../../components/utils/reactUtils';
import {init, dbVals} from '../../../components/utils/http';
import {pi,pInd} from '../../../components/utils/paramIds';

const verticalSpace=<div style={{height: 10}}/>

class Schedules extends React.Component{
  constructor(props) {// 
    super(props);
    this.state={
      value: "",
      selectedTab: "Schedules",
//       gatewayId:getZoneInfo(props.parms.zone).gatewayId,
      loaded: false,
      scheduleSel: "a",
      scheduleOpts:{
        a:{t:"Schedule A", 
          name: "Schedule A",
          tankAddr: 193,
          recipeId: "7XgxD5PYUyHB$99m",
          baseTime: getTime(),
          repeatPattern: "hours",
        },
        b:{t:"Schedule B"},
      },
      editMode: false,
      name: "",
      tankAddr: 0,
      recipeId: 0,
      baseTime: 0,
      repeatPattern: 0,
      repeat: {
        period: 0,
        details: 0,
      },
//       scheduleAreas:[
//         {areaId: "wqCFh4QdYoIV7cvb", volume: 100}
//       ],
      scheduleAreaSel: -1,//the array index of the this.state.scheduleAreas entry
      scheduleAreaId: 0,
      scheduleAreaVolume: 0,
      mixTime: 45,
//       areas: [],
      tankOpts:{0: {t: "Tank One"}, 1: {t: "Tank Two"}}
    }
    this.repeatOpts={
      none: {t: "Does Not Repeat"},// minutes, from, to
      minutes: {t: "Every So Many Minutes"},// minutes, from, to
      hours: {t: "Every So Many Hours"},// hours
      dayhours: {t: "These Hours of the Day"},// days, hour flags
      days: {t: "Every So Many Days"},// days
      weekdays: {t: "These Days of the Week"},// weeks, day flags
      monthdays: {t: "These Days of the Month"},// months, month day flags
      monthweek: {t: "This Week and Day of the Month"},// months, day, week
      yearday: {t: "This Day of the Year"},// years, day of month, month
      yearweek: {t: "This Month, Week and Day of the Year"},// years, day, week, month
    }
    this.loadData()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.savePage)
    if(this.props.parms.saveOK){this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})}
//     this.props.parms.onChange({cmd:"savePage", data:{savePage:true}})
    this.setBreadCrumbs()
  }
  
  componentWillUnmount=()=>{
    this.subscribe_savePageEvent.remove()
  }
  
  setBreadCrumbs=()=>{
    let p=this.props.parms
    let siteName=getSiteName(p.site)
    let zoneName=getZoneName(p.zone)
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`},
              {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
              {t:"iDoser", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/idoser/nutrients`},
              {t:"Schedules", url:`/usa/c18/sites/${p.site}/zones/${p.zone}/idoser/schedules`},
            ]},
        },
      )
    }
  }
  
  savePage=(cmd)=>{
    if(cmd=="save"){
      this.saveSchedule()
    }
  }
  
  saveScheduleAreas=async(scheduleId)=>{
    let s=this.state
    let siteId=globs.userData.session.siteId
    let areas=[]
    s.scheduleOpts[scheduleId].scheduleAreas.forEach(a=>{
      areas.push({
        siteId: siteId,
        scheduleId: scheduleId,
        gatewayId:this.gatewayId,
        areaId: a.areaId,
        volume: a.volume,
      })
      cl(a)
    })
    cl(areas)
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/scheduleAreas", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {scheduleId: scheduleId, areas: areas}})
  }
  
  saveSchedule=async()=>{
    let schedule=Object.assign({},this.state.scheduleOpts[this.state.scheduleSel],{gatewayId:this.gatewayId,tankAddr:this.state.tankAddr})
    cl(schedule)
    delete schedule["_id"]
    delete schedule.t
    delete schedule.scheduleAreas
    await wsTrans("usa", {cmd: "cRest", uri: "/s/schedules", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: schedule})
    saveTable("schedules",this.gatewayId)
    globs.events.publish("saveOK",true)
//     let sa=Object.assign({},this.state.scheduleAreas[this.state.scheduleSel])
//     let sa=(this.state.scheduleAreas[this.state.scheduleSel]||[]).slice(0)
//     wsTrans("usa", {cmd: "cRest", uri: "/s/scheduleAreas", method: "update", 
//       sessionId: globs.userData.session.sessionId,
//       body: {siteId:globs.userData.session.siteId,scheduleId:this.state.scheduleSel,areas:sa}})
    
// { "_id" : ObjectId("610ff379d536b60c20fa5c41"), 
//   "siteId" : "0sna8jVYIh0xw6oF", 
//   "scheduleId" : "pXEpMwJPXHHQHVD@", 
//   "areaId" : "D4BsbdRx$uip1hmH", 
//   "volume" : "3", 
//   "accountId" : "a036uzDCxohZ7ovD" }
    
  }
  
  saveScheduleO=async()=>{
    let s=this.state
    let schedules=Object.assign(this.state.scheduleOpts)
    schedules[s.scheduleSel]={
      scheduleId: s.scheduleSel,
      name: s.name,
      t:s.name,
      tankAddr: s.tankAddr,
      recipeId: s.recipeId,
      baseTime: s.baseTime,
      repeatPattern: s.repeatPattern,
      repeat: s.repeat,
      scheduleAreas: s.scheduleAreas,
      mixtime: s.mixTime
      
    }
    this.setState({scheduleOpts: schedules, editMode: false})
    let r=await wsTrans("usa", {cmd: "cRest", uri: "/s/schedules", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: schedules[s.scheduleSel]})
    this.saveScheduleAreas(s.scheduleSel)
  }
  
  getAreas=(tankAddr)=>{
    this.areaOpts={}
    let areas=this.areas[tankAddr]
    for(let k in areas){this.areaOpts[areas[k].areaId]={t:areas[k].name}}
    cl(this.areaOpts)
    cl(tankAddr)
    cl(this.areas)
  }
  
  getASchedule=(scheduleId)=>{// just use defaults, for now, will be stored in scheduleOpts
    let sched=this.state.scheduleOpts[this.state.scheduleSel]
    this.getAreas(sched.tankAddr)
    this.setState({
      name: sched.name, 
      tankAddr: sched.tankAddr, 
      recipeId: sched.recipeId,
      baseTime: sched.baseTime,
      repeatPattern: sched.repeatPattern,
      repeat:{
        period: 1,
        details: 3,
      },
//       scheduleAreas: sched.scheduleAreas,
      editMode: true,
    })
  }
  
  newSchedule=()=>{
    let tankAddr=Object.keys(this.state.tankOpts)[0]
    this.getAreas(tankAddr)
    this.setState({
      scheduleSel: getRandomString(16),
      name: "", 
      tankAddr: tankAddr, 
      recipeId: Object.keys(this.recipeOpts)[0],
      baseTime: Math.floor(getTime()),
      repeatPattern: "hours",
      repeat:{
        period: 1,
        details: 0,
      },
      mixTime: 0,
//       scheduleAreas: [],
      editMode: true,
    })
  }
  
  deleteSchedule=async(vals)=>{
/*needs to delete schedules, *and* scheduleAreas
this.state.scheduleAreas[this.state.scheduleSel] is the current areas*/
    let res=await this.props.parms.getPopup({text:"Are you sure you want to delete this Schedule?", buttons:["Cancel","Yes"]})
    if(res=="Yes"){
//       cl(vals)
//       return
      let schedules=Object.assign(this.state.scheduleOpts)
      delete schedules[vals.scheduleId]
      let sa=Object.assign({},this.state.scheduleAreas)
      delete sa[this.state.scheduleSel]
      wsTrans("usa", {cmd: "cRest", uri: "/s/schedules", method: "delete", 
        sessionId: globs.userData.session.sessionId,
        body: {gatewayId:this.gatewayId,scheduleId: +this.state.scheduleSel}})
//       wsTrans("usa", {cmd: "cRest", uri: "/s/scheduleAreas", method: "delete", 
//         sessionId: globs.userData.session.sessionId,
//         body: {scheduleId: this.state.scheduleSel}})
      let scheduleSel=Object.keys(schedules)[0]
      cl(scheduleSel)
      this.setState({scheduleOpts: schedules,scheduleAreas:sa,scheduleSel:scheduleSel})
    }
  }
  
  loadTanks=async()=>{
    await init()
    let chTypeInd=pInd[1800].channels_configuration[0]+pi[1800].channels_configuration["channelType"]
    let chMixingTankInd=pInd[1800].config_channels_configuration[0]+pi[1800].config_channels_configuration["CD_tankSensor_fval"]
    let ecMixingTankNameInd=pInd[1800].config_ecph[0]+pi[1800].config_ecph["name"]
    let tanks=[]
    let tankNames={}
    dbVals.z.forEach((z,zInd)=>{
//       cl(zInd,globs.siteZoneTypes[zInd])
      if(globs.siteZoneTypes[zInd]=="1800"){
        for(let i=0;i<40;i++){
          if(z[i]){
            let addr=i | (zInd<<10)
            if(z[i][chTypeInd]==constant.EQ_FVALVE){
              cl(i)
              cl(chTypeInd)
              let tankAddr=192+(+z[i][chMixingTankInd]) | zInd<<10
              tanks.push({addr: addr, tankAddr: tankAddr})
            }
          }
        }
        for(let i=192;i<199;i++){
          if(z[i]){
            let addr=i | (zInd<<10)
            let name=z[i][ecMixingTankNameInd]
            if(name){tankNames[addr]=name}
          }
        }
      }
    })
    let tankOpts={}
    cl(tanks)
    tanks.forEach(t=>{
      let zId=1+((t.tankAddr >> 10) & 0x03F)
      let tId=1+(t.tankAddr&0xFF)-192
      tankOpts[t.tankAddr]={t:(tankNames[t.tankAddr])?tankNames[t.tankAddr]:`Zone ${zId}, Tank ${tId}`}
    })
    cl(this.state.stations)
//     cl(tankOpts)
    return tankOpts
  }
  
  loadData=async()=>{
    await init()
    await loadSitesInfo()
    await loadZonesInfo()
    this.gatewayId=getZoneInfo(this.props.parms.zone).gatewayId
    let siteId=globs.userData.session.siteId
    let siteDataP=await loadSiteData(siteId)
//     cl(globs)
//     cl(dbVals)
    let reP=wsTrans("usa", {cmd: "cRest", uri: "/s/recipes", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    let arP=wsTrans("usa", {cmd: "cRest", uri: "/s/areas", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    let scP=wsTrans("usa", {cmd: "cRest", uri: "/s/schedules", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    let saP=wsTrans("usa", {cmd: "cRest", uri: "/s/scheduleAreas", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    let stP=wsTrans("usa", {cmd: "cRest", uri: "/s/stations", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {gatewayId:this.gatewayId}})
    let [reR,arR,scR,saR,stR]=await Promise.all([reP,arP,scP,saP,stP])
//     cl(scR)
    let scheduleAreas={}
//     cl(saR.data)
    saR.data.forEach(a=>{
      if(!scheduleAreas[a.scheduleId]){scheduleAreas[a.scheduleId]=[]}
      scheduleAreas[a.scheduleId].push({
        areaId:a.areaId,
        volume: a.volume,
      })
    })
//     saR.data.forEach(a=>{
//       if(!scheduleAreas[a.scheduleId]){scheduleAreas[a.scheduleId]=[]}
//       scheduleAreas[a.scheduleId].push({
//         areaId:a.areaId,
//         volume: a.volume,
//       })
//     })
    let scheduleOpts={}
//     cl(scR)
    this.schedules=scR.data
    let scheduleSel=-1
    let tankOpts=await this.loadTanks()
//     cl(tankOpts)
    let tankAddr=Object.keys(tankOpts)[0]
    let stations={}
    stR.data.forEach(st=>{
//       cl(st)
      stations[0x10000 | st.stationId | (st.fvAddr<<10)]=st
    })
    if(!tankAddr){
//       cl(stations)
      tankAddr=Object.keys(stations)[0]
    }
//     cl(tankAddr)
    this.schedules.forEach(s=>{
//       cl(s)
      if(+s.tankAddr<0x10000){
        s.tankAddr=192+s.tankAddr
      }
//       cl([s.tankAddr,tankAddr])
      if(s.tankAddr==tankAddr){
        if(scheduleSel<0){scheduleSel=s.scheduleId}
        scheduleOpts[s.scheduleId]=s
      }
    })//Object.assign(s,scheduleAreas[s.scheduleId])})
//     cl(scheduleSel)
    let areas={}
//     cl(arR.data)
    this.areas=arR.data
    this.areas.forEach(a=>{
      if(!areas[a.tankId]){areas[a.tankId]={}}
      areas/*[a.tankId]*/[a.areaId]=a
    })
//     cl(arR)
    let recipeOpts={}
    this.recipes=reR.data
    this.recipes.forEach(r=>{
      recipeOpts[r.recipeId]={t: r.name}
    })
//     cl(tankOpts)
    
    
//     cl(stations)
    this.setState({loaded: true, tankOpts: tankOpts, tankAddr: tankAddr, scheduleOpts: scheduleOpts, 
      scheduleAreas: scheduleAreas, scheduleSel: scheduleSel, recipeOpts: recipeOpts, areaOpts:areas,
      stations:stations,
    })
//     cl(this.recipeOpts)
//     cl(this.areas)
    
//     cl(reR.data)

  }
  
  areaResult=(type)=>{
//     cl(this.state)
    let [type2,cmd]=type.split("-")
    let id=+type2.substr(4)
//     cl([type2,cmd])
    var areas, scheduleAreaId, scheduleAreaVolume
    switch(cmd){
      case "Edit":
        let area=this.state.scheduleAreas[id]
        scheduleAreaId=area.areaId
        scheduleAreaVolume=area.volume
        this.setState({scheduleAreaSel: id, scheduleAreaId: scheduleAreaId, scheduleAreaVolume: scheduleAreaVolume})
        break
      case "Add":
        cl(this.areaOpts)
        areas=this.state.scheduleAreas.slice(0)
        scheduleAreaId=Object.keys(this.areaOpts)[0]
        scheduleAreaVolume=0
        areas.splice(id,0,{
          areaId: scheduleAreaId,
          volume: scheduleAreaVolume,
        })
        this.setState({scheduleAreaSel: id, scheduleAreaId: scheduleAreaId, scheduleAreaVolume: scheduleAreaVolume,
          scheduleAreas: areas,
        })
        break
      case "OK":
        areas=this.state.scheduleAreas.slice(0)
        areas[id]={
          areaId: this.state.scheduleAreaId,
          volume: this.state.scheduleAreaVolume,
        }
        this.setState({scheduleAreaSel: -1, scheduleAreas: areas,})
        break
      case "Delete":
        areas=this.state.scheduleAreas.slice(0)
        areas.splice(id,1)
        this.setState({scheduleAreas: areas,})
        break
      default:
        break
    }
  }
  
  
  result=(type)=>{
    if(type.substr(0,4)=="Area"){return this.areaResult(type)}
    cl(type)
    switch(type){
      case "Schedules-OK":
        this.saveSchedule()
        break
      case "Schedules-Cancel":
        this.setState({editMode: false})
        break
      case "Schedules-Edit":
        this.getASchedule(this.state.scheduleSel)
//         this.setState({editMode: true})
        break
      case "Schedules-Add":
        this.newSchedule()
        break
      case "Schedules-Delete":
        this.deleteSchedule()
        break
      default:
        break
    }
  }
  
  showName=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Name",
        width: 400,
        select: false, // !this.state.editMode,
        valueId: "name",//valueId, // "widgetSel",
        value: this.state.name, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showTankSelectO=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Select\u00A0Mixing\u00A0Tank",
        select: true, // !this.state.editMode,
        valueId: "tankAddr",//valueId, // "widgetSel",
        value: this.state.tankAddr, //value, // this.state.widgetSel,
        opts: this.state.tankOpts,
        onChange: x=>this.onChange("tank",x)
    }}/>)
  }
  
  showRecipeSelectO=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Select\u00A0Recipe",
        select: true,
        valueId: "recipeId",
        value: this.state.recipeId, //value, // this.state.widgetSel,
        opts: this.recipeOpts,
        onChange: x=>this.onChange("fields",x)
    }}/>)
  }
  
  showTimePicker=()=>{
    return(
      <DateTime00 key={0} 
        parms={{
          title: "Event Time",
          date: this.state.baseTime,
          valueId: "baseTime",
          id: "begin"}} 
        onChange={o=>this.onChange("fields", o)}
      />)
  }
  
  showRepeatSelectO=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Select\u00A0Repeat\u00A0Pattern",
        select: true,
        valueId: "repeatPattern",
        value: this.state.repeatPattern, //value, // this.state.widgetSel,
        opts: this.repeatOpts,
        onChange: x=>this.onChange("fields",x)
    }}/>)
  }
  
  showRepeatDetails=()=>{
    let details={hours: RepeatHours, dayhours: RepeatDayHours, days: RepeatDays, weekdays: RepeatWeekDays,
      monthdays: RepeatMonthDays, monthweek: RepeatMonthWeek, yearday: RepeatYearDay, yearweek: RepeatYearWeek,
      minutes: RepeatMinutes,
    }
    let Detail=details[this.state.repeatPattern]
    if(Detail){
      return <Detail parms={{
        repeat: this.state.repeat,
        onChange: v=>this.onChange("fields",v),
      }}/>
    }else{
      return null
    }
//     switch(this.state.repeatPattern){
//       case "hours":
//         return <RepeatHours parms={{
//           repeat: this.state.repeat,
//           onChange: v=>this.onChange("fields",v),
//         }}/>
//       case "dayhours":
//     }
  }
  
  showMixTime=()=>{
    return(
      <UsaSelect00 parms={{
        title: "Mix Time",
        mode: "muiDuration00",
        width: 100,
        select: false, // !this.state.editMode,
        valueId: "mixTime",//valueId, // "widgetSel",
        value: this.state.mixTime, //value, // this.state.widgetSel,
        onChange: (vals)=>this.onChange("fields", vals),
      }}/>
    )
  }
  
  showAreaIcons=(i)=>{
    if(this.state.scheduleAreaSel==i){
      return(
        <>
          <td>
            <UsaIcon icon={`Area${i}-OK`} result={this.result} inline/>
          </td>
          <td>
            <UsaIcon icon={`Area${i}-Cancel`} result={this.result} inline/>
          </td>
          <td>
          </td>
        </>
      )
    }else{
      return(
        <>
          <td>
            <UsaIcon icon={`Area${i}-Add`} result={this.result} inline/>
          </td>
          <td>
            <UsaIcon icon={`Area${i}-Edit`} result={this.result} inline/>
          </td>
          <td>
            <UsaIcon icon={`Area${i}-Delete`} result={this.result} inline/>
          </td>
        </>
      )
    }
  }
  
  showAreaVolume=()=>{
    return showInput("scheduleAreaVolume",this.state.scheduleAreaVolume, "scheduleAreaVolume",0,this.onChange2)
  }
  
  showAreaSelection=(nutrientId)=>{
    return showSelect("scheduleAreas", this.state.scheduleAreaId, "scheduleAreaId",0,this.areaOpts, this.onChange2)
  }
  
  showAreaLine=(name, areaId, volume, i)=>{
    if(this.state.scheduleAreaSel==i){
      return <>
      <td>
        {this.showAreaSelection(areaId)}
      </td><td>
        {this.showAreaVolume()}
      </td>
      </>
    }else{
      return(
        <><td>{name}</td><td>{`${volume} Gals`}</td></>
      )
    }
  }
  
  showAreaAdd=()=>{
    let i=this.state.scheduleAreas.length
    return(
      <tr>
      <td>
        <UsaIcon icon={`Area${i}-Add`} result={this.result} inline/>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
    )
  }
  
  showAreas=()=>{
/*need to select a list of mixing areas, and volumes for each
this.state.scheduleAreas is an array of {areaId: volume:}
this.scheduleAreas is read from the db: {scheduleId: {areaId1: volume1, areaId2: volume2}}
scheduleAreas table: accountId, siteId, areaId, volume
the main area info is stored in this.areas, an object: {tankAddr: {areaId: {(area info)}
this.state.areaOpts will have all the areas for this tank
each watering area should have a total volume associated with it*/
//     cl(this.state)
    return(
      <div>
      <h3>Watering Areas</h3>
        <table><tbody>
        {this.state.scheduleAreas.map((a,i)=>{
//           cl(n)
//           cl(this.areas)
//           cl(this.state.tankAddr)
//           cl(this.areas[this.state.tankAddr])
//           cl(a.areaId)
          
          let name=this.areas[this.state.tankAddr][a.areaId].name
          return(
            <tr key={i}>
            {this.showAreaIcons(i)}
            {this.showAreaLine(name, a.areaId, a.volume, i)}
            </tr>
          )
        })}
        {this.showAreaAdd()}
        </tbody></table>
      </div>
    )
  }
  
  showEditFields=()=>{
//         {this.showTankSelect()}
//         {verticalSpace}
    if(this.state.editMode){
      return(
      <div>
        {verticalSpace}
        {this.showName()}
        {verticalSpace}
        {this.showRecipeSelect()}
        {verticalSpace}
        {this.showTimePicker()}
        {verticalSpace}
        {this.showRepeatSelect()}
        {verticalSpace}
        {this.showRepeatDetails()}
        {verticalSpace}
        {this.showMixTime()}
        {verticalSpace}
        {this.showAreas()}
      </div>
      )
    }else{
      return null
    }
  }
  
  setDateTime=(type,vals)=>{
    cl(vals)
    let so=Object.assign({},this.state.scheduleOpts)
    let sched=this.state.scheduleSel
//     cl(so[sched])
//     cl(so[sched].baseTime)
    let da=new Date(1000*so[sched].baseTime)
    switch(type){
      case "date":
        da=displayDateToDate(vals.date,da,"yyyy-mm-dd")
        so[sched].baseTime=Math.floor(da.getTime()/1000)
        break
      case "time":
        let parts=vals.time.split(":")
        let mins=60*parts[0]+(+parts[1])
        cl(mins)
        so[sched].time=60*mins
        
//         da=displayDateToDate(vals.time,da,"hh:mm")
//         cl(da)
        break
    }
//     cl(Math.floor(da.getTime()/1000))
//     Object.assign(so[this.state.scheduleSel],vals)
    this.setState({scheduleOpts:so})
  }
  
  handleRepts=(vals)=>{
    let so=this.state.scheduleOpts
    let repeat=so[this.state.scheduleSel].repeat
    
//     cl(so)
//     cl(vals)
    switch(vals.type){
      case "minsFrom":
        Object.assign(repeat,{details:repeat.details&0xFF00|vals.val})
        break
      case "minsTo":
        Object.assign(repeat,{details:repeat.details&0xFF|(vals.val<<8)})
        break
      case "period":
        Object.assign(repeat,{period:vals.val})
        cl(vals)
        break
      case "dayHour":
        Object.assign(repeat,{details:repeat.details^(0x800000>>vals.hr)})
        break
      case "weekDay":
        Object.assign(repeat,{details:repeat.details^(0x40>>vals.hr)})
        break
      case "monthDay":
        Object.assign(repeat,{details:repeat.details^(0x40000000>>vals.hr)})
        break
      case "monthWeekDay":
        Object.assign(repeat,{details:repeat.details&0xFF00|vals.val})
        break
      case "monthWeekWeek":
        Object.assign(repeat,{details:repeat.details&0xFF|(vals.val<<8)})
        break
      case "yearDayDay":
        Object.assign(repeat,{details:repeat.details&0xFF00|vals.val})
        break
      case "yearDayMonth":
        Object.assign(repeat,{details:repeat.details&0xFF|(vals.val<<8)})
        break
        
      case "yearWeekDay":
        Object.assign(repeat,{details:repeat.details&0xFFE0|(vals.val&0x1F)})
        break
      case "yearWeekWeek":
        cl(vals)
        Object.assign(repeat,{details:repeat.details&0xFC1F|((vals.val&0x1F)<<5)})// 1F << 5=3E invert=C1
        break
      case "yearWeekMonth":
        Object.assign(repeat,{details:repeat.details&0x83FF|((vals.val&0x1F)<<10)})// C1<<5=183
        break
      default:
        break
    }
    this.setState({scheduleOpts:so})
  }
  
  selectTank=(vals)=>{
// need to set scheduleOpts for *this* tank / station
//     cl(vals)
    let scheduleOpts={}
    this.schedules.sort((a,b)=>{
      if(a.time>b.time){return 1}
      if(a.time<b.time){return -1}
      return 0
    })
    let scheduleSel=-1
    this.schedules.forEach(s=>{
      cl(s)
      if(+s.tankAddr<0x10000){
        s.tankAddr=192+s.tankAddr
      }
      if(s.tankAddr==vals.tankAddr){
        if(scheduleSel<0){scheduleSel=s.scheduleId}
        scheduleOpts[s.scheduleId]=s
      }
    })
    cl(scheduleOpts)
    vals.scheduleOpts=scheduleOpts
//     cl(Object.keys(scheduleOpts))
    vals.scheduleSel=scheduleSel//+(Object.keys(scheduleOpts)||[])[0]||0
//     cl(vals)
//     this.setState({scheduleOpts:scheduleOpts})
  }
  
  onChange=(type, vals)=>{
//     cl(type,vals)
//     cl(type)
//     cl(vals)
    let st=this.state
    var sa,so,tank
    switch(type){
      case "fields":
        this.setState(vals)
        break
      case "upd":
        globs.events.publish("savePageEnable",true)
        so=Object.assign({},this.state.scheduleOpts)
        Object.assign(so[this.state.scheduleSel],vals)
        this.setState({scheduleOpts:so})
        break
      case "date":
      case "time":
        globs.events.publish("savePageEnable",true)
        this.setDateTime(type,vals)
        break
      case "tank":
//         cl(type,vals)// for HEA, need to select the event for this tank, too
//         cl(this.state)
        this.selectTank(vals)
//         let scheds=Object.assign({},st.scheduleOpts)
//         cl(scheds)
//         let stationScheds=Object.keys(scheds).filter(k=>{
//           let sched=scheds[k]
//           return sched.tankAddr==vals.tankAddr
//         })
//         cl(stationScheds)
//         vals.scheduleSel=+(stationScheds[0]||0)
//         globs.events.publish("savePageEnable",true)
//         this.getAreas(vals.tankAddr)
//         this.setState(Object.assign({scheduleAreas:[]},vals))
//         cl(this.state.tankAddr)
        this.setState(vals)
//         cl(vals.tankAddr)
//         cl(this.state.tankAddr)
        break
      case "rept":
        globs.events.publish("savePageEnable",true)
        this.handleRepts(vals)
        break
//       case "area":
//         globs.events.publish("savePageEnable",true)
//         sa=Object.assign({},this.state.scheduleAreas)
//         let index=vals.index
//         delete vals.index
//         Object.assign(sa[this.state.scheduleSel][index],vals)
//         this.setState({scheduleAreas:sa})
//         break
      case "area":// on the Schedules page - needs to set in scheduleOpts
        globs.events.publish("savePageEnable",true)
        so=Object.assign({},this.state.scheduleOpts)
        let sched=so[st.scheduleSel]
        Object.assign(sched,{areaId:+vals.areaId})
        this.setState({scheduleOpts:so})
        break
      case "addArea":
        globs.events.publish("savePageEnable",true)
        sa=Object.assign({},this.state.scheduleAreas)
        tank=this.state.scheduleOpts[this.state.scheduleSel].tankAddr
        let area=Object.keys(this.state.areaOpts[tank])[0]
        if(!sa[this.state.scheduleSel]){sa[this.state.scheduleSel]=[]}
        sa[this.state.scheduleSel].push({
          areaId:area,
          volume:0,
        })
        cl(sa)
        this.setState({scheduleAreas:sa})
        break
      case "deleteArea":
        globs.events.publish("savePageEnable",true)
        sa=Object.assign({},this.state.scheduleAreas)
        sa[this.state.scheduleSel].splice(vals.index,1)
        this.setState({scheduleAreas:sa})
        break
      case "addSchedule":
        globs.events.publish("savePageEnable",true)
//         so=this.state.scheduleOpts
        
        so=Object.assign({},this.state.scheduleOpts)
        let max=1
        Object.keys(so).forEach(k=>{
          if(!isNaN(k)){
            cl(k)
            if(max<+k){max=+k}
          }
        })
        
        let scheduleSel=+max+1//getRandomString(16)
        
        let recipe=0
        tank=Object.keys(this.state.tankOpts)[0]
        so[scheduleSel]={
          baseTime:getTime(),
          mixtime:0,
          name:scheduleSel,
          recipeId:recipe,
          repeat:{period:0,details:0},
          repeatPattern:"none",
          amount:0,
          areaId:0,
          time:0,
//           scheduleAreas:[],
          scheduleId:scheduleSel,
          siteId:globs.userData.session.siteId,
          tankAddr:this.state.tankAddr,
        }
        this.setState({scheduleOpts:so,scheduleSel:scheduleSel})
        break
      case "deleteSchedule":
        this.deleteSchedule(vals)
        break
      default:
        break
    }
  }
  
  onChange2=(type,valId,ind,e)=>{
    let val=e.currentTarget.value
    let vals={}
    vals[valId]=val
//     cl(vals)
    this.setState(vals)
  }
  
  showScheduleSelectO=()=>{
//     cl(this.state)
    return(
      <UsaSelect00 parms={{
        title: "Select\u00A0Schedule",
        select: true, // !this.state.editMode,
        inline: true,
        valueId: "scheduleSel",//valueId, // "widgetSel",
        value: this.state.scheduleSel, //value, // this.state.widgetSel,
//         textId: "widgetName",
        opts: this.state.scheduleOpts,
        onChange: x=>this.onChange("fields",x)
      }}
      />
      
    )
  }
  
  showMainIcons=()=>{
    if(this.state.editMode){
      return([
        <UsaIcon key={0} space="0.5" icon="Schedules-OK" result={this.result} inline/>,
        <UsaIcon key={1} icon="Schedules-Cancel" result={this.result} inline/>,
      ])
    }else{
      return([
        <UsaIcon key={0} space="0.5" icon="Schedules-Add" result={this.result} inline/>,
        <UsaIcon key={1} icon="Schedules-Edit" result={this.result} inline/>,
        <UsaIcon key={2} icon="Schedules-Delete" result={this.result} inline/>,
      ])
    }
  }
  
//   renderO(){
// //     cl(this.state)
//     if(this.state.loaded){
//       return(
//         <div style={{padding: 10}}>
//         <h3>Schedules
//         {this.showMainIcons()}
//         </h3>
//         {this.showScheduleSelect()}
//         {this.showEditFields()}
//
//         </div>
//       )
//     }else{
//       return <div>loading...</div>
//     }
//   }
  
  showScheduleSelect=(title)=>{
    let so=this.state.scheduleOpts
    let keys=Object.keys(so)
    keys.sort((a,b)=>{
      if(so[a].time>so[b].time){return 1}
      if(so[a].time<so[b].time){return -1}
      return 0
    })
    let lines=keys.map(k=>{
      let sch=so[k]
//       cl(sch)
      let rec=(this.recipes.filter(r=>{return r.recipeId==+sch.recipeId})[0])||
        {name:"No Name",color:"#FFFFFF"}
      let area=(this.areas.filter(a=>{return a.areaId==+sch.areaId})[0])||
      {name:"No Name",color:"#FFFFFF"}
//       cl(area)
      return(
        <tr key={k} style={{cursor:"pointer",
          backgroundColor:(this.state.scheduleSel==k)?"#DDDDDD":"#FFFFFF"
        }}
          onClick={e=>this.onChange("fields",{scheduleSel:k})}
        >
          <td width="150">{secsToHm(sch.time)}</td>
          <td width="30"><div style={{width:30,height:30,backgroundColor:rec?.color}}/></td>
          <td width="150">{rec.name}</td>
          <td width="50">{(sch.amount/constant.LITERS_PER_GALLON)||0}</td>
          <td width="30"><div style={{width:30,height:30,backgroundColor:area.color}}/></td>
          <td width="150">{area.name}</td>
          <td width="100">0:00</td>
          <td>
            <button type="button" className="material-icons trash after-selector" aria-label="delete nutrient"
            disabled={!this.props.parms.saveOK}
            onClick={e=>this.onChange("deleteSchedule",{scheduleId:k})}>
              delete_outline
            </button>
          </td>
          </tr>
      )
    })
//         <select id="idoser-schedule"
//           value={this.state.scheduleSel}
//           onChange={e=>this.onChange("fields",{scheduleSel:e.currentTarget.value})}
//         >
//         {Object.keys(so).map((scheduleId,i)=>{
//           if(so[scheduleId].tankAddr==this.state.tankAddr){
//             let time=secsToHm(so[scheduleId].time)
//             return(
//               <option key={i} value={scheduleId}>{time/*so[scheduleId].name*/}</option>
//             )
//           }
//           
//         })}
//         </select>
//         <span className="material-icons down-arrow">
//           keyboard_arrow_down
//         </span>
    return(
      <div>
        <label htmlFor="idoser-schedule">{title}</label>
        <table width="100%"><tbody>
        <tr>
        <th>Time</th>
        <th colSpan="2">Recipe</th>
        <th>Gals</th>
        <th colSpan="2">Watering Area</th>
        <th>Mix Time</th>
        <th/>
        </tr>
        {lines}
        </tbody></table>
        
      </div>
    )
  }
  
  showScheduleSelectO=(title)=>{
//     cl(this.state)
    let so=this.state.scheduleOpts
//     cl(so)
    return(
      <span className="custom-select">
        <label htmlFor="idoser-schedule">{title}</label>
        <select id="idoser-schedule"
          value={this.state.scheduleSel}
          onChange={e=>this.onChange("fields",{scheduleSel:e.currentTarget.value})}
        >
        {Object.keys(so).map((scheduleId,i)=>{
//           cl(so[scheduleId])
          if(so[scheduleId].tankAddr==this.state.tankAddr){
            let time=secsToHm(so[scheduleId].time)
            return(
              <option key={i} value={scheduleId}>{time/*so[scheduleId].name*/}</option>
            )
          }
          
        })}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }
  
  showTankSelect=(title,so)=>{
//     cl(title,so)
    let to=this.state.tankOpts
    cl(to)
    let opts=[]
    Object.keys(to).forEach((tankId,i)=>{
//       cl(tankId)
      opts.push(
        <option key={i} value={tankId}>{to[tankId].t}</option>
      )
    })
    let sto=this.state.stations
    Object.keys(sto).forEach((stId,i)=>{
      opts.push(
        <option key={"st"+i} value={stId}>{sto[stId].name}</option>
      )
    })

    return(
      <span className="custom-select">
        <label htmlFor="idoser-tank">{title}</label>
        <select id="idoser-tank"
        value={this.state.tankAddr}
        disabled={!this.props.parms.saveOK}
        onChange={e=>this.onChange("tank",{tankAddr:e.currentTarget.value})}
        >
        {opts}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }
  
  showRecipeSelect=(so)=>{
    let ro=this.state.recipeOpts
    if(!so?.recipeId){
      so.recipeId=Object.keys(ro)[0]
      cl(so)
    }
    return(
      <span className="custom-select">
        <label htmlFor="idoser-recipe">Recipe</label>
        <select id="idoser-recipe"
          value={so?.recipeId}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("upd",{recipeId:e.currentTarget.value})}
        >
        {Object.keys(ro).map((recipeId,i)=>{
          return(
            <option key={i} value={recipeId}>{ro[recipeId].t}</option>
          )
        })}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }
  
  showEventTime=(so)=>{
//     cl(so)
    // 2021-08-18, 06:10
//     yyyy-mm-dd, hh-mn
//     let da=new Date(1000*so?.baseTime)
//     cl(da)
//     cl(dateToDisplayDate(da,"yyyy-mm-dd"))
//       <div className="floatleft">
//         <label htmlFor="idoser-date">Event Date</label>
//         <input id="idoser-date" type="date"
//           value={dateToDisplayDate(da,"yyyy-mm-dd")}
//           disabled={!this.props.parms.saveOK}
//           onChange={e=>this.onChange("date",{date:e.currentTarget.value})}
//         />
//       </div>
// 
    let hr=Math.floor(so.time/3600)
    let mn=Math.floor(so.time/60)%60
    let timeStr=`${az(hr,2)}:${az(mn,2)}:00`
//     cl(timeStr)

//           value={dateToDisplayDate(da,"hh:mm")}
    return(
      <div>
      <div className="floatleft">
        <label htmlFor="idoser-time">Time</label>
        <input id="idoser-time" type="time" 
          value={timeStr}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("time",{time:e.currentTarget.value})}
        />
      </div>
      </div>
    )
  }
  
  showRepeatSelect=(so)=>{
    let ro=this.repeatOpts
//     cl(so)
    return(
      <span className="custom-select">
        <label htmlFor="idoser-repeat">Repeat Pattern</label>
        <select id="idoser-repeat"
          value={so?.repeatPattern}
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("upd",{repeatPattern:e.currentTarget.value})}
        >
        {Object.keys(ro).map((repeatId,i)=>{
          return(
            <option key={i} value={repeatId}>{ro[repeatId].t}</option>
          )
        })}
        </select>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
      
    )
  }
  
  repeatHours=(so)=>{
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1"
          value={so.repeat.period} 
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Hours</span>
      </div>
    )
  }
  
  repeatDays=(so)=>{
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1"
          value={so.repeat.period} 
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Days</span>
      </div>
    )
  }
  
  repeatMinutes=(so)=>{
//     cl(so)
    let da=new Date()
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1" 
        value={so.repeat.period} 
        disabled={!this.props.parms.saveOK}
        onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Minutes</span>
              <div className="clearfloat"></div>
              <br />

              <span className="custom-select">
                <label htmlFor="idoser-between">Between</label>
                <select id="idoser-between"
                  value={so.repeat.details & 0x0FF}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("rept",{type:"minsFrom",val:e.currentTarget.value})}
                >
                {[...Array(24).keys()].map(v=>{
                  da.setHours(v)
                  return<option key={v} value={v}>{dateToDisplayDate(da,"hh ap")}</option>
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <span className="custom-select">
                <label htmlFor="idoser-between">And</label>
                <select id="idoser-between"
                  value={so.repeat.details>>8}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("rept",{type:"minsTo",val:e.currentTarget.value})}
                >
                {[...Array(24).keys()].map(v=>{
                  da.setHours(v)
                  return<option key={v} value={v}>{dateToDisplayDate(da,"hh ap")}</option>
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

      </div>
    )
    return null
  }
  
  repeatDayHours=(so)=>{
    let da=new Date()
    let details=so.repeat.details
//         <button type="button" className="select-all">Select All</button>
//         <button type="button" className="select-all">Unselect All</button> 
    return(
      <div>
        <div className="form-title">On These Hours:</div>
        <div className="toggle-field">
          {[...Array(24).keys()].map(v=>{
            da.setHours(v)
            let sel=details&0x800000
            details=details<<1
            return(
              <button key={v} type="button" className={`toggle${(sel)?" selected":""}`} 
                aria-pressed={(sel)?"true":"false"}
                disabled={!this.props.parms.saveOK}
                onClick={e=>this.onChange("rept",{type:"dayHour",hr:v})}
                >{dateToDisplayDate(da,"hh ap")}</button> 
            )
          })}
        </div>
      </div>
    )
  }
  
  repeatWeekDays=(so)=>{
//     let days=["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
    let da=new Date()
    let details=so.repeat.details
//         <button type="button" className="select-all">Select All</button>
//         <button type="button" className="select-all">Unselect All</button> 
    return(
      <div>
        <div className="form-title">On These Days:</div>
        <div className="toggle-field days">
          {[...Array(7).keys()].map(v=>{
            da.setHours(v)
            let sel=details&0x40
            details=details<<1
            return(
              <button key={v} type="button" className={`toggle${(sel)?" selected":""}`} 
                aria-pressed={(sel)?"true":"false"}
                disabled={!this.props.parms.saveOK}
                onClick={e=>this.onChange("rept",{type:"weekDay",hr:v})}
                >{shortDays[v]}</button> 
            )
          })}
        </div>
      </div>
    )
  }
  
  repeatMonthDays=(so)=>{
    let days=["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
    let da=new Date()
    let details=so.repeat.details
    return(
      <div>
        <div className="form-title">On These Days:</div>
        <div className="toggle-field days">
          {[...Array(31).keys()].map(v=>{
            da.setHours(v)
            let sel=details&0x40000000
            details=details<<1
            return(
              <button key={v} type="button" className={`toggle${(sel)?" selected":""}`} 
                aria-pressed={(sel)?"true":"false"}
                disabled={!this.props.parms.saveOK}
                onClick={e=>this.onChange("rept",{type:"monthDay",hr:v})}
                >{v+1}</button> 
            )
          })}
        </div>
      </div>
    )
  }
  
  repeatMonthWeek=(so)=>{
//     let days=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
//     let weeks=["First","Second","Third","Fourth","Last"]
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1"
          value={so.repeat.period} 
          onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Months</span>
              <div className="clearfloat"></div>
              <br />
              <span className="custom-select">
                <label htmlFor="idoser-day-of-week">On</label>
                <select id="idoser-day-of-week"
                  value={so.repeat.details&0xFF}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("rept",{type:"monthWeekDay",val:e.currentTarget.value})}
                >
                {days.map((d,i)=>{
                  return(
                    <option key={i} value={i}>{d}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <span className="custom-select">
                <label htmlFor="idoser-ordinal-week">Of the</label>
                <select id="idoser-ordinal-week"
                  value={so.repeat.details>>8}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("rept",{type:"monthWeekWeek",val:e.currentTarget.value})}
                >
                {weeks.map((w,i)=>{
                  return(
                    <option key={i} value={i}>{w}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span><span className="units">Week of the Month</span>
      </div>

      
    )
    
  }
  
  makeOrd=(n)=>{
    if((n<10)||(n>20)){
      if((n%10==1)&&(n!=11)){return "st"}
      if(n%10==2){return "nd"}
      if(n%10==3){return "rd"}
    }
    return "th"
  }
  
  repeatYearDay=(so)=>{
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1"
          value={so.repeat.period} 
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Years</span>
              <div className="clearfloat"></div>
              <br />
              <span className="custom-select">
                <label htmlFor="idoser-ordinal">On the</label>
                <select id="idoser-ordinal"
                  value={so.repeat.details&0xFF}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("rept",{type:"yearDayDay",val:e.currentTarget.value})}
                >
                {[...Array(31).keys()].map(v=>{
                  return(
                    <option key={v} value={v+1}>{`${v+1}${this.makeOrd(v+1)}`}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span><span className="units">Day of</span>

              <span className="custom-select">
                <label htmlFor="idoser-month">The Month of</label>
                <select id="idoser-month"
                  value={so.repeat.details>>8}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("rept",{type:"yearDayMonth",val:e.currentTarget.value})}
                >
                {[...Array(12).keys()].map(v=>{
                  return(
                    <option key={v} value={v+1}>{months[v]}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>
      </div>
    )
  }
  
  repeatYearWeek=(so)=>{
    return(
      <div>
        <label htmlFor="idoser-repeat-num">Repeat Every</label>
        <input id="idoser-repeat-num" type="number" step="1"
          value={so.repeat.period} 
          disabled={!this.props.parms.saveOK}
          onChange={e=>this.onChange("rept",{type:"period",val:e.currentTarget.value})}
        /><span className="units">Years</span>
              <div className="clearfloat"></div>
              <br />
              <span className="custom-select">
                <label htmlFor="idoser-day-of-week">On</label>
                <select id="idoser-day-of-week"
                  value={so.repeat.details&0x1F}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("rept",{type:"yearWeekDay",val:e.currentTarget.value})}
                >
                  {days.map((d,i)=>{
                    return(
                      <option key={i} value={i}>{d}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

              <span className="custom-select">
                <label htmlFor="idoser-ordinal-week">Of the</label>
                <select id="idoser-ordinal-week"
                  value={(so.repeat.details>>5)&0x1F}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("rept",{type:"yearWeekWeek",val:e.currentTarget.value})}
                >
                  {weeks.map((w,i)=>{
                    return(
                      <option key={i} value={i}>{w}</option>
                    )
                  })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span><span className="units">Week of</span>

              <span className="custom-select">
                <label htmlFor="idoser-month">The Month of</label>
                <select id="idoser-month"
                  value={(so.repeat.details>>10)&0x1F}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("rept",{type:"yearWeekMonth",val:e.currentTarget.value})}
                >
                {[...Array(12).keys()].map(v=>{
                  return(
                    <option key={v} value={v+1}>{months[v]}</option>
                  )
                })}
                </select>
                <span className="material-icons down-arrow">
                  keyboard_arrow_down
                </span>
              </span>

      </div>
    )
  }
  
  repeatNone=()=>{
    return null
  }
  
  showRepeatEdit=(so)=>{
//     cl(so)
    let repeats={hours:this.repeatHours, minutes:this.repeatMinutes, dayhours:this.repeatDayHours, days:this.repeatDays,
      weekdays: this.repeatWeekDays, monthdays: this.repeatMonthDays, monthweek:this.repeatMonthWeek, yearday:this.repeatYearDay,
      yearweek: this.repeatYearWeek, none: this.repeatNone
    }
    if(repeats[so?.repeatPattern]){return repeats[so?.repeatPattern](so)}
//     return repeats[so?.repeatPattern](so)
//       let details={hours: RepeatHours, dayhours: RepeatDayHours, days: RepeatDays, weekdays: RepeatWeekDays,
//       monthdays: RepeatMonthDays, monthweek: RepeatMonthWeek, yearday: RepeatYearDay, yearweek: RepeatYearWeek,
//       minutes: RepeatMinutes,
}
  
  showMixTime=(so)=>{
//     cl(so)
//         <input className="narrow inline-block" type="text" pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}" value="00:00:00"
//         onChange={this.onChange}
//         />
//     cl(so.mixtime)
    return(
      <div>
        <label>Mix Time</label>
        <C18Duration00 parms={{
          duration:so?.mixtime,
          saveOK:this.props.parms.saveOK,
          onChange:e=>this.onChange("upd",{mixtime:e.duration}),
          unit: "(HH:MM:SS)",
        }}/>
      </div>
    )
  }
  
  showScheduleEdit=()=>{
    let so=this.state.scheduleOpts[this.state.scheduleSel]
    if(!so){return}
//     cl(so)
//               {this.showRepeatSelect(so)}
//               
//               {this.showRepeatEdit(so)}
// 
//               <label htmlFor="idoser-name">Name</label>
// 
//               <input id="idoser-name" type="text" 
//               value={so?.name} 
//               disabled={!this.props.parms.saveOK}
//               onChange={e=>this.onChange("upd",{name:e.currentTarget.value})}
//               />
//               {this.showTankSelect(so)}
//               <br />
    return(
      <div>

              <div className="clearfloat"></div>
              {this.showRecipeSelect(so)}

              <div className="clearfloat"></div>
              {this.showEventTime(so)}
              
              <div className="clearfloat"></div>
              <br />
              {this.showMixTime(so)}


              <div className="clearfloat"></div>
              <br />
              
      </div>
    )
  }
  
  showWateringArea=()=>{
//     cl("water")
//     let sa=this.state.scheduleAreas[this.state.scheduleSel]||[]
    let so=this.state.scheduleOpts[this.state.scheduleSel]
//     cl(so)
//     cl(sa)
//     cl(this.state.scheduleOpts)
//     cl(this.state.areaOpts)
//     if(!Object.keys(this.state.areaOpts).length){return}
//     cl(so)
    if(!so){return}
    let ao=this.state.areaOpts/*[so?.tankAddr]*/
//     cl(ao)
    if(!ao){return}
    let i=0// index of the watering area, always 0?
    if(!so?.areaId){so.areaId=Object.keys(ao)[0]}
    let a={areaId:so.areaId, volume:so.amount}
//                 <button type="button" className="material-icons trash" aria-label="delete nutrient"
//                   disabled={!this.props.parms.saveOK}
//                   onClick={e=>this.onChange("deleteArea",{index:i})}
//                 >
//                   delete_outline
//                 </button>
//     cl(ao)
//     cl(a)
    
      return(
        <div key={i} className="line">
          <span className="custom-select floatleft">
            <label>Area</label>
            <select
              value={so.areaId/*a.areaId*/}
              disabled={!this.props.parms.saveOK}
              onChange={e=>this.onChange("area",{index:i,areaId:e.currentTarget.value})}
            >
            {Object.keys(ao).map((areaId,i)=>{
              return(
                <option key={i} value={areaId}>{ao[areaId].name}</option>
              )
            })}
            </select>
            <span className="material-icons down-arrow">
              keyboard_arrow_down
            </span>
          </span>
          <label>Volume</label>
          <input className="narrow inline-block" type="text" 
            value={Math.round((so.amount||0)/constant.LITERS_PER_GALLON)}/*a.volume*/ 
            disabled={!this.props.parms.saveOK}
            onChange={e=>this.onChange("upd",{amount:e.currentTarget.value*constant.LITERS_PER_GALLON})}
            />
          <span className="units">Gal</span>
        </div>
      )
  }
  
  showWateringAreas=()=>{
//     cl(this.state)
    let sa=this.state.scheduleAreas[this.state.scheduleSel]||[]
    let so=this.state.scheduleOpts[this.state.scheduleSel]
//     cl(so)
    let ao=this.state.areaOpts/*[so?.tankAddr]*/
//     cl(ao)
    return(
      <div>
        <h2>Watering Areas</h2>
        {sa.map((a,i)=>{
//             cl(a)
            return(
              <div key={i} className="line">
                <span className="custom-select floatleft">
                  <label>Area</label>
                  <select
                    value={a.areaId}
                    disabled={!this.props.parms.saveOK}
                    onChange={e=>this.onChange("area",{index:i,areaId:e.currentTarget.value})}
                  >
                  {Object.keys(ao).map((areaId,i)=>{
                    return(
                      <option key={i} value={areaId}>{ao[areaId].name}</option>
                    )
                  })}
                  </select>
                  <span className="material-icons down-arrow">
                    keyboard_arrow_down
                  </span>
                </span>
                <label>Volume</label>
                <input className="narrow inline-block" type="text" 
                  value={a.volume}
                  disabled={!this.props.parms.saveOK}
                  onChange={e=>this.onChange("area",{index:i,volume:e.currentTarget.value})}
                  />
                <button type="button" className="material-icons trash" aria-label="delete nutrient"
                  disabled={!this.props.parms.saveOK}
                  onClick={e=>this.onChange("deleteArea",{index:i})}
                >
                  delete_outline
                </button>
              </div>
            )
        })}
        <button className="material-icons-outlined add" aria-label="add watering area"
          disabled={!this.props.parms.saveOK}
          onClick={e=>this.onChange("addArea")}>
          add
        </button>
        
      </div>
    )
  }
  
  render(){
    cl(this.state)
    if(this.state.loaded){
//                 {this.showWateringAreas()}
//                 <button type="button" className="material-icons trash after-selector" aria-label="delete nutrient"
//                 disabled={!this.props.parms.saveOK}
//                 onClick={e=>this.onChange("deleteSchedule")}>
//                   delete_outline
//                 </button>
// 
      return(
        <div id="iDoser_schedules">
          <div className="clearfloat"></div>
            {this.showTankSelect("Select Mixing Tank",this.state.scheduleOpts)}
                <div className="clearfloat"></div>
                {this.showScheduleSelect("Select Event")}
                <div className="clearfloat"></div>
                <button type="button" className="material-icons-outlined add after-selector" aria-label="add nutrient"
                onClick={e=>this.onChange("addSchedule")}
                disabled={!this.props.parms.saveOK}
                >add</button>
                <div className="clearfloat"></div>
                <hr /><br />
                {this.showScheduleEdit()}
                <div className="clearfloat"></div>
                {this.showWateringArea()}
        </div>
        
      )
    }else{return<div>loading. . .</div>}
  }
}

  
export default Schedules ;

