import { createTheme/*createMuiTheme*/ } from '@material-ui/core';

export default createTheme({
  typography: {
    useNextVariants: true,
  },
 palette: {
      primary: {
        main: '#006060',
      },
      secondary:  {
        main: '#FFFF00',
      },
      error:  {// doesn't work
        main: '#FF00FF',
      },
 }
});
