import React from 'react';
import C18Select00 from './C18Select00'
import {wsTrans} from '../utils/utils'
import {loadSitesInfo,getSiteIndex,loadZonesInfo,getSiteName,getZoneIndex,getZoneName,privs} from './C18utils'
import {dbVals} from '../../components/utils/http'
import {cl, globs, constant, getRandomString, getTime,dateToDisplayDate} from '../../components/utils/utils';

class C18SyncStatus extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded:false,
      showLine:-1,
      gotData:false,
      enableAutoSync:true,
    }
    this.loadInfo()
    this.setBreadcrumbs()
    this.getPackCRCTimer=setInterval(this.checkPackCRC,59000)
    this.loadSyncLogTimer=setInterval(this.loadSyncLog,10000)
  }
  
  componentWillUnmount=()=>{
    cl("unmount")
    clearInterval(this.getPackCRCTimer)
    clearInterval(this.loadSyncLogTimer)
//     clearInterval(this.getJustCRCTimer)
  }
  
  loadInfo=async()=>{
    let p=this.props.parms
//     cl(p)
    await loadSitesInfo()
    let autoSync=globs.sitesInfo.info[getSiteIndex(p.site)]?.autoSync||0
//     cl(autoSync)
    await loadZonesInfo()
    let zInd=getZoneIndex(p.zone)
    this.zoneInfo=globs.zonesInfo.info[zInd]
    await this.loadSyncLog()
    let zoneOpts=[]
    globs.zonesInfo.info.forEach(z=>{
      if(z.siteId==p.site){zoneOpts.push({v:z.zoneId,t:z.zoneName})}
    })
    await this.getPackDefs()
    await this.getCRCs()// guaranteed to get the first time, and set lastTime
    this.checkPackCRC()
//     setTimeout(this.getCRCs,10000)
    this.setState({loaded:true,zoneOpts:zoneOpts,zone:p.zone,enableAutoSync:autoSync})
  }
  
  lastCRCTime=0
  lastCRCTimer=null
  
  getCRCs=async()=>{// just reads the current CRC data from the table
    await this.getPackDefs()
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/packCRCs", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:this.zoneInfo.siteId,zone:this.zoneInfo.siteZoneIndex}})
//     cl(res)
    if((res.data.ts==this.lastCRCTime)&&(!this.lastCRCTimer)){
      this.lastCRCTimer=setTimeout(this.getCRCs,5000)
    }else{
      this.lastCRCTime=res.data.ts
      this.lastCRCTimer=null
    }
//     cl([res.data.cloudCRCs,res.data.contCRCs])
    this.setState({cloudCRCs:res.data.cloudCRCs,contCRCs:res.data.contCRCs})
  }
  
  
  checkPackCRC=async()=>{
// this just *sends* the message, and does not wait for the reply from the controller
    cl(`check CRCs @ ${new Date()}, zone: ${this.zoneInfo.siteZoneIndex}`)
    let p=this.props.parms
//     cl(this.props)
    wsTrans("usa", {cmd: "cRest", uri: "/s/packCRCs", method: "create", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:this.zoneInfo.siteId,gatewayId:this.zoneInfo.gatewayId,zone:this.zoneInfo.siteZoneIndex}})
//     if(!res?.data?.contCRCs){return}
//     await this.getPackDefs()
    let gots=Object.keys(this.packDefs)
//     cl(gots)
    let needs=[]
    for(let i=0;i<this.state.contCRCs.length;i++){
      if(!gots.includes(i.toString())){needs.push(i)}
    }
//     cl(needs)
    if(needs.length){
      let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/packDefs", method: "create", 
        sessionId: globs.userData.session.sessionId,
        body: {siteId:this.zoneInfo.siteId,gatewayId:this.zoneInfo.gatewayId,zone:this.zoneInfo.siteZoneIndex,packIds:needs}})
    }
//     this.getCRCs()
    setTimeout(this.getCRCs,10000)
//     this.setState({cloudCRCs:res.data.cloudCRCs,contCRCs:res.data.contCRCs})
  }
  
  getPackDefs=async()=>{
//     cl(this.props)
//     cl(this.zoneInfo)
    let p=this.props.parms
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/packDefs", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:p.site,zone:this.zoneInfo.siteZoneIndex}})
//     cl({siteId:p.site,zone:this.zoneInfo.siteZoneIndex})
    this.packDefs={}
    res.data.forEach(p=>{
      this.packDefs[p.packId]=p
    })
    cl(this.packDefs)
  }
  
  checkPackData=async(packId)=>{// this will keep getting called until the data is there
    if(packId<0){return}
    let z=this.zoneInfo
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/packData", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:z.siteId,zone:z.siteZoneIndex,packId:packId}})
    if(res.data){// checks every second until data is found
      clearInterval(this.getPackDataTimer)
      this.getPackDataTimer=null
//       cl(res.data)
//       cl(`Received Data for Pack ${res.data.packId+1}`)
      this.setState({gotData:true,packData:res.data.packData})
    }
  }
  
  
  
  syncPackData=async(packId)=>{
    cl(`syncPackData pack: ${packId}`)
    let z=this.zoneInfo
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/packData", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:z.siteId,gatewayId:z.gatewayId,siteZoneIndex:z.siteZoneIndex,packIds:[packId]}})// should return def and data to update local DB image
    cl("Got Pack Data")
    this.getPackData(packId)// re-get and load to show the new data
//     this.getPackDataTimer=setInterval(e=>this.checkPackData(packId),1000)// force another read
//     cl(res)
    
  }
  
  getPackData=async(packId)=>{// loads from controller, then periodically loads from packData table
    if(this.getPackDataTimer){return}
//     clearInterval(this.getPackDataTimer)
    if(packId<0){return}
//     cl(`Get Data for Pack ${packId+1}`)
    let z=this.zoneInfo
    await wsTrans("usa", {cmd: "cRest", uri: "/s/packData", method: "delete", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:z.siteId,siteZoneIndex:z.siteZoneIndex,packId:packId}})
    await wsTrans("usa", {cmd: "cRest", uri: "/s/packData", method: "create", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:z.siteId,gatewayId:z.gatewayId,siteZoneIndex:z.siteZoneIndex,packId:packId}})
    
    this.getPackDataTimer=setInterval(e=>this.checkPackData(packId),1000)
  }
  
  setBreadcrumbs=()=>{
    let zoneName=getZoneName(this.props.parms.zone)
    let siteName=getSiteName(this.props.parms.site)
    let siteId=this.props.parms.site
    let zoneId=this.props.parms.zone
    let zoneBCI=[
      {t:"Sites", url:"/usa/c18/sites"},
      {t:siteName, url:`/usa/c18/sites/${siteId}`},
      {t:zoneName, url:`/usa/c18/sites/${siteId}/zones/${zoneId}`},
      {t:"Sensor Settings", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/settings/sensor1800`},
      {t:"Sync Status", url:`/usa/c18/sites/${siteId}/zones/${zoneId}/admin/syncStatus`},
    ]
    this.props.parms.onChange(
      {
        cmd: "breadcrumbs",
        data:
          {breadcrumbs: zoneBCI},
      },
    )
  }
  
  saveAutoSync=(autoSync)=>{
    
    globs.sitesInfo.info[getSiteIndex(this.props.parms.site)].autoSync=autoSync
    wsTrans("usa", {cmd: "cRest", uri: "/s/sites", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:this.props.parms.site,autoSync:autoSync}})
  }
  
  onChange=(type,vals)=>{
//     cl(type,vals)
    var vals
    switch(type){
      case "sync":
        this.syncPackData(vals.packId)
        break
      case "show":
        let id=(this.state.showLine==vals.packId)?-1:vals.packId
        if(id>=0){
          this.getPackData(vals.packId)
        }
        this.setState({gotData:false,showLine:id,packData:null})
        break
      case "zones":
//         cl(vals)
        let zInd=getZoneIndex(vals.zone)
        this.zoneInfo=globs.zonesInfo.info[zInd]
        Object.assign(vals,{showLine:-1})
        this.checkPackCRC()
        this.setState(vals)
        break
      case "autoSync":
        cl(vals)
        this.saveAutoSync(vals.enableAutoSync)
        this.setState(vals)
        break
      case "eraseDB":
      case "resend":
        wsTrans("usa", {cmd: "cRest", uri: "/s/packCmd", method: "create", 
          sessionId: globs.userData.session.sessionId,
          body: {cmd2:type,siteId:this.zoneInfo.siteId,gatewayId:this.zoneInfo.gatewayId,zone:this.zoneInfo.siteZoneIndex}})
        break
      case "getCRCs":
        this.checkPackCRC()
        break
        
    }
      
  }
  
  cmpVals=(a,b)=>{
    return a==b
  }
  
  fixFloat=(v)=>{
//     cl(v)
    if(v=="true"){v=1}
    v=+v
    if(isNaN(v)){v=0}
    if(Math.floor(v)!=v){// if not integer
      v=(Number.parseFloat(v).toExponential(3)).toString()
      let len=v.length
      let ch=v.substring(len-2,len-1)
      if((ch=="+")||(ch=="-")){
        v=v.substring(0,len-1)+"0"+v.substring(len-1)
      }
    }
    return +v
  }
  
  showValues=(packId)=>{
    if((this.state.showLine<0)||(!this.state.gotData)){return null}
//     cl(this.packDefs)
//     cl(dbVals)
    let z=dbVals.z[this.zoneInfo.siteZoneIndex]
//     cl(z)
    let pack=this.packDefs[packId]
//     cl(packId,pack)
    if(!pack?.fields){return null}
//     cl(pack.fields)
    return(
      <div>
      <h2>{`Pack: ${pack.name}`}</h2>
        <button className="button outlined" onClick={e=>this.onChange("sync",{packId:packId})}>Sync</button>
        <div className="clearfloat"></div>
        <br/>
        <table><tbody>
          {pack.fields.map((p,i)=>{
            let val=((this.state?.packData||[])[i])||0
            let def=pack.packDef[i]
            let v=(z[def[0]]||[])[def[1]]||0
            if(v=="None"){
              if([5,6].includes(v)){v=""}else{v=0}
            }
//             cl([[def],v])
            if(def[2]==3){// float
              v=this.fixFloat(v)
              val=this.fixFloat(val)
              v=+v
//               if(isNaN(v)){v=0}
//               if(Math.floor(v)!=v){// if not integer
//                 v=(Number.parseFloat(v).toExponential(3)).toString()
//                 let len=v.length
//                 let ch=v.substring(len-2,len-1)
//                 if((ch=="+")||(ch=="-")){
//                   v=v.substring(0,len-1)+"0"+v.substring(len-1)
//                 }
//               }
            }
//             cl(def) 
            let bg=(this.cmpVals(val,v))?"#CCFFCC":"#FFCCCC"
            let style={backgroundColor:bg}
            return(
              <tr style={style} key={i}>
              <td>{pack.packDef[i][0]}</td>
              <td>{pack.packDef[i][1]}</td>
              <td>{pack.packDef[i][2]}</td>
              <td>{p}</td>
              <td>{val}</td>
              <td>{v}</td>
              </tr>
            )
          })}
        </tbody></table>
      </div>
    )
  }
  
//   showPackO=(pack,i)=>{
// //     cl(pack)
//     let ret=[
//       <tr key={i}>
//         <td><button className="button outlined" onClick={e=>this.onChange("sync",{packId:i})}>Sync</button>
//         <button className="button outlined" onClick={e=>this.onChange("show",{packId:i})}>Show</button></td>
//         <td>{pack.name}</td>
//       </tr>
//     ]
//     if(this.state.showLine==i){
//       ret.push(
//         <tr key={1+"a"}>
//         <td>{this.showValues(i)}</td>
//         <td colSpan="3"></td></tr>
//       )
//     }
//     return ret
//   }
//   
//   showPacksO=()=>{
//     return(
//       <table><tbody>
//         {
//           Object.keys(this.packDefs).map((k,i)=>{
//             let p=this.packDefs[k]
//             return this.showPack(p,i)
//           })
//         }
//        
//       </tbody></table>
//     )
//   }
  
  showAutoSync=()=>{
//     cl(this.state)
    return(
      <div className="checkbox-field">
        <span>
          <input type="checkbox" id="" checked={(+this.state.enableAutoSync)!=0} 
            onChange={e=>this.onChange("autoSync",{enableAutoSync:e.currentTarget.checked})} />
          <label htmlFor="">Auto Sync</label>
        </span>
      </div>
    )
  }
  
  showPack=(ind)=>{
    let pack=this.packDefs[ind]
//     cl(this.packDefs)
//     cl(pack)
    if(pack){
      let packStyle={width:100, cursor:"pointer", margin:5}
      let eq=(this.state.cloudCRCs||[])[ind]==(this.state.contCRCs||[])[ind]
//       cl(pack)
      if(pack.name?.indexOf("snapshot")>=0){
        packStyle.backgroundColor=(eq)?"#CCEEFF":"#FFEECC"
      }else{
        packStyle.backgroundColor=(eq)?"#CCFFFF":"#FFCCCC"
      }
      return(
        <td key={ind} valign="top" align="center" title={pack?.name}
          onClick={e=>this.onChange("show",{packId:ind})}
        >
        {ind<41&&
          <button className="button outlined" style={packStyle}>{`Pack ${1+ind}`}</button>
        }
        </td>
      )
    }
  }
  
  showPacks=()=>{
    let packs=[]
    for(let i=0;i<7;i++){
      let packRow=[]
      for(let j=0;j<6;j++){
        packRow.push(this.showPack(6*i+j))
      }
      packs.push(
        <tr key={i}>
        {packRow}
        </tr>
      )
    }
    return(
      <div style={{width:420}}>
        <table width="150"><tbody>
        {packs}
        </tbody></table>  
        {this.showValues(this.state.showLine)}
      </div>
    )
  }
  
  makeSelectOpts=(opts)=>{
    let ret=[]
    Object.values(opts).forEach((o,i)=>{
      ret.push(
        <option key={i} value={o.v}>{o.t}</option>
      )
    })
    return ret
  }
  
  showZones=(key)=>{
    return(
      <div  key={key} className="custom-select">
        <label htmlFor="graph-zone">Zone</label>
        <C18Select00 
          id="graph-zone"
          onChange={e=>this.onChange("zones",{zone: e.currentTarget.value})}
          value={this.state.zone}
        >
          {this.makeSelectOpts(this.state.zoneOpts)}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div>
    )
  }
  
  loadSyncLog=async()=>{
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/syncLog", method: "retrieve", 
      sessionId: globs.userData.session.sessionId,
      body: {siteId:this.props.parms.site,ts:{$gt:getTime()-24*3600}}})
//     cl(res)
    res.data.sort((a,b)=>{
      if(a.ts<b.ts){return 1}
      if(a.ts>b.ts){return -1}
      return 0
    })
    await this.getPackData(this.state.showLine)
    this.setState({syncLog:res.data})
  }
  
  showSyncLog=()=>{
    let lines=[]
    let zone=this.zoneInfo.siteZoneIndex
    this.state.syncLog.forEach((s,i)=>{
      let da=new Date(s.ts*1000)
//       cl(da)
//       cl(dateToDisplayDate(da,"hh:mm:ss",da.getTimezoneOffset()))
      if(s.zone==zone){
        lines.push(
          <tr key={i}>
          <td width="100">{dateToDisplayDate(da,"hh:mm:ss",da.getTimezoneOffset())}</td>
          <td>{`Pack ${s.packId+1}: ${this.packDefs[s.packId]?.name}`}</td>
          </tr>
        )
      }
    })
    return(
      <div>
        <h3>Sync Log for {this.zoneInfo.zoneName}</h3>
        <table><tbody>
        <tr><td>Time</td><td>Pack</td></tr>
        {lines}
        </tbody></table>
      </div>
    )
  }
  
  render(){
//     cl(this.state)
    if(this.state.loaded){
      return(
        <div>
          {this.showZones()}
          <div className="clearfloat"></div>
          {this.showAutoSync()}
          <button className="button outlined" onClick={e=>this.onChange("eraseDB",{})}>Erase Cloud Current</button>
          <div className="clearfloat"></div><br/>
          <button className="button outlined" onClick={e=>this.onChange("resend",{})}>Resend 1800 Data</button>
          <div className="clearfloat"></div><br/>
          <button className="button outlined" onClick={e=>this.onChange("getCRCs",{})}>Get CRCs</button>
          <div className="clearfloat"></div><br/>
          <h3>CRCs current as of {dateToDisplayDate(new Date(1000*this.lastCRCTime),"hh:mm:ss")}</h3>
          {this.showPacks()}
          {this.showSyncLog()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}
      
export default C18SyncStatus;
