import React from 'react';
import CravoInput from './CravoInput'
import CravoSetpoint1 from './CravoSetpoint1'
import {setParamIds, getParam, putParam, getVentPos, saveVentPos, getCropProtection, saveCropProtection, 
    getStructuralProtection, saveStructuralProtection, getWindBreak, saveWindBreak, getOpenField, saveOpenField, 
    getShadeHouse, saveShadeHouse, getHumidityIncrease, saveHumidityIncrease} from './CravoData'
import {p, pi, pInd} from '../../components/utils/paramIds';
import {sendArray, dbVals} from '../../components/utils/http';
import {cl,globs,constant,getTime,secsToHms} from '../../components/utils/utils';

const cream="#f5f5dc"
const aqua="#00ffff"
const yellow="#fffacd"
const green="#98fb98"

class RetractableGreenhouse extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    let parts=props.zuci.split("-")
    let zone=+parts[0]
    let chan=+parts[1]
    this.state={
      zone:zone,
      chan:chan,
      setpoints:this.getSetpoints(zone),
      onChange: this.onChange,
    }
    this.state.vents=this.getVentChans(zone)
    cl(this.state)
    this.props.getValue(props.ind, {type: "array", count: 11})
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent", this.savePageEvent)
//     cl(this.props.value)
//     cl(this.props)
//     this.getSetpoints()
  }
/* need to collect setpoint data, and the channels that have Cravo Vents
pi[1800].config_setpoints["setpointIndex"] = 0 // 8 * 10
pi[1800].config_setpoints["unix_timestamp(modified)"] = 1
pi[1800].config_setpoints["enabled"] = 2
pi[1800].config_setpoints["startTimeOfDay"] = 3
pi[1800].config_setpoints["astroAdjust"] = 4
pi[1800].config_setpoints["rampMinutes"] = 5
pi[1800].config_setpoints["heatSetpoint"] = 6
pi[1800].config_setpoints["coolSetpoint"] = 7
pi[1800].config_setpoints["humidifySetpoint"] = 8
pi[1800].config_setpoints["dehumidifySetpoint"] = 9
*/

  savePageEvent=()=>{
//     this.changed=false
    this.saveCravoData()
  }

  componentWillUnmount=()=>{
//     this.subscribe_updateFui.remove()
    this.subscribe_savePageEvent.remove()
  }

  getCravoData=(z,c)=>{
//     let z=this.state.zone
//     let c=this.state.chan
    this.base=pInd[1800].config_channels_configuration[0]
    getVentPos(this,z,c)
    getCropProtection(this,z,c)
    getStructuralProtection(this,z,c)
    getWindBreak(this,z,c)
    getOpenField(this,z,c)
    getShadeHouse(this,z,c)
    getHumidityIncrease(this,z,c)
//     cl(this.state)
  }
  
  saveOneCravoData=(z,c,t,parms)=>{
    saveVentPos(this,z,c,t,parms)
    saveCropProtection(this,z,c,t,parms)
    saveStructuralProtection(this,z,c,t,parms)
    saveWindBreak(this,z,c,t,parms)
    saveOpenField(this,z,c,t,parms)
    saveShadeHouse(this,z,c,t,parms)
    saveHumidityIncrease(this,z,c,t,parms)
    sendArray(parms).then(r=>{globs.events.publish("saveOK",true)})
  }
  
  saveCravoData=()=>{
    cl("save")
    let z=this.state.zone
    let c=this.state.chan
    let t=Math.floor(getTime())
    this.base=pInd[1800].config_channels_configuration[0]
    let b=pInd[1800].config_channels_configuration[0]
    let parms=[]
    cl(this.props)
    this.props.current.channelInfo.forEach((ci,i)=>{
      if(ci.channelType==constant.EQ_VENT){
        let ventType=dbVals.z[z][i][b+pi[1800].config_channels_configuration["vent_type"]]
        if((ventType==constant.VENT_RETRACTABLE)||(ventType==constant.VENT_SIDE_WALL)){
          this.saveOneCravoData(z,i,t,parms)
        }
      }
    })
  }

  getSetpoints=(z)=>{// get enable, time, and heat/cool
//     cl(pInd[1800].config_setpoints)
    let sp=[]
//     let z=this.state.zone
//     let c=this.state.chan
    let c=255//zone
    let b=pInd[1800].config_setpoints[0]
    let mult=pInd[1800].config_setpoints[2]
    for(let i=0;i<8;i++){
      sp.push({
        enabled: (dbVals.z[z][c]||{})[b+mult*i+pi[1800].config_setpoints["enabled"]],
        startTimeOfDay: (dbVals.z[z][c]||{})[b+mult*i+pi[1800].config_setpoints["startTimeOfDay"]],
        heatSetpoint: (dbVals.z[z][c]||{})[b+mult*i+pi[1800].config_setpoints["heatSetpoint"]],
        coolSetpoint: (dbVals.z[z][c]||{})[b+mult*i+pi[1800].config_setpoints["coolSetpoint"]],
      })
    }
    return sp
  }
  
  getVentChans=(z)=>{
//pi[1800].config_channels_configuration["vent_type"] =	281
//     cl(dbVals.z[z])
//     let z=this.state.zone
    let b=pInd[1800].config_channels_configuration[0]
    let vents=[]
    setParamIds(this)
    this.props.current.channelInfo.forEach((c,i)=>{
//       cl(i)
      if(c.channelType==constant.EQ_VENT){
        let ventType=dbVals.z[z][i][b+pi[1800].config_channels_configuration["vent_type"]]
        if((ventType==constant.VENT_RETRACTABLE)||(ventType==constant.VENT_SIDE_WALL)){
          this.getCravoData(z,i)
          vents.push(i)
        }
//         cl(ventType)
      }
    })
    return vents
//     cl(this.props)
    
  }

  isArray=(valueId)=>{
    return ["pos","thresh"].includes(valueId)
  }
  
//   onChange=(type, valId, ind, e)=>{
  onChange=(valId, ind, e, dataGroup, dgInd)=>{// was e, ind 20211003
//     cl(valId,ind,dataGroup,dgInd)
    globs.events.publish("savePageEnable",true)
    var arr,vals,vals2,obj
    let val=e.currentTarget.value
    if(this.isArray(valId)){
      arr=this.state[dataGroup][dgInd][valId]
      arr[ind]=val
      vals={}
      vals[valId]=arr
      obj=Object.assign(this.state[dataGroup],vals)
    }else{
      arr=Object.assign({},this.state[dataGroup])
      arr[dgInd][valId]=val
      obj=Object.assign(this.state[dataGroup],arr)// has to be an array of channels
    }
    vals2={}
    vals2[dataGroup]=obj
    this.setState(vals2)
    
//     var vals={}
//     let arr=this.props.value.slice(0)
//     switch(type){
//       case "inp":
//         arr[ind]=e.currentTarget.value
//         break
//       case "check":
//         arr[ind]=(e.currentTarget.checked)?1:0
//         break
//     }
//     this.setState({arr: arr})
//     this.props.onChange(this.props.ind, {value: arr})
  }
  
  showHeadGroup0=()=>{
    return[
      <td key={0}>Id</td>,
      <td key={1}>Channel</td>,
      <td key={2}>{"Greenhouse\u00A0(GH)"}</td>,
      <td key={3} colSpan="5">Wind Break (WB)</td>,
      <td key={4} colSpan="5">Open Field (OF)</td>,
      <td key={5} colSpan="5">Shade House (SH)</td>,
      <td key={6} colSpan="5">Humidity Increase (HI)</td>,
    ]
  }
  
  showHeadGroup1=(mo0,mo1)=>{
    return[
      <td key={`${mo0}0`}>{`${mo0}->${mo1}`}</td>,
      <td key={`${mo0}1`}>{`${mo1}->${mo0}`}</td>,
      <td key={`${mo0}2`}>{`Position`}</td>,
      <td key={`${mo0}3`}>{`Threshold`}</td>,
      <td key={`${mo0}4`}>{`Deadband`}</td>,
    ]
    
  }
  
  showHeadGroup2=(mo0,mo1)=>{
    let thresh=(mo1=="HI")?"Hum.":"Temp."
    return[
      <td key={`${mo0}0`}>{`Delay`}</td>,
      <td key={`${mo0}1`}>{`Delay`}</td>,
      <td key={`${mo0}2`}>{``}</td>,
      <td key={`${mo0}3`}>{thresh}</td>,
      <td key={`${mo0}4`}>{thresh}</td>,
    ]
    
  }
  
  showHeaders=()=>{
    return(
      [
      <tr key={0}>{this.showHeadGroup0()}</tr>,
      <tr key={1}>
      <td key={0}></td>
      <td key={1}></td>
      {this.showHeadGroup1("GH","WB")}
      {this.showHeadGroup1("WB","OF")}
      {this.showHeadGroup1("OF","SH")}
      {this.showHeadGroup1("SH","HI")}
      </tr>,
      <tr key={2}>
      <td key={0}></td>
      <td key={1}></td>
      {this.showHeadGroup2("GH","WB")}
      {this.showHeadGroup2("WB","OF")}
      {this.showHeadGroup2("OF","SH")}
      {this.showHeadGroup2("SH","HI")}
      </tr>
      ]
    )
  }
  
  showSetpointLine=()=>{
    
  }
  
  showLines=()=>{// table has 22 columns
    let rows=[]
    let groups=["WB","OF","SH","HI"]
    let dataGroups=["windBreak", "openField", "shadeHouse", "humidityIncrease", ]
    let colors=[cream, aqua, yellow, green]
    var cols
    this.state.setpoints.forEach((s,i)=>{
//       cl(s)
      if(+s.enabled){
        let hms=secsToHms(60*s.startTimeOfDay)
        rows.push(
          <tr key={i} style={{fontWeight:700, fontSize: 24}}>
          <td key={0} colSpan="2">{`SETPOINT\u00A0${i+1}`}</td>
          <td key={2} colSpan="3">({`Start\u00A0Time:\u00A0${hms})`}</td>
          <td key={5}></td><td key={6}></td>
          <td key={7}></td><td key={8}></td><td key={9}></td><td key={10}></td><td key={11}></td>
          <td key={12}></td><td key={13}></td><td key={14}></td><td key={15}></td><td key={16}></td>
          <td key={17}></td><td key={18}></td><td key={19}></td><td key={20}></td><td key={21}></td>
          </tr>
        )
        
        rows.push(this.showHeaders())
        this.state.vents.forEach((v,i0)=>{
          cl(v)
          let channel=this.props.current.channelInfo[v].channelName
          cols=groups.map((g,j)=>{
//             cl(g)
            return(
              <CravoSetpoint1 key={j} parms={{
                that: this.state,
                dataGroup: dataGroups[j],
                dgInd: v,
                color: colors[j],//color,
                i: i,
              }}/>
              
            )
          })
//           cl(i)
          rows.push(
            <tr key={`cols-${i0}-${i}`}><td>{1+v}</td><td>{channel}</td>{cols}</tr>
          )
        })
      }
    })
    return rows
  }
  
  render(){
//       {this.showHeaders()}
//     cl(this.state)
    let cnt=this.state.setpoints.filter(s=>{return s.enabled}).length
    return(
      <div className="retractable-greenhouse">
      {(cnt==0)
        ?
        <h3>You have no Setpoints Enabled</h3>
        :
        <table><tbody>
        {this.showLines()}
        </tbody></table>
      }
      </div>
    )
  }

}

export default RetractableGreenhouse ;
